import * as React from 'react';
import {Grid, Typography, Divider, Tooltip} from '@mui/material';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

export default function FieldView2({label, value, color,fontSize, fontColor}) {
  
  return (
    <Grid item sx={{width:'100%',  alignItems:"center"}} >
        <Grid container  justifyContent="flrcx" direction="row"   sx={{width:'100%'}}>
              
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{padding:.5, borderRadius:3,wordBreak: 'break-word', overflowWrap: 'break-word', textAlign:'left'}}> 
                  <Typography  color="textSecondary" variant="h6" sx={{fontSize:14}}>{label}</Typography>
                  <Typography  sx={{ color:'primary.darker', fontWeight: 'light',fontSize: fontSize===undefined?15:fontSize}}>{value}</Typography>
              </Grid>
        
        </Grid>
    </Grid>
       
  );
}