import { transformDateValue } from 'core/js/FormUtils';
export const getMlmorderView = (dataList) => {
  
  const transformDataValue = (value, type) =>{
    if (!type.includes('date')) return value;
    return transformDateValue(value);
  }

  let mlmorderList = [];
  dataList.map((data)=>{
  let card = {
            'id': data.id,
            'headingList': [
                            {"fieldLabel":"Title","fieldValue":transformDataValue(data.title,"string"), "fieldType":"string"},
                            {"fieldLabel":"Order Id","fieldValue":transformDataValue(data.orderid,"ID"), "fieldType":"ID"},
                            {"fieldLabel":"Order Value","fieldValue":transformDataValue(data.ordervalue,"double"), "fieldType":"double"},
                            {"fieldLabel":"Partner Id","fieldValue":transformDataValue(data.partnerid,"ID"), "fieldType":"ID"},
                            {"fieldLabel":"Member Id","fieldValue":transformDataValue(data.memberid,"ID"), "fieldType":"ID"},
                            {"fieldLabel":"Order Date","fieldValue":transformDataValue(data.orderdate,"date"), "fieldType":"date"},
                            {"fieldLabel":"Credit Value","fieldValue":transformDataValue(data.creditvalue,"double"), "fieldType":"double"},
              
              
            ],
            'dataList': [
                
              ]
          }

          mlmorderList.push(card);
  })
  return mlmorderList;
}