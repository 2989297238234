// material-ui
import { Box, FormControl, InputAdornment, OutlinedInput, Grid, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

// assets
import { SearchOutlined } from '@ant-design/icons';

// ==============================|| HEADER CONTENT - SEARCH ||============================== //

const TitleSection = () => 
    {

    const {message} = useSelector((state)=>state.headerslice)
    return(
    <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }}>
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Typography variant="h2" sx={{color:'primary.main'}}>{message}</Typography>
        </Grid>

    </Box>
)}

export default TitleSection;
