import React, { useState, useEffect } from 'react'

import {Grid} from '@mui/material'

import ComponentContainer from '../../core/template/ComponentContainer';
import {CoreContext} from "../../core/context/CoreContext";
import CategoryEditForm from './CategoryEditForm';
import CategoryList from './CategoryList';
import { useSelector, useDispatch } from 'react-redux';

import Notification from '../../core/components/Notification';

import CategoryListView from './CategoryListView'


export default function CategoryDashboard(){
  const { setKeyValue, getKeyValues, keyvalues } = React.useContext(CoreContext);
  const {showEdit} = useSelector((state)=>state.dmpslice.categoryDashboard);
  const [ categoryId, setCategoryId] = React.useState('');
  const [reloadCategoryList, setReloadCategoryList] = useState(true);

  return (
      <>
      <Notification/>
      {showEdit?
        <ComponentContainer containedComponent = {
          <CategoryEditForm 
            setReloadCategoryList={setReloadCategoryList}
            categoryId={categoryId}
            />}/>:
            <>
            <Grid container direction="column" >
              <Grid item>
                    {
                      <CategoryListView 
                      setCategoryId = {setCategoryId}/>
                    }
              </Grid>
            </Grid>
            </>
      }
    </>


  )

}
