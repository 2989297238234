// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import {CoreContextProvider} from './core/context/CoreContext';
import PreventBackButton from 'core/components/PreventBackButton';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => (
  <ThemeCustomization>
    <ScrollTop>
    <CoreContextProvider>
      <Routes />
      <PreventBackButton />
    </CoreContextProvider>
    </ScrollTop>
  </ThemeCustomization>
);

export default App;