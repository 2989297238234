import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import MlmuserDashboard from '../MlmuserDashboard';

import { openDrawer } from './../../../store/slice/MenuSlice';
import { getMlmuserInfoByPartneridAPI } from '../MlmuserAPI';
import { setMlmuserDashboardMlmuserList } from 'store/slice/DmpSlice';

export default function MlmuserPage() {
    const dispatch = useDispatch();
    const { MlmuserList} = useSelector((state)=>state.dmpaslice)
    const {mlmUserObj} = useSelector((state)=>state.ecomslice);
    useEffect(() => {
        dispatch(openDrawer(false));
        
    },[]);

    useEffect(()=>{
        // if (mlmUserObj)
        //     getMlmuserInfoByPartneridAPI(mlmUserObj.id, apiSuccess, apiFailure)
    },[])

    const apiSuccess = (apiResponse) =>{
        dispatch(setMlmuserDashboardMlmuserList(apiResponse))
    }
    const apiFailure = (errorFailure) =>{
        alert('!!! ERROR => '+errorFailure);
    }

    return (
        < MlmuserDashboard />
    )

}