import { Grid, Tooltip} from "@mui/material";

export function getMlmuserStruct(){
    return {
        id:{
            name:'id',
            label:'ID',
            value:'',
            error:false,
            errorMessage:'',
            required:false
        },
        
        businessid:{
            name:'businessid',
            label:'Business Id',
            value:'',
            error:false,
            errorMessage:'You must enter Business Id',
            required:true,
            fieldtype:'ID'
        },
        title:{
            name:'title',
            label:'Title',
            value:'',
            error:false,
            errorMessage:'You must enter Title',
            required:true,
            fieldtype:'string'
        },
        userid:{
            name:'userid',
            label:'userid',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'ID'
        },
        usertype:{
            name:'usertype',
            label:'User Type',
            value:'',
            error:false,
            errorMessage:'You must enter User Type',
            required:true,
            fieldtype:'string'
        },
        mlmusertypeid:{
            name:'mlmusertypeid',
            label:'MLM User Type Id',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'bodynamiclist'
        },
        mlmusertypename:{
            name:'mlmusertypename',
            label:'MLM User Type Name',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'string'
        },
        seniorpartnerid:{
            name:'seniorpartnerid',
            label:'Senior Partner Id',
            value:'',
            error:false,
            errorMessage:'You must enter Senior Partner Id',
            required:true,
            fieldtype:'ID'
        },
        mobilenumber:{
            name:'mobilenumber',
            label:'Mobile Number',
            value:'',
            error:false,
            errorMessage:'You must enter Mobile Number',
            required:true,
            fieldtype:'mobile'
        },
        loginstatus:{
            name:'loginstatus',
            label:'Login Status',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'number'
        },
        membercount:{
            name:'membercount',
            label:'Member Count',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'number'
        },
        pan:{
            name:'pan',
            label:'PAN',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'string'
        },
      }
}

export function getColumns(payload){
  let actionList = [
        
        { field: 'title', headerName: 'Title', width:150 },
        { field: 'userid', headerName: 'userid', width:150 },
        { field: 'usertype', headerName: 'User Type', width:150 },
        { field: 'mlmusertypeid', headerName: 'MLM User Type Id', width:150 },
        { field: 'mlmusertypename', headerName: 'MLM User Type Name', width:150 },
        { field: 'seniorpartnerid', headerName: 'Senior Partner Id', width:150 },
        { field: 'mobilenumber', headerName: 'Mobile Number', width:150 },
        { field: 'loginstatus', headerName: 'Login Status', width:150 },
        { field: 'membercount', headerName: 'Member Count', width:150 },
  ];
  if (payload!==undefined){
    payload.actionList && payload.actionList.map((actionObj)=>{
    actionList.push(
      {
        field: actionObj.title,
        headerName: actionObj.title,
        sortable: false,
        width:60,
        disableClickEventBubbling: false,
        renderCell: (params) => {
          return (
              <Grid item onClick={()=>{actionObj.callBackMethod(params.row.id)}}>
                <Tooltip title={actionObj.title} sx={{cursor: "pointer"}}>
                  {actionObj.icon}
                </Tooltip>
              </Grid>
          );
        }
      })
    })
  }
  return actionList;
}

export function setData(data, formValues, setFormValues){
   
    setFormValues({
      ['id']:{
        ...formValues['id'],
        value:data.id,
      },
       ['businessid']:{
          ...formValues['businessid'],
          value:data.businessid,
        },['title']:{
          ...formValues['title'],
          value:data.title,
        },['userid']:{
          ...formValues['userid'],
          value:data.userid,
        },['usertype']:{
          ...formValues['usertype'],
          value:data.usertype,
        },['mlmusertypeid']:{
          ...formValues['mlmusertypeid'],
          value:data.mlmusertypeid,
        },['mlmusertypename']:{
          ...formValues['mlmusertypename'],
          value:data.mlmusertypename,
        },['seniorpartnerid']:{
          ...formValues['seniorpartnerid'],
          value:data.seniorpartnerid,
        },['mobilenumber']:{
          ...formValues['mobilenumber'],
          value:data.mobilenumber,
        },['loginstatus']:{
          ...formValues['loginstatus'],
          value:data.loginstatus,
        },['membercount']:{
          ...formValues['membercount'],
          value:data.membercount,
        },['pan']:{
          ...formValues['pan'],
          value:data.pan,
        },
      })
}