import { createSlice } from '@reduxjs/toolkit';

export const BreadCrumSlice = createSlice({
    name: "breadcrumslice",
    initialState: {
        clickedBo:0,
        hierarchyList:[],
        actionClicked:true

    },
    reducers: {
    
        setClickedBo: (state, action) =>{
            state.actionClicked=!state.actionClicked;
            state.clickedBo=action.payload;
        },
        addToHierarchyList: (state, action) =>{
            state.hierarchyList.push(action.payload);
        },
        removeFromHierarchList: (state, action) =>{
            state.hierarchyList.splice(-1);
        },
        setActionClicked:(state, action)=>{
            state.actionClicked=!state.actionClicked;
        }

    }
});
export const { setClickedBo, addToHierarchyList, removeFromHierarchList,setActionClicked } = BreadCrumSlice.actions;
export default BreadCrumSlice.reducer;