import { transformDateValue } from 'core/js/FormUtils';
export const getAddressView = (dataList) => {
  
  const transformDataValue = (value, type) =>{
    if (!type.includes('date')) return value;
    return transformDateValue(value);
  }

  let addressList = [];
  dataList.map((data)=>{
  let card = {
            'id': data.id,
            'headingList': [
              
              
            ],
            'dataList': [
                
              ]
          }

          addressList.push(card);
  })
  return addressList;
}