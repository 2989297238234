import React, { useState, useEffect } from 'react'

import Grid from '@mui/material/Grid';

import MlmuserFilterCriteria from './MlmuserFilterCriteria'
import MlmuserListImpl from './MlmuserListImpl'
import MyPageContainer from '../../core/template/MyPageContainer';
import {getListAPI} from './MlmuserAPI';
import {transformToCardFormat} from '../../core/js/FormUtils'
import {getPathVariable} from '../../core/js/UrlUtils';

import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {setMlmuserDashboardMlmuserList } from '../../store/slice/DmpSlice';

export default function MlmuserListView({setShowEdit,setMlmuserId}){
    const {filterCriteria} = useSelector((state)=>state.dmpslice.mlmuserDashboard);   

    const [filteredMlmuserList, setFilteredMlmuserList] = React.useState([]);
    const [cardList, setCardList] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const { showfilter } = useSelector((state) => state.confslice);
    const { mlmuserDashboard } = useSelector((state) => state.dmpslice);
    
    function dataFetchCallBack(){
    }

    useEffect(()=>{
      if (mlmuserDashboard.mlmuserList.length>-1){
        setFilteredMlmuserList(mlmuserDashboard.mlmuserList);
        transformToCardFormat(mlmuserDashboard.mlmuserList, setCardList);
      }
    },[mlmuserDashboard.mlmuserList])

    useEffect(()=>{
          transformToCardFormat(filteredMlmuserList, setCardList);
      },[filteredMlmuserList])

    useEffect(()=>{
        if (filterCriteria.title==='' && filterCriteria.userid==='' && filterCriteria.usertype==='' && filterCriteria.mlmusertypeid==='' && filterCriteria.mlmusertypename==='' && filterCriteria.seniorpartnerid==='' && filterCriteria.mobilenumber==='' && filterCriteria.loginstatus==='' && filterCriteria.membercount==='' ){
            setFilteredMlmuserList(mlmuserDashboard.mlmuserList);
            transformToCardFormat(mlmuserDashboard.mlmuserList, setCardList);

        } else {
            setFilteredMlmuserList(
                mlmuserDashboard.mlmuserList
                    .filter(mlmuser=> mlmuser.title.toString().toUpperCase().includes(filterCriteria.title.toString().toUpperCase()))
.filter(mlmuser=> mlmuser.mlmusertypeid.toString().toUpperCase().includes(filterCriteria.mlmusertypeid.toString().toUpperCase()))
.filter(mlmuser=> mlmuser.mobilenumber.toString().toUpperCase().includes(filterCriteria.mobilenumber.toString().toUpperCase()))

                
            )
            

            //transformToCardFormat(mlmuserDashboard.filteredMlmuserList, setCardList);
        }
      },[filterCriteria])
  
     return(
        <Grid container direction="column" >
            <Grid item lg={12}>
                <MlmuserListImpl
                    setShowEdit={setShowEdit}
                    setMlmuserId = {setMlmuserId}
                    tableList={filteredMlmuserList}
                    cardList={cardList}
                    />
          </Grid>
      </Grid>
    )
}