import React, { useState, useEffect } from 'react'

import Grid from '@mui/material/Grid';

import AddressList from './AddressList'
import {transformToCardFormat} from '../../../core/js/FormUtils'

import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

export default function AddressListView({setShowEdit,setAddressId}){
    const {filterCriteria} = useSelector((state)=>state.ecomslice.addressDashboard);   

    const [filteredAddressList, setFilteredAddressList] = React.useState([]);
    const [cardList, setCardList] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const { showfilter } = useSelector((state) => state.confslice);
    const { addressDashboard } = useSelector((state) => state.ecomslice);
    
    function dataFetchCallBack(){
    }

    useEffect(()=>{
      if (addressDashboard.addressList.length>-1){
        setFilteredAddressList(addressDashboard.addressList);
        transformToCardFormat(addressDashboard.addressList, setCardList);
      }
    },[addressDashboard.addressList])

    useEffect(()=>{
          transformToCardFormat(filteredAddressList, setCardList);
      },[filteredAddressList])

    useEffect(()=>{
        if (filterCriteria.title==='' && filterCriteria.pincode==='' && filterCriteria.stateid==='' && filterCriteria.statename==='' && filterCriteria.districtid==='' && filterCriteria.districtname==='' && filterCriteria.cityid==='' && filterCriteria.cityname==='' && filterCriteria.addresstypeid==='' && filterCriteria.addresstypename==='' ){
            setFilteredAddressList(addressDashboard.addressList);
            transformToCardFormat(addressDashboard.addressList, setCardList);

        } else {
            setFilteredAddressList(
                addressDashboard.addressList
                    .filter(address=> address.pincode.toString().toUpperCase().includes(filterCriteria.pincode.toString().toUpperCase()))

                
            )
            

            //transformToCardFormat(addressDashboard.filteredAddressList, setCardList);
        }
      },[filterCriteria])
  
     return(
        <Grid container direction="column" >
            <Grid item lg={12}>
                <AddressList
                    setShowEdit={setShowEdit}
                    setAddressId = {setAddressId}
                    tableList={filteredAddressList}
                    cardList={cardList}
                    />
          </Grid>
      </Grid>
    )
}