import React, { useEffect, useState } from "react";
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

export default function PageWaiting() {
  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" height="500px">
      <CircularProgress />
    </Grid>
  );
}
