import {getAPIprefix} from './ApiUtils';
import { v4 as uuidv4 } from 'uuid';
var IdentityServiceAPI = "https://appmachine.in/identity-core-service/getuser";
var updateUserNameServiceAPI = "https://appmachine.in/identity-core-service/username";
var AppusersResourcePrefix = "https://appmachine.in/identity-core-api/appusers";
 


const getErrorObj = (response) =>{
  let errorObj = {
    "errorCode":response.status,
    "errorMessage": "Something went wrong"
  }
  if (response.status===208){
    errorObj.message="Record already exist";
  } else if (403 === response.status) {
    errorObj.message="Unauthorized access";
  }

  return errorObj;
}
export const getUserAPI = (successCallBack, failuerCallback) => {
    const token = localStorage.getItem("JWT");
    
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${token}` },
        body: JSON.stringify({})
    };
    fetch(IdentityServiceAPI, requestOptions)
        .then(function(response) {
            if(response.ok)
            {
                return response.json();         
            }
            throw new Error('Something went wrong.');
        })
        .then((response) => {
            let errorMessage = "Something Went Wrong"
                if (response.status===208){
                    errorMessage = "Record already exist";
                    failuerCallback(errorMessage);
                } else if (500 === response.status) {
                    errorMessage='Internal Server Error';
                    failuerCallback(errorMessage);
                } else {
                    successCallBack(response);
                }
        })
        .catch(function(error) { 
            console.log('Request failed', error);
            failuerCallback('Internal Server Error');
        });
}
export const updateUserNameAPI = (payload, successCallBack, failuerCallback) => {
    const token = localStorage.getItem("JWT");
    
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${token}` },
        body: JSON.stringify(payload)
    };
    fetch(updateUserNameServiceAPI, requestOptions)
        .then(function(response) {
            if(response.ok)
            {
                return response.json();         
            }
            throw new Error('Something went wrong.');
        })
        .then((response) => {
            let errorMessage = "Something Went Wrong"
                if (response.status===208){
                    errorMessage = "Record already exist";
                    failuerCallback(errorMessage);
                } else if (500 === response.status) {
                    errorMessage='Internal Server Error';
                    failuerCallback(errorMessage);
                } else {
                    successCallBack(response);
                }
        })
        .catch(function(error) { 
            console.log('Request failed', error);
            failuerCallback('Internal Server Error');
        });
}
export function getAppuserInfoByAuthUserIdAPI(authUserId, setAppuserList, dataFetchCallBack){
    const requestId = uuidv4().split("-")[4];
    const token = localStorage.getItem("JWT");
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json','X-Request-ID': requestId,'Authorization': `Bearer ${token}` },
    };
    fetch(AppusersResourcePrefix+"/authuserid/"+authUserId+"/authuserid", requestOptions)
        .then((data) => data.json())
        .then((data) => {
          setAppuserList(data.list);
          dataFetchCallBack(data.list)
    })
}