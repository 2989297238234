import {useState, useEffect} from 'react';
import { Grid, Button, Typography } from '@mui/material';

import AddressEditFormImpl from '../../AddressEditFormImpl';
import { useDispatch } from 'react-redux';
import { setSelectedAddressId } from 'store/slice/EcomSlice';
export default function AddAddressAction(){
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(setSelectedAddressId(-1))
    },[])
    return (
        <>
        <AddressEditFormImpl/>
        </>
    )
}