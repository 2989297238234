import { createSlice } from '@reduxjs/toolkit';

export const ImageGallerySlice = createSlice({
    name: "imagegalleryslice",
    initialState: {
        showImageGallery:true,
        imageNameUnderSearch:'',
        selectedImage:'---',
        selectedImagePath:'',
        integratedImage:'',
        linkAssetOption:true,
        imageType:'CUSTOMER',
        uploadedAssetObj:{
          assetid:-1
        },
        assetList:[]
      },
    reducers: {
      setShowImageGallery : (state, action) =>{
          state.showImageGallery = action.payload;
      },
      setUploadedAssetObj : (state, action) => {
        state.uploadedAssetObj.uploadedAssetObj=action.payload
      },
      setImageNameUnderSearch: (state, action) => {
        state.imageNameUnderSearch=action.payload;
      },
      setSelectedImage: (state, action) =>{
        state.selectedImage = action.payload.name;
        state.selectedImagePath=action.payload.path;
      },
      setIntegratedImage: (state, action) =>{
        state.integratedImage = action.payload;
      },
      setLinkAssetOption: (state, action) =>{
        state.linkAssetOption = action.payload;
      },
      setImageType: (state, action) =>{
        state.imageType = action.payload;
      },
      setAssetList: (state, action) =>{
        state.assetList = action.payload;
      }
    }
});
export const { setShowImageGallery, setUploadedAssetObj,setImageNameUnderSearch,setSelectedImage,setIntegratedImage, setLinkAssetOption, setImageType, setAssetList} = ImageGallerySlice.actions;
export default ImageGallerySlice.reducer;