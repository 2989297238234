import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Grid, Box, Typography, Button, Tooltip, CircularProgress, Checkbox, FormControlLabel } from '@mui/material';

export default function MlmProcess() {
    const dispatch = useDispatch();
    return (
        <>
            <Grid container xs={12} direction="row" justifyContent="flex-end" sx={{ width: '100%', paddingBottom: 1 }} >
                <Grid item xs={12}>
                    <Typography variant="h4" color="primary.darker">Partnership Process</Typography>
                </Grid>
            </Grid>
            <Grid>
                <Box lg={12} sx={{ flexGrow: 1 }}>
                    <Grid item sx={{ overflowY: "scroll", backgroundColor: 'white', maxHeight: '40vh' }}>
                        <Grid container xs={12} direction="row" justifyContent="flex-end" sx={{ width: '100%' }} >
                            <Grid item xs={12} sx={{ backgroundColor: 'white' }}>
                                <Grid container>
                                    <Grid item xs={12} sx={{ padding: 1, }}>
                                        <Typography variant="h6">Buy Demo kit of worth 2000/-, which is 100% refundable when u add 4 members under you. </Typography>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </>
    )
}