import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import ProductDashboard from 'components/product/ProductDashboard';
import ProductPage from 'components/product/page/ProductPage';
import CategoryDashboard from 'components/category/CategoryDashboard';
import CategoryPage from 'components/category/page/CategoryPage';
import ProductcategoryDashboard from 'components/productcategory/ProductcategoryDashboard';
import ProductcategoryPage from 'components/productcategory/page/ProductcategoryPage';
import CustomerDashboard from 'components/customer/CustomerDashboard';
import CustomerPage from 'components/customer/page/CustomerPage';
import MlmuserDashboard from 'components/mlmuser/MlmuserDashboard';
import MlmuserPage from 'components/mlmuser/page/MlmuserPage';
import MlmusertypeDashboard from 'components/mlmusertype/MlmusertypeDashboard';
import MlmusertypePage from 'components/mlmusertype/page/MlmusertypePage';
import MlmcreditDashboard from 'components/mlmcredit/MlmcreditDashboard';
import MlmcreditPage from 'components/mlmcredit/page/MlmcreditPage';
import MlmorderDashboard from 'components/mlmorder/MlmorderDashboard';
import MlmorderPage from 'components/mlmorder/page/MlmorderPage';
import TandCPage from 'components/mlmuser/page/TandCPage';
import TandcActionImpl from 'components/mlmuser/action/tandc/TandcActionImpl';



// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));

// render - utilities
const Typography = Loadable(lazy(() => import('pages/components-overview/Typography')));
const Color = Loadable(lazy(() => import('pages/components-overview/Color')));
const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
const AntIcons = Loadable(lazy(() => import('pages/components-overview/AntIcons')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    },

    {path: '/product',element: <ProductDashboard />},
{path: '/productpage',element: <ProductPage />},
{path: '/category',element: <CategoryDashboard />},
{path: '/categorypage',element: <CategoryPage />},
{path: '/productcategory',element: <ProductcategoryDashboard />},
{path: '/productcategorypage',element: <ProductcategoryPage />},
{path: '/customer',element: <CustomerDashboard />},
{path: '/customerpage',element: <CustomerPage />},
{path: '/mlmuser',element: <MlmuserDashboard />},
{path: '/mlmuserpage',element: <MlmuserPage />},
{path: '/mlmusertype',element: <MlmusertypeDashboard />},
{path: '/mlmusertypepage',element: <MlmusertypePage />},
{path: '/mlmcredit',element: <MlmcreditDashboard />},
{path: '/mlmcreditpage',element: <MlmcreditPage />},
{path: '/mlmorder',element: <MlmorderDashboard />},
{path: '/mlmorderpage',element: <MlmorderPage />},
{path: '/tandc',element: <TandcActionImpl />},



    {
      path: 'color',
      element: <Color />
    },

    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: <DashboardDefault />
        }
      ]
    },
    {
      path: 'sample-page',
      element: <SamplePage />
    },
    {
      path: 'shadow',
      element: <Shadow />
    },
    {
      path: 'typography',
      element: <Typography />
    },
    {
      path: 'icons/ant',
      element: <AntIcons />
    }
  ]
};

export default MainRoutes;