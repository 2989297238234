import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Grid, Box, Typography, Button, Tooltip, CircularProgress, Checkbox, FormControlLabel } from '@mui/material';

export default function MlmBenefits() {
    const dispatch = useDispatch();
    return (
        <>
            <Grid container xs={12} direction="row" justifyContent="flex-end" sx={{ width: '100%', paddingBottom:1 }} >
                <Grid item xs={12}>
                    <Typography variant="h4" color="primary.darker">Partnership Benefits</Typography>
                </Grid>
            </Grid>
            <Grid>
            <Box lg={12} sx={{ flexGrow: 1 }}>
                <Grid item sx={{ overflowY: "scroll", backgroundColor: 'white', maxHeight: '42vh' }}>
                    <Grid container direction="column" justifyContent="flex-start" sx={{ width: '100%' }} >
                        
                            <Grid item xs={12} sx={{ backgroundColor: 'white' }}>
                                <Grid container>
                                    <Grid item xs={12} sx={{ padding: 1, }}>
                                        <Typography variant="h6">As an MLM partner, you can earn recurring income not just from your direct sales but also from the sales made by your network or team (downline)</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{ backgroundColor: 'white', padding: 1 }}>
                                <Grid container justifyContent="center" sx={{ padding: .25 }} >
                                    <Grid item xs="8">
                                        <Typography variant="h6">Customer Discount</Typography>
                                    </Grid>
                                    <Grid item xs="4">
                                        <Typography variant="h5">&nbsp;&nbsp;&nbsp;20%</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent="center" sx={{ padding: .25 }}>
                                    <Grid item xs="8">
                                        <Typography variant="h6">Level 1 Credit</Typography>
                                    </Grid>
                                    <Grid item xs="4">
                                        <Typography variant="h5">&nbsp;&nbsp;&nbsp;10%</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent="center" sx={{ padding: .25 }}>
                                    <Grid item xs="8">
                                        <Typography variant="h6">Level 2 Credit</Typography>
                                    </Grid>
                                    <Grid item xs="4">
                                        <Typography variant="h5">&nbsp;&nbsp;&nbsp;10%</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent="center" sx={{ padding: .25 }} >
                                    <Grid item xs="8">
                                        <Typography variant="h6">Level 3 Credit</Typography>
                                    </Grid>
                                    <Grid item xs="4">
                                        <Typography variant="h5">&nbsp;&nbsp;&nbsp;5%</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent="center" sx={{ padding: .25 }} >
                                    <Grid item xs="8">
                                        <Typography variant="h6">Level 4 Credit</Typography>
                                    </Grid>
                                    <Grid item xs="4">
                                        <Typography variant="h5">&nbsp;&nbsp;&nbsp;2%</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} sx={{ padding: .5, }}>
                                        <Typography variant="h6">Your income is directly proportional to the effort you invest and the size of your network, offering unlimited potential to scale earnings.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
            
            </Box>
        </Grid>
        </>
    )
}