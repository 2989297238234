import { Grid, Tooltip} from "@mui/material";

export function getAddressStruct(){
    return {
        id:{
            name:'id',
            label:'ID',
            value:'',
            error:false,
            errorMessage:'',
            required:false
        },
        
        businessid:{
            name:'businessid',
            label:'Business Id',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'ID'
        },
        title:{
            name:'title',
            label:'Title',
            value:'',
            error:false,
            errorMessage:'You must enter Title',
            required:true,
            fieldtype:'string'
        },
        partnerid:{
            name:'partnerid',
            label:'Partner Id',
            value:'',
            error:false,
            errorMessage:'You must enter Partner Id',
            required:false,
            fieldtype:'ID'
        },
        pincode:{
            name:'pincode',
            label:'pincode',
            value:'',
            error:false,
            errorMessage:'You must enter pincode',
            required:true,
            fieldtype:'number'
        },
        stateid:{
            name:'stateid',
            label:'State Id',
            value:'',
            error:false,
            errorMessage:'You must enter State Id',
            required:false,
            fieldtype:'bodynamiclist'
        },
        statename:{
            name:'statename',
            label:'State Name',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'string'
        },
        districtid:{
            name:'districtid',
            label:'District Id',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'bodynamiclist'
        },
        districtname:{
            name:'districtname',
            label:'District Name',
            value:'',
            error:false,
            errorMessage:'You must enter District Name',
            required:false,
            fieldtype:'string'
        },
        cityid:{
            name:'cityid',
            label:'City Id',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'bodynamiclist'
        },
        cityname:{
            name:'cityname',
            label:'City Name',
            value:'',
            error:false,
            errorMessage:'You must enter City Name',
            required:true,
            fieldtype:'string'
        },
        addresstypeid:{
            name:'addresstypeid',
            label:'Address Type Id',
            value:'',
            error:false,
            errorMessage:'You must enter Address Type Id',
            required:false,
            fieldtype:'bodynamiclist'
        },
        addresstypename:{
            name:'addresstypename',
            label:'Address Type Name',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'string'
        },
      }
}

export function getColumns(payload){
  let actionList = [
        
        { field: 'title', headerName: 'Title', width:150 },
        { field: 'pincode', headerName: 'pincode', width:150 },
        { field: 'statename', headerName: 'State Name', width:150 },
        { field: 'districtname', headerName: 'District Name', width:150 },
        { field: 'cityname', headerName: 'City Name', width:150 },
        { field: 'addresstypename', headerName: 'Address Type Name', width:150 },
  ];
  if (payload!==undefined){
    payload.actionList && payload.actionList.map((actionObj)=>{
    actionList.push(
      {
        field: actionObj.title,
        headerName: actionObj.title,
        sortable: false,
        width:60,
        disableClickEventBubbling: false,
        renderCell: (params) => {
          return (
              <Grid item onClick={()=>{actionObj.callBackMethod(params.row.id)}}>
                <Tooltip title={actionObj.title} sx={{cursor: "pointer"}}>
                  {actionObj.icon}
                </Tooltip>
              </Grid>
          );
        }
      })
    })
  }
  return actionList;
}

export function setData(data, formValues, setFormValues){
   
    setFormValues({
      ['id']:{
        ...formValues['id'],
        value:data.id,
      },
       ['businessid']:{
          ...formValues['businessid'],
          value:data.businessid,
        },['title']:{
          ...formValues['title'],
          value:data.title,
        },['partnerid']:{
          ...formValues['partnerid'],
          value:data.partnerid,
        },['pincode']:{
          ...formValues['pincode'],
          value:data.pincode,
        },['stateid']:{
          ...formValues['stateid'],
          value:data.stateid,
        },['statename']:{
          ...formValues['statename'],
          value:data.statename,
        },['districtid']:{
          ...formValues['districtid'],
          value:data.districtid,
        },['districtname']:{
          ...formValues['districtname'],
          value:data.districtname,
        },['cityid']:{
          ...formValues['cityid'],
          value:data.cityid,
        },['cityname']:{
          ...formValues['cityname'],
          value:data.cityname,
        },['addresstypeid']:{
          ...formValues['addresstypeid'],
          value:data.addresstypeid,
        },['addresstypename']:{
          ...formValues['addresstypename'],
          value:data.addresstypename,
        },
      })
}