import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import MlmorderDashboard from '../MlmorderDashboard';
import { getListAPI as getMlmorderList } from 'components/mlmorder/MlmorderAPI';
import { setMlmorderList, setMlmorderDashboardMlmorderList } from 'store/slice/DmpSlice';

import { openDrawer } from './../../../store/slice/MenuSlice';

export default function MlmorderPageImpl() {
    const dispatch = useDispatch();
    //const { mlmorderList} = useSelector((state)=>state.dmpslice);

    useEffect(() => {
       // dispatch(openDrawer(false));
        getMlmorderList(()=>{}, getMlmorderListSuccess);
    },[]);
    
    const getMlmorderListSuccess = (list) =>{ dispatch(setMlmorderList(list));
    }
    
    return (
        < MlmorderDashboard />
    )

}