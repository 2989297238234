import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Grid, Box, Typography, Divider } from '@mui/material';


export default function AddressInfo({ obj}) {
  useEffect(() => {
  }, [])
  return (

    <>
      <Grid container direction="column" sx={{backgroundColor:'white',width:'100%', padding:1}}>
        <Grid item sx={{width:'100%'}}>
              <Grid item ><Typography  variant="h6" fontSize={16} color="primary.darker">{obj.title}</Typography></Grid>
              <Grid item ><Typography color="textSecondary" gutterBottom variant="h5" >{obj.cityname} - {obj.pincode}</Typography></Grid>
        </Grid>
      </Grid>

    </>

  )
}