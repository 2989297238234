import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Container, Typography } from '@mui/material';

import { getMlmcreditInfoByOrderidAPI } from 'components/mlmcredit/MlmcreditAPI';
import { getAPI,getMlmorderInfoByOrderidAPI } from 'components/mlmorder/MlmorderAPI';
import FieldView2 from 'core/components/layout/FieldView2';
import { convertToRS, convertToRSWithTwoDec } from 'core/js/FormUtils';

import MlmcreditListTable from 'components/mlmorder/components/mlmcredit/MlmcreditListTable';

export default function MlmOrderDetails({ orderId }) {
    const dispatch = useDispatch();
    const [orderInfo, setOrderInfo] = useState({});
    
    const getOrderListSuccess = (apiResponse) =>{
        setOrderInfo(apiResponse[0]);
    }
    useEffect(() => {
        getMlmorderInfoByOrderidAPI(orderId, ()=>{}, getOrderListSuccess);
    }, []);

    return (
        <Grid container justifyContent="space-between" sx={{padding:.5, width:'100%'}} >
            <Grid item lg={12} xs={12} sx={{width:'100%'}}>
                <Grid container>
                    <Grid item lg={12} xs={12} sx={{ backgroundColor:'secondary.200', borderRadius:2, }}>
                        <Grid container > 
                        <Grid item lg={4} xs={4} ><FieldView2 label="Order By" value={orderInfo.title} /></Grid>
                        <Grid item lg={4} xs={4} ><FieldView2 label="Order Value" value={convertToRS(orderInfo.ordervalue)} /></Grid>
                        <Grid item lg={4} xs={4} ><FieldView2 label="Total Credit" value={convertToRS(orderInfo.creditvalue)} /></Grid>
                    </Grid>
                    </Grid>
                </Grid>
                
            </Grid>
        </Grid>

    )

}