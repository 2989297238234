import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { Grid, Box, Typography, Button, Tooltip, CircularProgress, Checkbox, FormControlLabel } from '@mui/material';
import { getMLMuserAPI } from 'api/CoreMlmmgmtWebServices';
import MlmBenefits from './MlmBenfits';
import MlmProcess from './MlmProcess';
import MlmTandC from './MlmTandC';
import { setMlmUserObj,setPartnerAPISuccess, setSelectedBottomMenu } from 'store/slice/EcomSlice';
import SuccessMessage from 'core/components/SuccessMessage';
import MlmPartnershipProcess from './MlmPartnershipProcess';
import MlmuserPageImpl from 'components/mlmuser/page/MlmuserPageImpl';
import { inviteeLoginSuccessAPI } from 'api/CoreMlmmgmtWebServices';
import { setMlmuserOnboardModal } from 'store/slice/DmpSlice';

export default function MlmLandingPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mlmUserObj, partnerAPISuccess } = useSelector((state) => state.ecomslice.mlm);
  const [apiInProgress, setApiInProgress] = useState(false);
  useEffect(() => {
    setApiInProgress(true);
    getMLMuserAPI({}, getMLMuserSuccess, getMLMuserFailure)
  }, [])
  useEffect(() => {
    console.log("mlmUserObj=>"+JSON.stringify(mlmUserObj));
    if (mlmUserObj!==null && mlmUserObj.loginstatus===0)
      inviteeLoginSuccessAPI({},getMLMuserSuccess, getMLMuserFailure);
  }, [mlmUserObj, partnerAPISuccess])

  const getMLMuserSuccess = (apiResponse) => {
    setApiInProgress(false);
    dispatch(setMlmUserObj(apiResponse.mlmuserInfo));
  }
  const getMLMuserFailure = (errMsg) => {
    setApiInProgress(false);
    alert("!!! ERROR =>" + errMsg + "<= !!!");
  }
  const goToMembership = () =>{
    dispatch(setPartnerAPISuccess(false));
    dispatch(setMlmuserOnboardModal(false));
    dispatch(setSelectedBottomMenu("PARTNER"));

  }
  const getComponent = () =>{
    if (partnerAPISuccess)  return <Grid container>
                                      <Grid item><SuccessMessage message="Thank you for your Partnership. We will get back to for the delivery of your Partnership Kit."/></Grid>
                                      <Grid container justifyContent="flex-end" sx={{paddingTop:5}}>
                                        <Button variant="contained" onClick={()=>goToMembership()}>Go To Membership Page</Button>
                                      </Grid>
                                    </Grid>
    else 
      if (mlmUserObj!==null && mlmUserObj.usertype==="PARTNER")  return <MlmuserPageImpl/>;
      else return <MlmPartnershipProcess/>;
  }
  return (
    <Grid container direction="column"  sx={{ width: '100%' }}>
      {apiInProgress ? <Grid container justifyContent="center" alignItems="center" sx={{ width: '100%', minHeight:'50vh' }}><CircularProgress /></Grid> :
        <>{getComponent()}</>  
      }
    </Grid>
  )
}