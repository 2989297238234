import { isEmailValid } from "./FormValidation";

export const formValuesUtils = (formValues)=>{
    console.log(formValues);
}
export const updateTextValue = (formValues, setFormValues, e) => {
    const {name, value} = e.target;
    setFormValues({
      ...formValues,
      [name]:{
        ...formValues[name],
        value,
        error:false
      }
    })
}
export const updateSelectedValue = (formValues, setFormValues, e, labelField, labelValue) => {
  const {name, value} = e.target;
  setFormValues({
    ...formValues,
    [name]:{
      ...formValues[name],
      value,
      error:false
    },
    [labelField]:{
      ...formValues[labelField],
      value:labelValue,
      error:false
    }
  })
}
export const updateValue = (formValues, setFormValues,name, value) => {
    setFormValues({
      ...formValues,
      [name]:{
        ...formValues[name],
        value:value
      }
    })
}
export const removeAllSpacesAndAllLower=(str)=>{
  return str.replace(/ /g,'').toLowerCase();
}

export const formFieldToReqJSON= (formValues) => {
  const formFields = Object.keys(formValues);

  var jsonStr='{';
  for (let index = 0; index < formFields.length; index++) {
      const currentField = formFields[index];
      const currentValue = formValues[currentField].value;
      
      if (formValues[currentField].fieldtype==='boolean' ||
            formValues[currentField].fieldtype==='number'||
            formValues[currentField].fieldtype==='double'){
        
              if (currentValue===''){
                jsonStr += '"'+currentField+'":0';
              } else {
                jsonStr += '"'+currentField+'":'+currentValue;
              }
      } else {
        jsonStr += '"'+currentField+'":'+'"'+currentValue+'"'
      }

      if (index<formFields.length-1)  jsonStr +=',';
  }
  jsonStr +='}';
  return JSON.parse(jsonStr);
}

export const handleSubmit = (e, id, formValues, setFormValues,createSuccessCallback, updateSuccessCallback, failuerCallback, createAPI, updateAPI) => {
  e.preventDefault();
  
  const formFields = Object.keys(formValues);
  let newFormValues = {...formValues}

  let okToSubmit = true;
  for (let index = 0; index < formFields.length; index++) {
    const currentField = formFields[index];
    const currentValue = formValues[currentField].value;
    const requiredFlag = formValues[currentField].required;
    const fieldType = formValues[currentField].fieldtype;

    if(currentValue === '' && requiredFlag){
      console.log('Unable to submit due to mandatory field '+currentField);
      okToSubmit = false;
      newFormValues = {
        ...newFormValues,
        [currentField]:{
          ...newFormValues[currentField],
          error:true
        }
      }
    }
    if(okToSubmit && fieldType==='emailid' && !isEmailValid(currentValue)){
      console.log('Unable to submit due to Email id is not valid '+currentField);
      okToSubmit = false;
      newFormValues = {
        ...newFormValues,
        [currentField]:{
          ...newFormValues[currentField],
          error:true,
          errorMessage:'Email Id is not valid'

        }
      }
      if(okToSubmit && fieldType==='mobile' && currentValue.length<10){
        okToSubmit = false;
        newFormValues = {
          ...newFormValues,
          [currentField]:{
            ...newFormValues[currentField],
            error:true,
            errorMessage:'Mobile number is not valid'
  
          }
        }
      }
    }
    
  }
  setFormValues(newFormValues);
  
  if (okToSubmit){
      if (id===-1){
          createAPI(newFormValues, createSuccessCallback, failuerCallback);
      } else {
          updateAPI(id, newFormValues, updateSuccessCallback,failuerCallback)
      }
  }
  return false;
}

//{key:1, label:'ID', value:'1'},
export const json2keyvalueArray=(json)=>{
  var keyArr = [];
  Object.keys(json).forEach(function(key) {
  keyArr.push(key);
  });

  var valArr = [];
  Object.values(json).forEach(function(value) {
  valArr.push(value);
  });

  let str ="["
  for (let ii = 0; ii < keyArr.length; ii++){
    str =str+"{\"key\":"+ii+", \"label\":\""+keyArr[ii]+"\", \"value\":\""+valArr[ii]+"\"}"
    if (ii<keyArr.length-1) str=str+",";
  }
  str = str+"]"
  
  return str;
}

export function transformToCardFormat(dataList, setCardList){
  var cardList = [];
  dataList.map((item)=>{
    let fieldList = []
    Object.keys(item).forEach(function(key) {
      let fieldItem = {}
      if (key!=='id'){
        fieldItem['fieldLabel']=key;
        if (item[key]===true)
          fieldItem['fieldValue']="Yes";
        else if (item[key]===false)
          fieldItem['fieldValue']="No";
        else
          fieldItem['fieldValue']=item[key];
        fieldList.push(fieldItem);
      }
    })

    let card = {}
    card['id']=item.id;
    card['fieldList'] = fieldList;

    cardList.push(card);
  })
  setCardList(cardList);
}

export const transformDateValue=(fieldValue)=>{
  if (fieldValue==undefined || fieldValue=='')  return fieldValue;

  let onlyDateArray = (fieldValue.split('T')[0]).split('-');
  return (onlyDateArray[2]+'-'+onlyDateArray[1]+'-'+onlyDateArray[0])
}
export const getTime=(fieldValue)=>{
  if (fieldValue==undefined || fieldValue=='')  return fieldValue;

  let onlyDateArray = (fieldValue.split('T')[1]).split('.');
  return (onlyDateArray[0])
}
export const convertToRS = (number)=>{
  const formattedRupees = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 2
  }).format(number);

  return formattedRupees.substring(0,formattedRupees.length-3);
}
export const convertToRSWithTwoDec = (number)=>{
  if (Number.isNaN(number) || number===undefined) number=0;
  const formattedRupees = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 2
  }).format(number);

  return formattedRupees;
}