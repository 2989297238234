import * as React from 'react';
import {Grid, Typography, Divider, Tooltip} from '@mui/material';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

export default function FieldView({label, value, color,fontSize}) {
  
  return (
    <Grid item sx={{width:'100%',  alignItems:"center", backgroundColor:'secondary.100'}} >
        <Grid container  justifyContent="space-between" direction="row"   sx={{width:'100%'}}>
              
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{backgroundColor:color===undefined?'':color,padding:.5, borderRadius:3,wordBreak: 'break-word', overflowWrap: 'break-word'}}> 
                  <Typography   variant="h6" >{label}</Typography>
                  <Typography color="textSecondary" sx={{ fontWeight: 'light',fontSize: fontSize===undefined?12:fontSize }}>{value}</Typography>
              </Grid>
        
        </Grid>
    </Grid>
       
  );
}