import React, { useState, useEffect } from 'react'
import { useSearchParams } from "react-router-dom";

import AddIcon from '@mui/icons-material/Add';
import { Grid, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import DeleteNotification from '../../core/components/DeleteNotification';
import { CoreContext } from "../../core/context/CoreContext";
import PageWaiting from '../../core/components/PageWaiting';
import { isMobile } from 'react-device-detect';

import { getListAPI, deleteAPI } from './MlmuserAPI';
import { getColumns } from './MlmuserStruct';
import ListCardView from '../../core/components/card/ListCardView';
import ListTableView from '../../core/components/card/ListTableView';

import { getMlmuserView } from './MlmuserView';
import CardListLayout from '../../core/components/card/layout/CardListLayout';
import { useSelector, useDispatch } from 'react-redux';
import { setViewParams } from '../../store/slice/ChoiceSelectionSlice';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';

import { setFilter } from '../../store/slice/ConfSlice';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import MyPageContainer from '../../core/template/MyPageContainer';
import { deleteMlmuserObj, setSelectedMlmuserId, setMlmuserTandcModal, setSelectedActionId, setShowEditForMlmuser } from 'store/slice/DmpSlice';

import MlmuserListTable from './components/MlmuserListTable';
import { useNavigate } from "react-router-dom";
import { setClickedBo, setActionClicked } from 'store/slice/BreadCrumSlice';
import MlmuserFilterCriteria from './MlmuserFilterCriteria';
import ShareIcon from '@mui/icons-material/Share';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { setShowGraph } from 'store/slice/EcomSlice';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';


import HvacIcon from '@mui/icons-material/Hvac';

import TandcActionModal from './action/tandc/TandcActionModal';

import { transformDateValue } from 'core/js/FormUtils';
import { getAPIprefix } from 'api/ApiUtils';

export default function MlmuserListImpl({ tableList, cardList, setShowEdit, setMlmuserId }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setNotification, setShowLoading, showloading, lastURL } = React.useContext(CoreContext);
  const columns = getColumns(editCallBack, initDeleteCallBack);
  const [view, setView] = useState(1);
  const { showfilter } = useSelector((state) => state.confslice)
  const { showGraph } = useSelector((state) => state.ecomslice.mlm);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const { selectedId, mlmuserObj } = useSelector((state) => state.dmpslice.mlmuserDashboard);
  const { mlmuserList } = useSelector((state) => state.dmpslice.mlmuserDashboard);

  useEffect(() => {
  }, [mlmuserList, mlmuserObj])

  function initDeleteCallBack(id) {
    dispatch(setSelectedMlmuserId(id));
    setShowDeleteAlert(true);
  }
  function deleteCallBack(obj) {
    dispatch(deleteMlmuserObj(obj.id));
    setNotification('Record deleted successfully !!!')
  }
  function deleteRecord() {
    deleteAPI(selectedId, deleteCallBack);
    setShowDeleteAlert(false);
  }

  function editCallBack(id) {
    dispatch(setSelectedMlmuserId(id));
    dispatch(setShowEditForMlmuser(true));
  }

  const createMlmuser = () => {
    dispatch(setSelectedMlmuserId(-1));
    dispatch(setShowEditForMlmuser(true));
  }


  const tandcCallBack = (id) => {
    dispatch(setSelectedMlmuserId(id));
    dispatch(setMlmuserTandcModal(true));
  }

  const getDataWithDateTransformation = (data) => {
    let newData = {
      ...data,
      'expand': false,

    }
    return newData;
  }
  const mlmuserTransformation = (tableList) => {
    let newDataList = []
    tableList.map((data) => {
      newDataList.push(getDataWithDateTransformation(data));
    })
    return newDataList;
  }
  const handleCopy = async (id) => {
    dispatch(setSelectedMlmuserId(id));
    let index = mlmuserList.map(mlmuserObj => mlmuserObj.id).indexOf(id);
    let url = window.location.href;
    let urlPrefix = url.substr(0, url.lastIndexOf('/'))
    const linkToCopy = urlPrefix + "/?inviteelogin/" + mlmuserList[index].mobilenumber;
    console.log('linkToCopy===>' + linkToCopy);
    try {
      await navigator.clipboard.writeText(linkToCopy);
      alert("Link copied to clipboard!");
      console.log("Link copied=>" + linkToCopy);
    } catch (err) {
      console.error("Failed to copy text: ", err);
      alert("Failed to copy the link. Please try again.");
    }
  };
  const shareLink = (id) => {

  }

  const payload = {
    mlmuserList: mlmuserList,
    cardList: getMlmuserView(tableList),
    actionList: [
      /*{title:'Edit', callBackMethod:editCallBack, icon:<EditIcon/>, status:'NA'},
      {title:'Delete', callBackMethod:initDeleteCallBack, icon:<DeleteIcon/>, status:'NA'},
      
    {id:59, title:'TandC', callBackMethod:tandcCallBack, icon:<HvacIcon/>, status:'Draft', role:''},
    */
    ],
    dialogueActionList: [

    ],
    tableView: {
      tableList: mlmuserTransformation(tableList),
      mlmuserList: mlmuserList,
      actionList: [
        { title: 'Edit', callBackMethod: editCallBack, icon: <EditIcon />, status: 0 },
        // {title:'Delete', callBackMethod:initDeleteCallBack, icon:<DeleteIcon/>, status:'NA'},

        { id: 59, title: 'Share Link', callBackMethod: handleCopy, icon: <ShareIcon />, status: 2, role: '' },
      ]
    }
  }

  const getPayload = () => {
    return payload;
  }

  useEffect(() => {
    if (document.body.offsetWidth < 500) setView(0);
    dispatch(setViewParams({
      colsCount: { xs: 12, sm: 4, md: 4, lg: 4 },
      modal: {

      }
    }))
  }, [])

  return (
    <>
      {showloading ? <PageWaiting /> :
        <Grid container direction="column" >
          {/* <Grid item xs={12} sx={{width:'100%'}}>
            <Grid container direction="row" justifyContent="space-between" alignContent="center" >
              <Grid item xs={4} lg={3} >
                <Typography variant='h5' color="primary.darker">My Member(s)</Typography>
              </Grid>
              <Grid item xs={1} lg={1} sx={{color:'primary.darker'}}>
                    {showGraph ?
                      <Tooltip title="Hide filter" sx={{ cursor: "pointer" }}>
                        <FormatListNumberedIcon onClick={() => { dispatch(setShowGraph(false)) }} />
                      </Tooltip>
                      :
                      <Tooltip title="Hide filter" sx={{ cursor: "pointer" }}>
                        <AccountTreeIcon onClick={() => { dispatch(setShowGraph(true)) }} />
                      </Tooltip>}
                  
              </Grid>
            </Grid>
          </Grid> */}
          <Grid item sx={{paddingTop:.5}}>
            < MlmuserListTable
              payload={getPayload().tableView}
            />

          </Grid>
        </Grid>
      }
      {showDeleteAlert ? <DeleteNotification
        id={selectedId}
        width="50%"
        setShowDeleteAlert={setShowDeleteAlert}
        deleteRecord={deleteRecord} /> : ''
      }


      <TandcActionModal />
    </>
  );


}