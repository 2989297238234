import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Button, Grid, Typography, CircularProgress, Divider, TextField, Box } from "@mui/material";
import { setShowImageGallery, setUploadedAssetObj, setSelectedImage } from 'store/slice/ImageGallerySlice';
import { setApiInProgress } from 'store/slice/HeaderSlice';
import { getAPIprefix } from '../../../api/ApiUtils';
import assetAPI from './ImageGalleryAPI';

const ImageGalleryUploadNewImage = () => {
    const dispatch = useDispatch();
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadResult, setUploadResult] = useState("NA");
    const [previewUrl, setPreviewUrl] = useState(null);
    const fileInputRef = useRef(null);

    const { uploadedAssetObj } = useSelector((state) => state.imagegalleryslice);
    const { apiInProgress } = useSelector((state) => state.headerslice)

    useEffect(() => {

    }, [uploadedAssetObj, apiInProgress])


    const handleFileChange = (event) => {
        setUploadResult('NA');
        setSelectedFile(event.target.files[0]);
        if (event.target.files[0]) {
            setPreviewUrl(URL.createObjectURL(event.target.files[0]));
        }
    };

    const handleButtonClick = () => {
        setUploadResult('NA');
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    const handleAssetUploadSuccess = (responseObj) => {
        dispatch(setSelectedImage(responseObj.assetInfo))
        dispatch(setApiInProgress(false))
        dispatch(setUploadedAssetObj(responseObj.assetInfo))
        setUploadResult("success");
        setSelectedFile(null);
        console.log("responseObj=>" + JSON.stringify(responseObj));
    }
    const handleAssetUploadFailure = (errorObj) => {
        dispatch(setApiInProgress(false))
        setUploadResult("failure");
        console.log("errorObj=>" + errorObj);
    }
    const handleSubmit = async (event) => {
        setUploadResult("NA");
        event.preventDefault();
        dispatch(setApiInProgress(true))

        const formData = new FormData();
        formData.append('file', selectedFile);
        const token = localStorage.getItem("JWT");

        try {
            const response = await assetAPI.post('/extn/asset', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                },
            });

            console.log('File uploaded successfully:', response.data);
            handleAssetUploadSuccess(response.data)
        } catch (error) {
            // Check if error.response exists
            if (error.response) {
                if (error.response.status === 208) {
                    console.error('File upload already processed. No action taken.');
                } else {
                    console.error('Error uploading file:', error.response.data || error.message);
                    handleAssetUploadFailure(error.response.data || error.message)
                }
            } else {
                // If error.response is undefined
                console.error('Unexpected error occurred:', error.message || error);
                handleAssetUploadFailure(error.message || error)
            }

        }
    };
    const getMessage = () => {

        if (uploadResult === "failure") return <Typography color="error" gutterBottom><b><i>Asset with same name already uploaded.</i></b></Typography>
        if (uploadResult === "success") return <Typography ><b><i>Uploaded successfully</i></b></Typography>

        if (previewUrl) return <Typography ><b><i>Click on 'Upload' Button to upload selected image</i></b></Typography>
        else return <Typography ><b><i>Click on 'Image from Local Machine' button to select an Image.</i></b></Typography>
    }
    return (
        <>
            <form onSubmit={handleSubmit}>
                <Grid container direction="column" lg={12} sx={{ backgroundColor: 'white', height: '65vh', justifyContent: "center", alignItems: "center" }}>
                    <input type="file" accept="image/*" onChange={handleFileChange} ref={fileInputRef} style={{ display: 'none' }} />

                    <Grid item lg={8} md={8}>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container sx={{ overflowY: "scroll", width: '100%', height: '65vh',justifyContent: "center", alignItems: "center" }}>
                                {previewUrl && (
                                    <div>
                                        <img src={previewUrl} alt="Selected" style={{ width: '100%', height: '100%' }} />
                                    </div>
                                )}
                                {!previewUrl && (
                                    <Grid container sx={{ justifyContent: "center", alignItems: "center" }}>
                                        <Grid sx={{  backgroundColor: '#e6f7ff', borderRadius: 5 }}>
                                            <Typography sx={{ padding: 2 }}><i>Click on 'Image From Local Machine' button for Image selection and then click on 'Upload' Button.</i></Typography>
                                        </Grid>

                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container direction="row" lg={12} md={12} sm={12} sx={{ justifyContent: "flex-end", alignItems: "center"  }}>
                    <Grid item lg={4} md={4} container sx={{ justifyContent: "flex-start", alignItems: "center", padding:1 }}>
                        {getMessage()}
                    </Grid>
                    {!apiInProgress ?
                        <Grid item lg={8}  md={8} sm={8} sx={{ justifyContent: "flex-end", alignItems: "center",}}>
                            <Grid container sx={{justifyContent: "flex-end", alignItems: "center", padding:1}}>
                            <Button variant="outlined" onClick={() => handleButtonClick()}>{selectedFile === null ? 'Image From Local Machine' : 'Change Local Machine Image'}</Button>
                            &nbsp;&nbsp;&nbsp;
                           <Button variant="outlined" onClick={handleSubmit} disabled={!selectedFile}>Upload</Button>
                           &nbsp;&nbsp;&nbsp;
                           <Button variant="outlined" onClick={() => { dispatch(setShowImageGallery(true)) }}>Back to Gallery</Button>
                            </Grid>
                            
                        </Grid>
                        : <>
                            <Grid item lg={8} md={8} sm={8} container justifyContent="center" >
                                <CircularProgress />
                            </Grid>
                        </>}

                </Grid>
            </form>
        </>

    )
}
export default ImageGalleryUploadNewImage;