import { createSlice } from '@reduxjs/toolkit';

export const ConfSlice = createSlice({
    name: "confslice",
    initialState: {
        showfilter: false,
        showDialogue: false,
        tablerequest:{
            screen: 'tableawaited'
        }
    },
    reducers: {
    
        setFilter: (state, action) =>{
            state.showfilter=action.payload;
        },
        setTablereRuestScreen: (state, action) =>{
            state.tablerequest.screen = action.payload;
        },
        setShowDialogue: (state, action) =>{
            state.showDialogue = action.payload;
        }
    }
});
export const { setFilter, setTablereRuestScreen, setShowDialogue } = ConfSlice.actions;
export default ConfSlice.reducer;