import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
export function getAPIprefix(){
    return "https://appmachine.in";
}
export function getOPScoreAPIPrefix(){
    return "https://appmachine.in/ops-core-api";
}
export function getOPSserviceAPIPrefix(){
    return "https://appmachine.in/ops-core-service";
}
export function getOPSAnonymousServiceAPIPrefix(){
    return "https://appmachine.in/ops-core-api/anonymous";
}

export function callAPI(api, method, payload, successCallBack, failuerCallBack){
    const token = localStorage.getItem("JWT");
    const requestId = uuidv4().split("-")[4];
    
    let requestOptions = {
    }

    if (method==='GET'){
        requestOptions = {
            method: method,
            headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${token}` }
        }
    } else {
        requestOptions = {
            method: method,
            headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${token}` },
            body: JSON.stringify(payload)
        };
    }
    fetch(api, requestOptions)
        .then(async function(response) {
            let errorMessage = "Something Went Wrong"
            if(response.ok)
            {
                return response.json();         
            }
            else {
                if (response.status===208){
                    errorMessage = "Record already exist";
                } else if (403 === response.status) {
                    errorMessage = "Access Denied"
                }else if (400 === response.status) {
                    return response.json().then((errorData) => {
                        console.error('400 Error:', errorData);
                        errorMessage=errorData.message;
                        throw new Error(errorMessage);
                      });
                }
                else if (500 === response.status) {
                } 
            }
            throw new Error(errorMessage);
        })
        .then((response) => {
            successCallBack(response);
        })
        .catch(function(error) { 
            console.log('Request failed', error.message);
            failuerCallBack(error.message);
        });
}
export function callAPIwithoutJWT(api, method, payload, successCallBack, failuerCallBack){
    const requestId = uuidv4().split("-")[4];
    
    let requestOptions = {
            method: method,
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify(payload)
        };
    fetch(api, requestOptions)
        .then(async function(response) {
            let errorMessage = "Something Went Wrong"
            if(response.ok)
            {
                return response.json();         
            }
            else {
                if (response.status===208){
                    errorMessage = "Record already exist";
                } else if (403 === response.status) {
                    errorMessage = "Access Denied"
                }else if (400 === response.status) {
                    return response.json().then((errorData) => {
                        console.error('400 Error:', errorData);
                        errorMessage=errorData.message;
                        throw new Error(errorMessage);
                      });
                }
                else if (500 === response.status) {
                } 
            }
            throw new Error(errorMessage);
        })
        .then((response) => {
            successCallBack(response);
        })
        .catch(function(error) { 
            console.log('Request failed', error.message);
            failuerCallBack(error.message);
        });
}

export function callGetAPI(api, setList, successCallBack, failuerCallBack){
    const token = localStorage.getItem("JWT");
    const requestId = uuidv4().split("-")[4];

    let requestOptions = {
        method: "GET",
        headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${token}` }
    }
    fetch(api, requestOptions)
        .then(async function(response) {
            let errorMessage = "Something Went Wrong"
            if(response.ok)
            {
                return response.json();         
            }
            else {
                if (response.status===208){
                    errorMessage = "Record already exist";
                } else if (403 === response.status) {
                    errorMessage = "Access Denied"
                }else if (400 === response.status) {
                    return response.json().then((errorData) => {
                        console.error('400 Error:', errorData);
                        errorMessage=errorData.message;
                        throw new Error(errorMessage);
                      });
                }
                else if (500 === response.status) {
                } 
            }
            throw new Error(errorMessage);
        })
        .then((response) => {
            successCallBack(response.list);
            setList(response.list);
        })
        .catch(function(error) { 
            console.log('Request failed', error.message);
            failuerCallBack(error.message);
        });
}
export function callGetWithoutJWTAPI(api, setList, successCallBack, failuerCallBack){
    const requestId = uuidv4().split("-")[4];

    let requestOptions = {
        method: "GET",
        headers: { 'Content-Type': 'application/json'}
    }
    fetch(api, requestOptions)
        .then(async function(response) {
            let errorMessage = "Something Went Wrong"
            if(response.ok)
            {
                return response.json();         
            }
            else {
                if (response.status===208){
                    errorMessage = "Record already exist";
                } else if (403 === response.status) {
                    errorMessage = "Access Denied"
                }else if (400 === response.status) {
                    return response.json().then((errorData) => {
                        console.error('400 Error:', errorData);
                        errorMessage=errorData.message;
                        throw new Error(errorMessage);
                      });
                }
                else if (500 === response.status) {
                } 
            }
            throw new Error(errorMessage);
        })
        .then((response) => {
            successCallBack(response.list);
            setList(response.list);
        })
        .catch(function(error) { 
            console.log('Request failed', error.message);
            failuerCallBack(error.message);
        });
}