import React, { useState, useEffect } from 'react'

import { Grid, Typography, Box, Tooltip } from '@mui/material';

import { CoreContext } from '../../../core/context/CoreContext';
import PageWaiting from '../../../core/components/PageWaiting';
import IconEnabled from '../../../core/components/layout/IconEnabled';
import IconDisabled from 'core/components/layout/IconDisabled';
import { useDispatch, useSelector } from 'react-redux';
import MlmuserListTableHeader from './MlmuserListTableHeader';
import MlmuserListTableRow from './MlmuserListTableRow';
import MembershipTree from './MembershipTree';
import { setSelectedMlmuserId, setShowEditForMlmuser } from 'store/slice/DmpSlice';
import AddIcon from '@mui/icons-material/Add';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { setShowGraph } from 'store/slice/EcomSlice';


export default function MlmuserListTable({ payload }) {
  const dispatch = useDispatch();
  const { showloading } = React.useContext(CoreContext);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const { userprofileInfo } = useSelector((state) => state.headerslice)
  const { showGraph } = useSelector((state) => state.ecomslice.mlm);
  useEffect(() => {
    setRows(payload.tableList);
  }, [payload])

  const handleRowClick = (rowId) => {
    setRows(prevData =>
      prevData.map(item =>
        item.id === rowId ? { ...item, 'expand': !item.expand } : item
      )
    );
  }
  const getActionComponent = (rowObj) => {
    return <>
      {payload.actionList.map(actionObj => {
        if ((actionObj.status === 2) || (actionObj.status !== '' && actionObj.status === rowObj.loginstatus))
          return <IconEnabled actionObj={actionObj} id={rowObj.id} />
      })}
    </>
  }
  const createMlmuser = () => {
    dispatch(setSelectedMlmuserId(-1));
    dispatch(setShowEditForMlmuser(true));
  }
  return (
    <>
      <Grid container direction="column" sx={{ justifyContent: "flex-start", alignItems: "flex-start", }}>
        <Grid item sx={{ width: '100%' }}>
          {showGraph ? <MembershipTree /> :
            <>
              <Box lg={12} sx={{ flexGrow: 1 }}>
                <Grid item sx={{ overflowY: "scroll", maxHeight: '58vh', minHeight:'58vh' }}>
                  <Grid container direction="column" sx={{ justifyContent: "flex-start", alignItems: "flex-start", width: '100%' }}>
                    {rows.map(obj => {
                      return (
                        < MlmuserListTableRow key={obj.id} obj={obj} getActionComponent={getActionComponent} handleRowClick={handleRowClick} />
                      )
                    })}
                    {
                      rows.length === 0 ?
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          sx={{ width: '100%', backgroundColor: 'white', height: '58vh', borderRadius: 2 }}

                        >
                          <Typography sx={{ fontWeight: 'light', fontSize: 14 }}><i>Member(s) are not added, yet.</i></Typography>
                        </Grid>
                        : <></>
                    }
                  </Grid>

                </Grid>
              </Box>
              <Grid container  justifyContent="space-between" alignItems="center" sx={{ paddingTop: 2 }} >
                <Grid onClick={() => { dispatch(setShowGraph(true)) }} container sx={{ cursor: "pointer" , width: '40%', backgroundColor: 'primary.dark', padding: .50, borderRadius: 2, color: 'white' }}>
                  <Tooltip title="Add Mlmuser" sx={{ cursor: "pointer" }}>
                    <AccountTreeIcon />
                  </Tooltip>
                  &nbsp;&nbsp;Graph View
                </Grid>

                <Grid container onClick={() => { createMlmuser() }} sx={{ cursor: "pointer" , width: '40%', backgroundColor: 'primary.dark', padding: .50, borderRadius: 2, color: 'white' }}>
                  <Tooltip title="Add Mlmuser" sx={{ }}>
                    <AddIcon />
                  </Tooltip>
                  Add Member
                </Grid>
              </Grid>
            </>}

        </Grid>

      </Grid>
    </>
  );


}