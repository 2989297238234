import React from "react";

export const initialState = {
    notification:'',
    showloading:false,
    lastURL:'',
    parentURL:'',
    homeURL:'',
    keyvalues: new Map()
  };
  
export const actions = {
    SET_NOTIFICATION: "SET_NOTIFICATION",
    SET_SHOW_LOADING: "SET_SHOW_LOADING",
    SET_LAST_URL: "SET_LAST_URL",
    SET_PARENT_URL: "SET_PARENT_URL",
    SET_HOME_URL: "SET_HOME_URL",
    SET_KEY_VALUE:"SET_KEY_VALUE",
    GET_KEY_VALUE:"GET_KEY_VALUE"
  };
  
  const setKeyValues=(keyvalues, key,value)=>{
    keyvalues.set(key,value);
    return keyvalues;
  }
export const reducer = (state, action) => {
    switch (action.type) {
      case actions.SET_NOTIFICATION: {
        return {
            notification: action.notification,

            showloading:state.showloading,
            homeURL: state.homeURL,
            parentURL: state.parentURL,
            lastURL: state.lastURL,
            keyvalues:state.keyvalues
        }
      }
      case actions.SET_SHOW_LOADING: {
        return {
          showloading: action.flag,

          notification: state.notification,
          homeURL: state.homeURL,
          parentURL: state.parentURL,
          lastURL: state.lastURL,
          keyvalues:state.keyvalues
        }
      }
      case actions.SET_LAST_URL: {
        return {
          lastURL: action.url,

          notification: state.notification,
          showloading:state.showloading,
          homeURL: state.homeURL,
          parentURL: state.parentURL,
          keyvalues:state.keyvalues
        }
      }
      case actions.SET_PARENT_URL: {
        return {
          parentURL: action.url,

          notification: state.notification,
          showloading:state.showloading,
          homeURL: state.homeURL,
          lastURL: state.lastURL,
          keyvalues:state.keyvalues
        }
      }
      case actions.SET_HOME_URL: {
        return {
          homeURL: action.url,

          notification: state.notification,
          showloading:state.showloading,
          parentURL: state.parentURL,
          lastURL: state.lastURL,
          keyvalues:state.keyvalues
        }
      }
      case actions.SET_KEY_VALUE: {
        return {
          keyvalues: setKeyValues(state.keyvalues, action.key, action.value),
          homeURL: state.url,
          notification: state.notification,
          showloading:state.showloading,
          parentURL: state.parentURL,
          lastURL: state.lastURL,
        }
      }
      default:
        return state;
    }
    
};