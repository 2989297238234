import { useState, useEffect } from 'react';
import { Grid, Button, Typography, Box, CircularProgress } from '@mui/material';
import { getMlmuserInfoByMobilenumberAPI } from 'components/mlmuser/MlmuserAPI';
import { useSelector, useDispatch } from 'react-redux';
import { addMlmuserObj, setMlmuserOnboardModal, setMlmuserTandcModal } from 'store/slice/DmpSlice';
import { getAppuserInfoByAuthUserIdAPI } from 'api/IdentityServiceAPI';
import Cookies from 'js-cookie';
import { updateCartCustomerAPI } from 'core/components/ecom/EcomAPI';
import { setCartObj, setPartnerObj, setInviteeObj,setInviteeProcessStatus } from 'store/slice/EcomSlice';
import { sendCustomerOTPAPI, validateCustomerOtpAPI } from 'pages/authentication/components/AuthAPI';
import { setLoggedIn, setAppuserInfo, setuserprofileInfo } from 'store/slice/HeaderSlice';
import ErrorMessage from 'core/components/ErrorMessage';
import { inviteeLoginSuccessAPI } from 'api/CoreMlmmgmtWebServices';
import Welcome from './Welcome';
import SuccessMessage from 'core/components/SuccessMessage';
import { setSelectedBottomMenu } from 'store/slice/EcomSlice';
import MlmLandingPage from 'components/mlmuser/components/mlmmgmt/MlmLandingPage';
import MemberRegistrationDone from './MemberRegistrationDone';
import InitiatePartnership from './InitiatePartnership';
import AlreadyOnboarded from './AlreadyOnboarded';
import { setMlmUserObj } from 'store/slice/EcomSlice';
import { getUserAPI } from 'api/IdentityServiceAPI';

export default function OnboardActionImpl() {
    const { inviteeMobileNumber, partnerObj, inviteeObj,inviteeProcessStatus } = useSelector((state) => state.ecomslice.mlm);
    //const { mlmuserObj } = useSelector((state) => state.dmpslice.mlmuserDashboard);
    const { mlmUserObj } = useSelector((state) => state.ecomslice.mlm);
    const dispatch = useDispatch();
    const [otpReqId, setOtpReqId] = useState('');
    const [otp, setOtp] = useState('');
    const { userprofileInfo } = useSelector((state) => state.headerslice);
    const [apiInProgress, setApiInProgress] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [choice, setChoice] = useState("MEMBER");
    const [memberSuccessDone, setMemberSuccessDone] = useState(false);
    const [partnerSuccessDone, setPartnerSuccessDone] = useState(false);
    const [memberAPIinProgress, setMemberAPIinProgress] = useState(false);
    const [alreadyOnboarded, setAlreadyOnboarded] = useState(false);

    


    useEffect(() => {
        if (userprofileInfo.email !== null){
            setApiInProgress(true);
            getMlmuserInfoByMobilenumberAPI(inviteeMobileNumber, () => { }, getInviteeSuccess);
        }
    }, [userprofileInfo])
    useEffect(() => {
        if (mlmUserObj && mlmUserObj.seniorpartnerid !== undefined)
            getAppuserInfoByAuthUserIdAPI(mlmUserObj.seniorpartnerid, () => { }, getInviteePartnerSuccess)
    }, [mlmUserObj])

    useEffect(() => {
        if (Cookies.get('anonymousId') !== undefined && userprofileInfo.rolename !== "MLM_CUSTOMER")
            updateCartCustomerAPI({
                "anonymousId": Cookies.get('anonymousId')
            }, updateCartCustomerSuccess, updateCartCustomerFailure
            )
    }, [userprofileInfo])

    const updateCartCustomerSuccess = (apiResponse) => {
        dispatch(setCartObj(apiResponse.cartInfo));
    }
    const updateCartCustomerFailure = (errMsg) => {
        console.log(' !!! Error !!! ' + errMsg);
    }

    useEffect(() => {
        if (otp !== '')
            validateOTP();
    }, [otp])

    const getInviteeSuccess = (apiResponse) => {
        if (apiResponse.length > 0) {
            dispatch(setInviteeObj(apiResponse[0]));
            dispatch(addMlmuserObj(apiResponse[0]))
            dispatch(setMlmUserObj(apiResponse[0]))
            if (apiResponse[0].userid!==0)
                setAlreadyOnboarded(true);
        } else {
            setApiInProgress(false);
            setErrMsg('Invalid Link');
        }
    }
    const getInviteePartnerSuccess = (apiResponse) => {
        dispatch(setPartnerObj(apiResponse[0]));
        setApiInProgress(false);
    }


    const loginCustomer = () => {
        sendCustomerOTPAPI(inviteeMobileNumber + "@appmachine.in", getOTPsuccess, getOTPfailure)
    }
    const getOTPsuccess = (apiResponse) => {
        setOtpReqId(apiResponse.otpResponse.id);
        setOtp(apiResponse.otpResponse.otp);
    }
    const getOTPfailure = (errMsg) => {
        alert("!!! ERROR ==>" + errMsg);
    }
    const validateOTP = () => {
        validateCustomerOtpAPI(otpReqId, otp, validateCustomerOTPsuccessCallBack, failuerCallback);
    }
    const getMLMuserSuccess = (apiResponse) => {
        dispatch(setMlmUserObj(apiResponse.mlmuserInfo));
        getUserAPI(loadUserSuccessCallBack, ()=>{});
    }
    const getMLMuserFailure = (errMsg) => {
    setApiInProgress(false);
    alert("!!! ERROR =>" + errMsg + "<= !!!");
    }
    const loadUserSuccessCallBack = (response) => {
        dispatch(setuserprofileInfo(response.userprofileInfo));
}
    const validateCustomerOTPsuccessCallBack = (data) => {
        localStorage.setItem("JWT", data.accesstoken);
        dispatch(setLoggedIn(true));
        dispatch(setAppuserInfo(data.appuserInfo));
        dispatch(setuserprofileInfo(data.userprofileInfo));

        if (mlmUserObj!==null && mlmUserObj.loginstatus===0){
            inviteeLoginSuccessAPI({},getMLMuserSuccess, getMLMuserFailure);
        }
        setMemberAPIinProgress(false);
        if (choice === 'MEMBER') {
            dispatch(setInviteeProcessStatus('MEMBERSHIP_DONE'))
            setMemberSuccessDone(true);

        } else if (choice === 'PARTNER') {
            dispatch(setInviteeProcessStatus('PARTNERSHIP_LOGIN_DONE'))
            setPartnerSuccessDone(true);
        }
    }
    const failuerCallback = (data) => {

    }
    const continueMember = () => {
        setMemberAPIinProgress(true);
        setChoice("MEMBER");
        loginCustomer();
    }
    const bePartner = () => {
        setMemberAPIinProgress(true);
        setChoice("PARTNER");
        loginCustomer();
    }
    
    const getComponent = () => {
        if (alreadyOnboarded)   return <AlreadyOnboarded/>

        if (errMsg!=='') return <Grid container justifyContent="center" alignItems="center" sx={{height:'68vh', backgroundColor:'white', borderRadius:2}}>
                             <ErrorMessage message="Invlaid Link"/>
                         </Grid>

        if (inviteeProcessStatus==='MEMBERSHIP_DONE')  return <MemberRegistrationDone/>
        if (inviteeProcessStatus==='PARTNERSHIP_LOGIN_DONE') return <InitiatePartnership/>
        if (inviteeProcessStatus==='PARTNERSHIP_PROCESS_START') return <MlmLandingPage/>
        
        if (apiInProgress) {
            return <Grid container alignItems="center" justifyContent="center" sx={{ height: '55vh' }}>
                <CircularProgress />
            </Grid>
        } else
            return <Welcome />
    }
    const getActions = () => {

        if (memberSuccessDone || partnerSuccessDone || apiInProgress || errMsg!=='' || alreadyOnboarded)  return; // Do not show action button
        if (memberAPIinProgress) return <Grid container justifyContent="center" alignItems="center"><CircularProgress/></Grid>

        return <Grid container justifyContent="space-between">
            <Grid item>
                <Button variant="contained" sx={{ backgroundColor: 'primary.200' }} onClick={() => continueMember()}>Continue as Member</Button>
            </Grid>
            <Grid item>
                <Button variant="contained" onClick={() => bePartner()}>Be Partner</Button>
            </Grid>
        </Grid>
    }

    return (
        <>
            {getComponent()}
            {getActions()}
        </>


        // <Grid container justifyContent="center" alignItems="center">
        //     {
        //         initiatePartnership?<MlmLandingPage/>:<></>
        //     }
        //     {
        //         memberSuccessDone?<>
        //             <MemberRegistrationDone/>
        //         </>
        //         :<></>
        //     }
        //     {
        //         !initiatePartnership && partnerSuccessDone?<>
        //             <InitiatePartnership/>
        //         </>
        //         :<></>
        //     }

        //     {!memberSuccessDone && !partnerSuccessDone && inviteeObj.id !== undefined ?
        //         <>
        //             {apiInProgress ? 
        //                 <Grid container alignItems="center" justifyContent="center" sx={{height:'55vh'}}>
        //                     <CircularProgress />
        //                 </Grid> 
        //             : 
        //             <>
        //                 <Welcome/>
        //                 <Grid container justifyContent="space-between">
        //                     {memberAPIinProgress?
        //                         <Grid container alignItems="center" justifyContent="center"><CircularProgress />
        //                         </Grid> 
        //                     :<>
        //                         <Grid item>
        //                             <Button variant="contained" sx={{ backgroundColor: 'primary.200' }} onClick={() => continueMember()}>Continue as Member</Button>
        //                         </Grid>
        //                         <Grid item>
        //                             <Button variant="contained" onClick={() => bePartner()}>Be Partner</Button>
        //                         </Grid>
        //                     </>}
        //                 </Grid>
        //             </>
        //             }
        //         </>
        //         : 
        //         <>
        //             {errMsg!==''?
        //                 <Grid container justifyContent="center" alignItems="center" sx={{height:'68vh', backgroundColor:'white', borderRadius:2}}>
        //                     <ErrorMessage message="Invlaid Link"/>
        //                 </Grid>
        //             :<></>
        //             }
        //         </>
        //     }
        // </Grid>
    )
}