import {useState, useEffect} from 'react';
import { Grid, Button, Typography } from '@mui/material';
import MlmuserEditFormImpl from './MlmuserEditFormImpl';

export default function EdituserActionImpl(){

    return (
        <>
        <MlmuserEditFormImpl/>
        </>
    )
}