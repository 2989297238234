import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";

import Alert from '@mui/material/Alert';
import { Button, Divider,TextField, Grid, Typography, Box,CircularProgress } from "@mui/material"

import {getProductStruct, setData} from './ProductStruct'
import { updateTextValue, handleSubmit,updateValue,updateSelectedValue} from '../../core/js/FormUtils'
import {createAPI, updateAPI, getAPI, updateEditableAPI} from './ProductAPI';
import {CoreContext} from "../../core/context/CoreContext";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {validMobileNumberEntry, validNameEntry, validEmailEntry} from '../../core/js/FormValidation';

import {getPathVariable} from '../../core/js/UrlUtils';
import { useSearchParams } from "react-router-dom";
import RadioForBoolean from '../../core/form/RadioForBoolean';
import MyDate from '../../core/components/MyDate';
import { useDispatch,useSelector } from 'react-redux';
import { updateProductObj,addProductObj, setShowEditForProduct} from 'store/slice/DmpSlice';
import { setApiInProgress } from 'store/slice/HeaderSlice';
import { setSelectedImage } from 'store/slice/ImageGallerySlice';


export default function ProductEditFormImpl(){
    const dispatch = useDispatch();
    const { setNotification, lastURL } = React.useContext(CoreContext);
    const [formValues, setFormValues] = useState(getProductStruct);
    const [showError, setShowError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('Something went wrong');
    const [dataLoaded, setDataLoaded] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    
    const {productDashboard} = useSelector((state)=>state.dmpslice);
    const [productId, setProductId] = useState(productDashboard.selectedId);
    const { businessid } = useSelector((state) => state.headerslice.userprofileInfo);
    const { selectedImagePath, imageType } = useSelector((state) => state.imagegalleryslice);
    const {productObj} = useSelector((state)=>state.dmpslice.productDashboard)

    
    const {apiInProgress} = useSelector((state)=>state.headerslice)
    useEffect(()=>{
    },[apiInProgress])

    useEffect(()=>{
        if (productObj && productObj.id!==undefined){
            setFormValues({
                ['id']:{
                  ...formValues['id'],
                  value:productObj.id,
                },
                 ['businessid']:{
                    ...formValues['businessid'],
                    value:productObj.businessid,
                  },['title']:{
                    ...formValues['title'],
                    value:productObj.title,
                  },['description']:{
                    ...formValues['description'],
                    value:productObj.description,
                  },['imagepath']:{
                    ...formValues['imagepath'],
                    value:productObj.imagepath,
                  },['price']:{
                    ...formValues['price'],
                    value:productObj.price,
                  },
                })

                setDataLoaded(true);
                // dispatch(setSelectedImage(
                //     {"name":productObj.title, "path":productObj.imagepath}
                // ));
        }
        setDataLoaded(true);
    },[])

    useEffect(() => {
        dataLoaded && setFormValues({
            ...formValues,
            ['imagepath']: {
                ...formValues['imagepath'],
                value: selectedImagePath
            }
        })

    }, [selectedImagePath])
    
    const loadForm = (data)=>{
        
        //setData(data, formValues, setFormValues);

        setFormValues({
            ['id']:{
              ...formValues['id'],
              value:data.id,
            },
             ['businessid']:{
                ...formValues['businessid'],
                value:data.businessid,
              },['title']:{
                ...formValues['title'],
                value:data.title,
              },['description']:{
                ...formValues['description'],
                value:data.description,
              },['imagepath']:{
                ...formValues['imagepath'],
                value:data.imagepath,
              },['price']:{
                ...formValues['price'],
                value:data.price,
              },
            })

    }
    const handleRadioChange = (name, value) => {
        setShowError(false);
        console.log(value+" == "+getRadioAPIvalue(value))
        updateValue(formValues, setFormValues, name, getRadioAPIvalue(value));
    }
    const getRadioValue = (value) => {
        if (value ) 
            return "yes"
        else 
            return "no"
    }
    const getRadioAPIvalue = (value) => {
        if (value ==="yes") 
            return true
        else 
            return false
    }
    const handleTextChange = (e) => {
        setShowError(false);
        updateTextValue(formValues, setFormValues,e);
    }
    const handleDoubleChange = (e) => {
        setShowError(false);
        const parsed = parseFloat(e.target.value);
        if (!isNaN(parsed) && e.target.value == parsed)
         updateTextValue(formValues, setFormValues,e);
    }
    const handleNameChange = (e) =>{
        setShowError(false);
        
       if (validNameEntry(e))
        updateTextValue(formValues, setFormValues,e);
    }
    const handleMobileChange = (e) => {
        setShowError(false);
        
       if (validMobileNumberEntry(e))
            updateTextValue(formValues, setFormValues,e);
    }
    const handleEmailidChange = (e) => {
        setShowError(false);
        
       if (validEmailEntry(e))
            updateTextValue(formValues, setFormValues,e);
    }

    const createSuccessCallback = (obj) =>{
         dispatch(setApiInProgress(false));
        dispatch(addProductObj(obj));
        setNotification('Record has been cretaed successfully !!!');
        dispatch(setShowEditForProduct(false));
    }
    const updateSuccessCallback = (obj) =>{
         dispatch(setApiInProgress(false));
        dispatch(updateProductObj(obj));
        setNotification('Record with id['+productId+'] has been updated successfully !!!');
        dispatch(setShowEditForProduct(false));
        
    }
    const failuerCallback = (errorMsg) => {
         dispatch(setApiInProgress(false));
        setErrorMsg(errorMsg);
        setShowError(true);
    }
    const callCreateAPI = (formValues, successCallBack, failuerCallback) =>{
        dispatch(setApiInProgress(true));
        createAPI(formValues, successCallBack, failuerCallback);
    }
    const callUpdateAPI = (id,formValues, updateSuccessCallback, failuerCallback) =>{
        dispatch(setApiInProgress(true));
        updateAPI(id,formValues, updateSuccessCallback, failuerCallback);
    }
    const onSubmit=(e)=>{
        handleSubmit(e,productId, formValues, setFormValues, createSuccessCallback, updateSuccessCallback, failuerCallback, callCreateAPI, callUpdateAPI);
    }
    const setDate = (fieldName, value) => {
        var date = new Date(value);
        
        const day = date.getDate();
        const dayStr = day < 10 ? '0' + day : day.toString();

        const month = date.getMonth() + 1;
        const monthStr = month < 10 ? '0' + month : month.toString();
        var dateStr =date.getFullYear()+ "-" +  monthStr + "-" + dayStr + "T00:00:00.000Z";
        updateValue(formValues, setFormValues, fieldName, dateStr);
    }
    return (
        <form  noValidate onSubmit={onSubmit} >
            <Grid container direction="column" padding={.25}>
                {showError? <Alert severity="error">{errorMsg}</Alert>: ''}
                <Box sx={{ flexGrow: 1 }}>
                    <Grid item sx={{overflowY: "scroll", backgroundColor:'white',maxHeight:'62vh', padding:1}}>
                        <Grid container direction="column" padding={.25}>
                            <Grid item>
                                <Typography variant='h4'>{productId===-1?'Add':'Edit'} Product </Typography>
                                <Divider/>
                            </Grid>
                            <Grid height='25px' ></Grid>
                                <Grid container display="flex" spacing={2} direction="row" justifyContent="space-between" alignItems="center"> 
                                                            
                            <Grid item lg={12} xs={12}>
                                <TextField size="Normal"
                                    placeholder={formValues.title.placeholder}
                                    required={formValues.title.required}
                                    label={formValues.title.label}
                                    name={formValues.title.name}
                                    value={formValues.title.value}
                                    onChange={handleTextChange}
                                    error={formValues.title.error}
                                    helperText={formValues.title.error && formValues.title.errorMessage}
                                    sx={{width:'100%'}}
                                />
                            </Grid>
                                            
                            <Grid item lg={12} xs={12}>
                                <TextField size="Normal"
                                    placeholder={formValues.description.placeholder}
                                    required={formValues.description.required}
                                    label={formValues.description.label}
                                    name={formValues.description.name}
                                    multiline
                                    rows="4"
                                    value={formValues.description.value}
                                    onChange={handleTextChange}
                                    error={formValues.description.error}
                                    helperText={formValues.description.error && formValues.description.errorMessage}
                                    sx={{width:'100%'}}
                                />
                            </Grid>
                            <Grid item lg={12} xs={12}>
                                <TextField size="Normal"
                                    placeholder={formValues.price.placeholder}
                                    required={formValues.price.required}
                                    label={formValues.price.label}
                                    name={formValues.price.name}
                                    value={formValues.price.value}
                                    onChange={handleDoubleChange}
                                    error={formValues.price.error}
                                    helperText={formValues.price.error && formValues.price.errorMessage}
                                    sx={{width:'100%'}}
                                />
                            </Grid>
                                            
                            <Grid item lg={12} xs={12}>
                                <TextField size="Normal"
                                    placeholder={formValues.imagepath.placeholder}
                                    required={formValues.imagepath.required}
                                    label={formValues.imagepath.label}
                                    name={formValues.imagepath.name}
                                    value={formValues.imagepath.value}
                                    onChange={handleTextChange}
                                    error={formValues.imagepath.error}
                                    helperText={formValues.imagepath.error && formValues.imagepath.errorMessage}
                                    sx={{width:'100%'}}
                                />
                            </Grid>
                                            
                            
                        
                                    <Grid item lg={4} xs={12} sx={{display:'none'}}>
                                        <TextField size="Normal"
                                            placeholder={formValues.businessid.placeholder}
                                            required={formValues.businessid.required}
                                            label={formValues.businessid.label}
                                            name={formValues.businessid.name}
                                            value={formValues.businessid.value}
                                            onChange={handleTextChange}
                                            error={formValues.businessid.error}
                                            helpertext={formValues.businessid.error && formValues.businessid.errorMessage}
                                            sx={{ width: '100%',  }}
                                        />
                                    </Grid> 
                                </Grid>
                            
                        </Grid>
                    </Grid>
                </Box>
                <Grid height='25px' ></Grid>
                <Grid padding={1}>
                    <Divider/>
                </Grid>
                <Grid container direction="row" justifyContent="flex-end"  >
                {   apiInProgress?<CircularProgress/>:
                    <>
                        <Button variant="outlined" type="submit" >{productId===-1?'Add':'Save'}</Button>&nbsp;&nbsp;&nbsp;
                        <Button variant="outlined" onClick={()=>{dispatch(setShowEditForProduct(false));}}>Close</Button>
                    </>
                }
                </Grid>
            </Grid>
        </form>
    );
};