import React, { useState, useEffect } from 'react'

import { Grid, Typography, Box } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import MlmcreditListTableHeader from './MlmcreditListTableHeader';
import MlmcreditListTableRow from './MlmcreditListTableRow';

export default function MlmcreditListTable({ creditList }) {

  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const {userprofileInfo} = useSelector((state)=>state.headerslice)
  const {mlmcreditList} = useSelector((state)=>state.dmpslice.mlmcreditDashboard)
  
  useEffect(() => {
  }, [creditList])

  return (
    <>
      <Grid container direction="column" sx={{ justifyContent: "flex-start", alignItems: "flex-start", }}>
        <Grid item sx={{ width: '100%', paddingBottom: .2 }}>
          {/* <HEADER - CONTAINER/> */}
          < MlmcreditListTableHeader/>
        </Grid>
        {/* <ROWS/> */}
        <Grid item sx={{ width: '100%' }}>
          <Box lg={12} sx={{ flexGrow: 1 }}>
            <Grid item sx={{ overflowY: "scroll", backgroundColor: 'white', maxHeight: '32vh' }}>
              {/* <ROW CONTAINER/> */}
              <Grid container direction="column" sx={{ justifyContent: "flex-start", alignItems: "flex-start", width: '100%' }}>

              {creditList.map(obj => {
                    return (
                      < MlmcreditListTableRow obj={obj}/>
                    ) 
                })}
              {
                  creditList.length === 0 ?
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ width: '100%', backgroundColor: '#fafafa' }}
                      padding={1}
                    >
                      <Typography sx={{ fontWeight: 'light', fontSize: 14 }}><i>Records(s) are not added, yet.</i></Typography>
                    </Grid>
                    : <></>
                }
               </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );


}