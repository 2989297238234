import {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {Grid, Box,Typography,Divider,Avatar} from '@mui/material';
import FieldView from 'core/components/layout/FieldView';
import MlmuserInfo from './MlmuserInfo';
import PersonIcon from '@mui/icons-material/Person';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';


export default function MlmuserListTableRow({obj, getActionComponent,handleRowClick}){
  useEffect(()=>{
  },[])
    return (
      <>
        <Grid item lg={12} sx={{ width: '100%', }}>
          <Grid container direction="row" onClick={() => handleRowClick(obj.id)}
            sx={{cursor: 'pointer',
              justifyContent: "flex-start", alignItems: "center",  padding:1,  backgroundColor: 'white',
              
            }}>
              {/* <Grid item lg={1} xs={1} ><CheckCircleOutlineIcon sx={{color:obj.loginstatus===0?'secondary.400':'green'}}/></Grid> */}
               <Grid item lg={1} xs={1} sx={{}}>{obj.usertype==="MEMBER"?
                <Avatar sx={{ bgcolor: obj.loginstatus===0?'secondary.400':'green', width: 28, height: 28, fontSize: 15 }}>M</Avatar>:
                <Avatar sx={{ bgcolor: obj.loginstatus===0?'secondary.400':'green', width: 28, height: 28, fontSize: 15 }}>P</Avatar>}
              </Grid>
              <Grid item lg={1} xs={4} sx={{paddingLeft:1}}><Typography color="" sx={{ fontWeight: 'light', fontSize: 15 }}>{obj.title}</Typography></Grid>
              <Grid item lg={1} xs={4} ><Typography color="" sx={{ fontWeight: 'light', fontSize: 15 }}>{obj.mobilenumber}</Typography></Grid>
              {/* <Grid item lg={1} xs={2} ><Typography color="" sx={{ fontWeight: 'light', fontSize: 15 }}>{obj.usertype==="MEMBER"?'MEM':'PAR'}</Typography></Grid> */}

              <Grid item lg={2} xs={3} >
                <Grid container direction="row" sx={{ justifyContent: "flex-end", width: '100%', }}>
                  {getActionComponent(obj)}
                </Grid>
              </Grid>
            
          </Grid>
        </Grid>
        <Grid item lg={12} sx={{ width: '100%' }}><Divider/></Grid>
        {/* {obj.expand ?
        <Grid container direction="row">
          <Grid item sx={{ width: '100%' }}>
            < MlmuserInfo  mlmuserObj={obj} />
          </Grid>
          <Grid item sx={{ width: '100%' }}>
            <Divider />
          </Grid>
        </Grid>
        : <Grid container direction="row">
          <Grid item sx={{ width: '100%' }}>
            <Divider />
          </Grid>
        </Grid>} */}
      </>
      
    )
}