import React, { useState, useEffect } from 'react'

import Grid from '@mui/material/Grid';

import ProductFilterCriteria from './ProductFilterCriteria'
import ProductListImpl from './ProductListImpl'
import MyPageContainer from '../../core/template/MyPageContainer';
import {getListAPI} from './ProductAPI';
import {transformToCardFormat} from '../../core/js/FormUtils'
import {getPathVariable} from '../../core/js/UrlUtils';

import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {setProductDashboardProductList } from '../../store/slice/DmpSlice';

export default function ProductListView({setShowEdit,setProductId}){
    const {filterCriteria} = useSelector((state)=>state.dmpslice.productDashboard);   

    const [filteredProductList, setFilteredProductList] = React.useState([]);
    const [cardList, setCardList] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const { showfilter } = useSelector((state) => state.confslice);
    const { productDashboard } = useSelector((state) => state.dmpslice);
    
    function dataFetchCallBack(){
    }

    useEffect(()=>{
      if (productDashboard.productList.length>-1){
        setFilteredProductList(productDashboard.productList);
        transformToCardFormat(productDashboard.productList, setCardList);
      }
    },[productDashboard.productList])

    useEffect(()=>{
          transformToCardFormat(filteredProductList, setCardList);
      },[filteredProductList])

    useEffect(()=>{
        if (filterCriteria.title==='' && filterCriteria.description==='' && filterCriteria.imagepath==='' && filterCriteria.price==='' ){
            setFilteredProductList(productDashboard.productList);
            transformToCardFormat(productDashboard.productList, setCardList);

        } else {
            setFilteredProductList(
                productDashboard.productList
                    .filter(product=> product.title.toString().toUpperCase().includes(filterCriteria.title.toString().toUpperCase()))

                
            )
            

            //transformToCardFormat(productDashboard.filteredProductList, setCardList);
        }
      },[filterCriteria])
  
     return(
        <Grid container direction="column" >
            <Grid item lg={12}>
                <ProductListImpl
                    setShowEdit={setShowEdit}
                    setProductId = {setProductId}
                    tableList={filteredProductList}
                    cardList={cardList}
                    />
          </Grid>
      </Grid>
    )
}