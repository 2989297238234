import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Button, Typography, TextField } from '@mui/material';
import { sendCustomerOTPAPI,validateCustomerOtpAPI} from 'pages/authentication/components/AuthAPI';
import {setLoggedIn, setAppuserInfo, setuserprofileInfo} from 'store/slice/HeaderSlice';
import { setMlmUserObj, setSelectedTab } from 'store/slice/EcomSlice';
import { updateCartCustomerAPI } from '../ecom/EcomAPI';
import { setCartObj } from 'store/slice/EcomSlice';
import Cookies from 'js-cookie';
import { validMobileNumberEntry } from 'core/js/FormValidation';
import { getMLMuserAPI, inviteeLoginSuccessAPI } from 'api/CoreMlmmgmtWebServices';

export default function MobileLogin() {
    const dispatch = useDispatch();
    const {inviteeMobileNumber} = useSelector((state)=>state.ecomslice.mlm);
    const [mobileNumber, setMobileNumber] = useState(inviteeMobileNumber);
    const [otpReqId, setOtpReqId] = useState('');
    const [otp, setOtp] = useState('');
    const {userprofileInfo} = useSelector((state)=>state.headerslice);
    
    useEffect(()=>{
        if (Cookies.get('anonymousId')!==undefined && userprofileInfo.rolename!=="MLM_CUSTOMER")
            updateCartCustomerAPI({
                    "anonymousId":Cookies.get('anonymousId')
                },updateCartCustomerSuccess, updateCartCustomerFailure
            )
    },[userprofileInfo])

    const updateCartCustomerSuccess = (apiResponse) =>{
        dispatch(setCartObj(apiResponse.cartInfo));
    }
    const updateCartCustomerFailure= (errMsg) =>{
        console.log(' !!! Error !!! '+errMsg);
    }

    useEffect(()=>{
        if (otp!=='')
            validateOTP();
    },[otp])
    const setNameValue = (e) => {
        const { name, value } = e.target;
        setMobileNumber(value);
    }
    const loginCustomer = () =>{
        sendCustomerOTPAPI(mobileNumber+"@appmachine.in",getOTPsuccess, getOTPfailure)
    }
    const getOTPsuccess = (apiResponse)=>{
        setOtpReqId(apiResponse.otpResponse.id);
        setOtp(apiResponse.otpResponse.otp);
    }
    const getOTPfailure = (errMsg)=>{
        alert("!!! ERROR ==>"+errMsg);
    }
    const validateOTP = () =>{
          validateCustomerOtpAPI(otpReqId, otp,validateCustomerOTPsuccessCallBack, failuerCallback);
      }
      const validateCustomerOTPsuccessCallBack = (data) =>{
        //Cookies.set("JWT",data.accesstoken); 
        localStorage.setItem("JWT",data.accesstoken);
        dispatch(setLoggedIn(true));
        dispatch(setAppuserInfo(data.appuserInfo));
        dispatch(setuserprofileInfo(data.userprofileInfo));
        fetchMlmUserObj();
        dispatch(setSelectedTab("ADDRESS"))
    }
    const failuerCallback = (errMsg) =>{
        alert("!!! ERROR => "+errMsg)
    }
    const handleMobileChange = (e) => {
        const inputValue = e.target.value;
        // Allow only numbers
        if (/^\d*$/.test(inputValue) && inputValue.length<=10) {
            setMobileNumber(inputValue);
        }
    }
    const getMlmUserSuccess = (apiResponse) =>{
        dispatch(setMlmUserObj(apiResponse.mlmuserInfo));
    }
    const getMLMuserFailure = (errMsg) =>{
        console.log("!!! ERROR - Unable to fetch MLM User");
    }
    const fetchMlmUserObj = () =>{
        inviteeLoginSuccessAPI({},getMlmUserSuccess, getMLMuserFailure);
        getMLMuserAPI({},
            getMlmUserSuccess,failuerCallback
        )
    }
    return (
        <>
            <Grid container direction="row" sx={{ width: '100%', backgroundColor: 'white' }}>
                <Grid item lg={12} xs={12} sx={{ padding: 1}}>
                    <Typography variant="h4" color="primary.darker">Your Mobile Number</Typography>
                </Grid>
                <Grid item lg={12} xs={12} sx={{ width: '100%', backgroundColor: 'white', padding:1, borderRadius: 2 }} >
                    <Grid container direction="row" justifyContent="space-between" >
                        <Grid item lg={12} sx={{ width: '100%' }} >
                            <TextField size="large"
                                placeholder="Enter your Mobile Number"
                                required={true}
                                label="Enter your Mobile Number"
                                name="mobilenumber1"
                                value={mobileNumber}
                                onChange={handleMobileChange}
                                sx={{ width: '100%' }}
                                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justifyContent="flex-end" sx={{backgroundColor:'secondary.lighter', paddingTop:2}}>
                <Button variant="contained" disabled={mobileNumber.length!==10?true:false} onClick={()=>loginCustomer()}>Continue</Button>
            </Grid>
        </>
    )
}