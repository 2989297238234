import { useEffect, useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { AppBar, Box, ClickAwayListener, IconButton, Paper, Popper, Toolbar, Grid } from '@mui/material';
import { LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import SettingsIcon from '@mui/icons-material/Settings';

// project import
import Search from './Search';
import Profile from './Profile';
import Transitions from 'components/@extended/Transitions';


// assets
import { MoreOutlined } from '@ant-design/icons';
import { setShowProfileMenu } from 'store/slice/MenuSlice';
import { useDispatch, useSelector } from 'react-redux';
// ==============================|| HEADER CONTENT - MOBILE ||============================== //

const MobileSection = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  //const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const {showProfileMenu} = useSelector((state)=>state.menu)

  
  const handleToggle = () => {
    dispatch(setShowProfileMenu(!showProfileMenu));
    //setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    // if (anchorRef.current && anchorRef.current.contains(event.target)) {
    //   return;
    // }
    dispatch(setShowProfileMenu(false));
    //setOpen(false);
  };

  const prevOpen = useRef(showProfileMenu);
  // useEffect(() => {
  //   if (prevOpen.current === true && showProfileMenu === false) {
  //     anchorRef.current.focus();
  //   }

  //   prevOpen.current = showProfileMenu;
  // }, [showProfileMenu]);

  return (
    <>
      <Box sx={{ flexShrink: 0, ml: 0.75 }}>
        <IconButton
          component="span"
          disableRipple
          sx={{
            bgcolor: showProfileMenu ? 'grey.300' : 'grey.100'
          }}
          ref={anchorRef}
          aria-controls={showProfileMenu ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          color="inherit"
        >
          <MoreOutlined />
        </IconButton>
      </Box>
      <Popper
        placement="bottom-end"
        open={showProfileMenu}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{
          width: '100%'
        }}
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={showProfileMenu} {...TransitionProps}>
            <Paper sx={{ boxShadow: theme.customShadows.z1 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <AppBar color="inherit">
                  <Toolbar>
                    {/* <Search /> */}
                    <Profile />
                    {/* <Grid item justifyContent="flex-end">
                          <IconButton size="large" color="secondary" >
                            <LogoutOutlined />
                          </IconButton>
                    </Grid>
                    <Grid item justifyContent="flex-end">
                          <IconButton size="large" color="secondary" >
                            <SettingsIcon />
                          </IconButton>
                    </Grid> */}

                    
                  </Toolbar>
                </AppBar>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default MobileSection;
