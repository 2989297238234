import { createSlice } from '@reduxjs/toolkit';

export const RestoLiveSlice = createSlice({
    name: "restolive",
    initialState: {
        selectedTableId: ''
    },
    reducers: {
    
        setSelectedTableId: (state, action) =>{
            state.selectedTableId=action.payload;
        }
    }
});
export const { setSelectedTableId } = RestoLiveSlice.actions;
export default RestoLiveSlice.reducer;