import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Button, Grid, Typography, CircularProgress, Divider, TextField, Box } from "@mui/material";
import ImageGalleryHeader from './ImageGalaryHeader';
import ImageGalleryMainArea from './ImageGalaryMainArea';
import ImageGalleryUploadNewImage from './ImageGalaryUploadNewImage';
import { setShowImageGallery, setImageNameUnderSearch, setIntegratedImage } from 'store/slice/ImageGallerySlice';

const ImageGallery = ({ clickToAction }) => {
    const dispatch = useDispatch();
    const { integratedImage, imageType } = useSelector((state) => state.imagegalleryslice);
    const { selectedImagePath, selectedImage, linkAssetOption } = useSelector((state) => state.imagegalleryslice);
    const [linkResult, setLinkResult] = useState(false);
    const [apiInProgress, setApiInProgress] = useState(false);

    useEffect(() => {
        setLinkResult('')
    }, [selectedImage])
    const showUploadImage = () => {
        dispatch(setShowImageGallery(false));
    }
    const handleContinue = () => {
        dispatch(setIntegratedImage(selectedImage))
    }
    const linkAsset = () => {
        // let reqPayload = {
        //     "assetpath":selectedImagePath,
        //     "productid":productObj.id,
        //     "title":selectedImage,
        //     "imagetype":imageType
        // }
        // setApiInProgress(true);
        // linkAssetToProductAPI(reqPayload, assetLinkSuccessCallBack, assetLinkFailureCallBack)
    }
    const assetLinkSuccessCallBack = (obj) => {
        // dispatch(addProductassetObj(obj));
        setApiInProgress(false);
    }
    const assetLinkFailureCallBack = (obj) => {
        setLinkResult(obj)
        setApiInProgress(false);
    }
    return (
        <>
            <Grid container sx={{ width: '100%' }}>
                <ImageGalleryHeader />
                <ImageGalleryMainArea />

            </Grid>
            <Grid container paddingTop={2} direction="row" sx={{ alignItems: "center" }}>
                <Grid item lg={6} md={6} sx={{ paddingLeft: 2, borderRadius: 5, justifyContent: "flex-start", alignItems: "center", backgroundColor: 'primary.200' }}>
                    <Typography variant="h5">Selected Image </Typography><Typography color="textSecondary" sx={{ fontWeight: 'light', fontSize: 12 }}>'{selectedImage}'</Typography>
                </Grid>

                <Grid container lg={6} md={6} sx={{ justifyContent: "flex-end", alignItems: "center" }}>
                    <Button variant="outlined" onClick={() => showUploadImage()}>Upload New Image</Button>
                    {/* <Button variant="outlined" disabled={selectedImage==='---'?false:true} onClick={()=>{showUploadImage()}}>Upload New</Button>&nbsp;&nbsp;&nbsp; */}
                    {/* <Button variant="outlined" disabled={selectedImage==='---'?true:false}  onClick={()=>{handleContinue()}}>Continue</Button> */}
                    {linkResult === '' ?
                        <>
                            {apiInProgress ? <CircularProgress /> :
                                <>
                                    {clickToAction ?
                                        <>&nbsp;&nbsp;<Button variant="outlined" disabled={selectedImage === '---' || !linkAssetOption ? true : false} onClick={() => clickToAction()}>Attach To Product</Button></> : <></>}
                                </>
                            }
                        </>
                        : <><Typography color="error" gutterBottom variant="h6">{linkResult}</Typography></>}
                </Grid>
            </Grid>


        </>
    )
}
export default ImageGallery;