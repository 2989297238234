import React, { useState, useEffect } from 'react'

import { Grid, Typography, Box } from '@mui/material';

import { CoreContext } from '../../../core/context/CoreContext';
import PageWaiting from '../../../core/components/PageWaiting';
import IconEnabled from '../../../core/components/layout/IconEnabled';
import IconDisabled from 'core/components/layout/IconDisabled';
import { useDispatch, useSelector } from 'react-redux';
import CustomerListTableHeader from './CustomerListTableHeader';
import CustomerListTableRow from './CustomerListTableRow';

export default function CustomerListTable({ payload }) {

  const { showloading } = React.useContext(CoreContext);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const {userprofileInfo} = useSelector((state)=>state.headerslice)
  
  useEffect(() => {
    setRows(payload.tableList);
  }, [payload])

  const handleRowClick = (rowId) => {
    setRows(prevData =>
      prevData.map(item =>
        item.id === rowId ? { ...item, 'expand': !item.expand } : item
      )
    );
  }

  const getActionComponent = (rowObj) => {
    return <>
      { payload.actionList.map(actionObj=>{
         /*if (
            (
              actionObj.status===undefined || (
                  actionObj.status!==undefined && 
                  (actionObj.status==='' || (actionObj.status!=='' && actionObj.status.toLowerCase().includes(rowObj.orderstatus.toLowerCase())))
              )
            )
          && 
          (actionObj.role===undefined || actionObj.role!=undefined && (actionObj.role==='' || actionObj.role.includes(userprofileInfo.rolename)))
        ) */
        return <IconEnabled actionObj={actionObj}  id={rowObj.id}/>})}
    </>
  }

  return (
    <>
      <Grid container direction="column" sx={{ justifyContent: "flex-start", alignItems: "flex-start", }}>
        {/* <HEADER/> */}
        <Grid item sx={{ width: '100%', paddingBottom: .2 }}>
          {/* <HEADER - CONTAINER/> */}
          < CustomerListTableHeader/>
        </Grid>
        {/* <ROWS/> */}
        <Grid item sx={{ width: '100%' }}>
          <Box lg={12} sx={{ flexGrow: 1 }}>
            <Grid item sx={{ overflowY: "scroll", backgroundColor: 'white', maxHeight: '65vh' }}>
              {/* <ROW CONTAINER/> */}
              <Grid container direction="column" sx={{ justifyContent: "flex-start", alignItems: "flex-start", width: '100%' }}>

              {rows.map(obj => {
                    return (
                      < CustomerListTableRow obj={obj} getActionComponent={getActionComponent} handleRowClick={handleRowClick}/>
                    ) 
                })}
              {
                  rows.length === 0 ?
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ width: '100%', backgroundColor: '#fafafa' }}
                      padding={1}
                    >
                      <Typography sx={{ fontWeight: 'light', fontSize: 14 }}><i>Records(s) are not added, yet.</i></Typography>
                    </Grid>
                    : <></>
                }
               </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );


}