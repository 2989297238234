import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import MlmcreditPageImpl from './MlmcreditPageImpl';
import { openDrawer } from './../../../store/slice/MenuSlice';

export default function MlmcreditPage() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(openDrawer(false));
    },[]);

    return (
        < MlmcreditPageImpl />
    )

}