import React, { useState, useEffect } from 'react'
import { useSearchParams } from "react-router-dom";

import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import { Grid, Typography, Button } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import DeleteNotification from '../../core/components/DeleteNotification';
import { CoreContext } from "../../core/context/CoreContext";
import PageWaiting from '../../core/components/PageWaiting';
import { isMobile } from 'react-device-detect';

import { getListAPI, deleteAPI } from './ProductAPI';
import { getColumns } from './ProductStruct';
import ListCardView from '../../core/components/card/ListCardView';
import ListTableView from '../../core/components/card/ListTableView';

import { getProductView } from './ProductView';
import CardListLayout from '../../core/components/card/layout/CardListLayout';
import { useSelector, useDispatch } from 'react-redux';
import { setViewParams } from '../../store/slice/ChoiceSelectionSlice';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';

import { setFilter } from '../../store/slice/ConfSlice';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import MyPageContainer from '../../core/template/MyPageContainer';
import { deleteProductObj, setSelectedProductId, setProductEditProductModal, setProductManageProductModal, setSelectedActionId, setShowEditForProduct } from 'store/slice/DmpSlice';

import ProductListTable from './components/ProductListTable';
import { useNavigate } from "react-router-dom";
import { setClickedBo, setActionClicked } from 'store/slice/BreadCrumSlice';
import ProductFilterCriteria from './ProductFilterCriteria';


import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import AutoModeIcon from '@mui/icons-material/AutoMode';

import EditProductActionModal from './action/editProduct/EditProductActionModal';
import ManageProductActionModal from './action/manageProduct/ManageProductActionModal';

import { transformDateValue } from 'core/js/FormUtils';

export default function ProductListImpl({ tableList, cardList, setShowEdit, setProductId }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setNotification, setShowLoading, showloading, lastURL } = React.useContext(CoreContext);
  const columns = getColumns(editCallBack, initDeleteCallBack);
  const [view, setView] = useState(1);
  const { showfilter } = useSelector((state) => state.confslice)

  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const { selectedId, productObj, productList } = useSelector((state) => state.dmpslice.productDashboard);
  const { rolename } = useSelector((state) => state.headerslice.userprofileInfo);

  useEffect(() => {
  }, [productList, productObj])

  function initDeleteCallBack(id) {
    dispatch(setSelectedProductId(id));
    setShowDeleteAlert(true);
  }
  function deleteCallBack(obj) {
    dispatch(deleteProductObj(obj.id));
    setNotification('Record deleted successfully !!!')
  }
  function deleteRecord() {
    deleteAPI(selectedId, deleteCallBack);
    setShowDeleteAlert(false);
  }

  function editCallBack(id) {
    dispatch(setSelectedProductId(id));
    dispatch(setShowEditForProduct(true));
  }

  const createProduct = () => {
    dispatch(setSelectedProductId(-1));
    //dispatch(setShowEditForProduct(true));
    dispatch(setProductEditProductModal(true));
  }


  const editProductCallBack = (id) => {
    dispatch(setSelectedProductId(id));
    dispatch(setProductEditProductModal(true));
  }
  const manageProductCallBack = (id) => {
    dispatch(setSelectedProductId(id));
    dispatch(setProductManageProductModal(true));
  }

  const getDataWithDateTransformation = (data) => {
    let newData = {
      ...data,
      'expand': true,

    }
    return newData;
  }
  const productTransformation = (tableList) => {
    let newDataList = []
    tableList.map((data) => {
      newDataList.push(getDataWithDateTransformation(data));
    })
    return newDataList;
  }

  const payload = {
    productList: productList,
    cardList: getProductView(tableList),
    actionList: [
      /*{title:'Edit', callBackMethod:editCallBack, icon:<EditIcon/>, status:'NA'},
      {title:'Delete', callBackMethod:initDeleteCallBack, icon:<DeleteIcon/>, status:'NA'},
      
    {id:56, title:'EditProduct', callBackMethod:editProductCallBack, icon:<AddToQueueIcon/>, status:'Draft', role:''},
    {id:57, title:'ManageProduct', callBackMethod:manageProductCallBack, icon:<AutoModeIcon/>, status:'Draft', role:''},
    */
    ],
    dialogueActionList: [

    ],
    tableView: {
      tableList: productTransformation(tableList),
      productList: productList,
      actionList: [
        // {title:'Edit', callBackMethod:editCallBack, icon:<EditIcon/>, status:'NA'},
        // {title:'Delete', callBackMethod:initDeleteCallBack, icon:<DeleteIcon/>, status:'NA'},

        { id: 56, title: 'EditProduct', callBackMethod: editProductCallBack, icon: <EditIcon />, status: 'Draft', role: 'DMP_PRODUCT_MGR' },
        // {id:57, title:'ManageProduct', callBackMethod:manageProductCallBack, icon:<AutoModeIcon/>, status:'Draft', role:''},
      ]
    }
  }

  const getPayload = () => {
    return payload;
  }

  useEffect(() => {
    if (document.body.offsetWidth < 500) setView(0);
    dispatch(setViewParams({
      colsCount: { xs: 12, sm: 4, md: 4, lg: 4 },
      modal: {

      }
    }))
  }, [])

  return (
    <>
    {rolename === 'DMP_PRODUCT_MGR' ?
      <Grid container justifyContent="flex-end" sx={{ padding: 1 }}>
        <Tooltip title="Add Product">
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            sx={{
              backgroundColor: 'primary.dark', // Set background color to primary.dark
              '&:hover': {
                backgroundColor: 'primary.main', // Set hover color to primary.main
              },
              color: '#fff', // Ensure text color is white
            }}
            onClick={() => { createProduct() }}
          >
            Add Product
          </Button>
        </Tooltip>
      </Grid>
      : <></>
    }
        <Grid container direction="column">
          <Grid item>
            < ProductListTable payload={getPayload().tableView} />
          </Grid>
        </Grid>
      {showDeleteAlert ? <DeleteNotification
        id={selectedId}
        width="50%"
        setShowDeleteAlert={setShowDeleteAlert}
        deleteRecord={deleteRecord} /> : ''
      }


      <EditProductActionModal />
      <ManageProductActionModal />
    </>
  );


}