
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';

// icons
const icons = {
  ArrowForwardIosIcon,
  QrCode2Icon,
  BusinessCenterIcon
};

// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const core = {
  id: 'core',
  title: 'Digital Makrting Platform',
  type: 'group',
  role:'DMP_PRODUCT_MGR',
  children: [
      {
      id: 'product',
      title: 'Product',
      type: 'item',
      url: '/productpage',
      icon: icons.QrCode2Icon
  }, 
  {
    id: 'mlmorder',
    title: 'Order',
    type: 'item',
    url: '/mlmorderpage',
    icon: icons.BusinessCenterIcon
}
  
  /*
  {
      id: 'category',
      title: 'Category',
      type: 'item',
      url: '/category',
      icon: icons.ArrowForwardIosIcon
  },  {
      id: 'productcategory',
      title: 'Productcategory',
      type: 'item',
      url: '/productcategory',
      icon: icons.ArrowForwardIosIcon
  },  {
      id: 'customer',
      title: 'Customer',
      type: 'item',
      url: '/customer',
      icon: icons.ArrowForwardIosIcon
  },  {
      id: 'mlmuser',
      title: 'Mlmuser',
      type: 'item',
      url: '/mlmuser',
      icon: icons.ArrowForwardIosIcon
  },  {
      id: 'mlmusertype',
      title: 'Mlmusertype',
      type: 'item',
      url: '/mlmusertype',
      icon: icons.ArrowForwardIosIcon
  },  {
      id: 'mlmcredit',
      title: 'Mlmcredit',
      type: 'item',
      url: '/mlmcreditpage',
      icon: icons.ArrowForwardIosIcon
  },  
      */
  ]
};

export default core;