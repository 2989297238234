import {getAPIprefix} from './ApiUtils';
var ActionResourcePrefix = getAPIprefix()+"/engine-core-api/action";
var ActionsResourcePrefix = getAPIprefix()+"/engine-core-api/actions";


export function getAPI(id, loadForm){
   let response;
   try {
       const requestOptions = {method: 'GET'};
       fetch(ActionResourcePrefix+'/'+id)
           .then((data) => data.json())
           .then((data) => {
               loadForm(data);
               //setData({data},{formValues}, setFormValues);
           })
   } catch (error) {
       // setAlert(true);
       console.log('There was an error', error);
   }
   
}

export function getListAPI(setActionList, dataFetchCallBack){
  const token = localStorage.getItem("JWT");
  const requestOptions = {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`
  }
  };
  fetch(ActionsResourcePrefix, requestOptions)
      .then((data) => data.json())
      .then((data) => {setActionList(data.list);dataFetchCallBack(data.list)})
}

export function getActionInfoByBoidAPI(boid, setActionList, dataFetchCallBack){
   const requestOptions = {
     method: 'GET'
   };
   fetch(ActionsResourcePrefix+"/boid/"+boid+"/boid", requestOptions)
       .then((data) => data.json())
       .then((data) => {
         setActionList(data.list);
         dataFetchCallBack(data.list)
   })
}export function getActionInfoByContainerboidAPI(containerboid, setActionList, dataFetchCallBack){
   const requestOptions = {
     method: 'GET'
   };
   fetch(ActionsResourcePrefix+"/containerboid/"+containerboid+"/containerboid", requestOptions)
       .then((data) => data.json())
       .then((data) => {
         setActionList(data.list);
         dataFetchCallBack(data.list)
   })
}