import { transformDateValue } from 'core/js/FormUtils';
export const getCustomerView = (dataList) => {
  
  const transformDataValue = (value, type) =>{
    if (!type.includes('date')) return value;
    return transformDateValue(value);
  }

  let customerList = [];
  dataList.map((data)=>{
  let card = {
            'id': data.id,
            'headingList': [
                            {"fieldLabel":"Title","fieldValue":transformDataValue(data.title,"string"), "fieldType":"string"},
                            {"fieldLabel":"Email","fieldValue":transformDataValue(data.email,"emailid"), "fieldType":"emailid"},
                            {"fieldLabel":"Phone","fieldValue":transformDataValue(data.phone,"mobile"), "fieldType":"mobile"},
                            {"fieldLabel":"Invitee Id","fieldValue":transformDataValue(data.inviteeid,"ID"), "fieldType":"ID"},
                            {"fieldLabel":"Status","fieldValue":transformDataValue(data.status,"string"), "fieldType":"string"},
                            {"fieldLabel":"Customer Type","fieldValue":transformDataValue(data.customertype,"string"), "fieldType":"string"},
                            {"fieldLabel":"Member Count","fieldValue":transformDataValue(data.membercount,"number"), "fieldType":"number"},
              
              
            ],
            'dataList': [
                
              ]
          }

          customerList.push(card);
  })
  return customerList;
}