import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

export const HeaderSlice = createSlice({
    name: "headerslice",
    initialState: {
        appId:26,
        message:'',
        isLoggedIn:false,
        appuserInfo:{},
        userprofileInfo:{
            rolename:'OPS_ANONYMOUS'
        },
        refreshUserProfile:false,
        profile:{},
        exchange : [],
        apiInProgress: false,
        initialAPIload:true,
        
        login:{
            loginModalOpen:false
        },
        showProfileMgmtModal:false,
    },
    reducers: {
        setMessage: (state, action) =>{
            state.message=action.payload;
        },
        setLoggedIn: (state, action) =>{
            state.isLoggedIn=action.payload;
        },
        setAppuserInfo: (state, action) =>{
            state.appuserInfo=action.payload;
        },
        setAppId: (state, action) =>{
            state.appId= action.payload;
        },
        setProfile : (state, action) =>{
            state.profile = action.payload;
        },
        setuserprofileInfo: (state, action) =>{
            state.userprofileInfo = action.payload;
        },
        logout: (state, action)=>{
            state.appuserInfo={};
            state.userprofileInfo={};
            Cookies.remove("anonymousId");
            state.isLoggedIn=false;
            localStorage.removeItem("JWT");
        },
        addInfo : (state, action)=>{
            let info = {id:action.payload.id, value:action.payload.value};
            state.exchange.push(info);
        },
        deleteInfo : (state, action)=>{
            let index = state.exchange.findIndex(a => a.id === action.payload)
            if (index>-1){
                console.log('GOT info object...>>>>>>');
                state.exchange.splice(index , 1);
            }
               
        },
        getInfo : (state, action)=>{
            let index = state.exchange.map(obj=>obj.id).indexOf(action.payload.id);
            if (index>-1){
                return state.exchange[index]
            }
            return null;
        },
        setApiInProgress: (state, action)=>{
            state.apiInProgress=action.payload;
        },
        setInitialAPIload: (state, action)=>{
            state.initialAPIload=action.payload;
        },
        setProduct: (state, action)=>{
            state.cart.selectedProduct=action.payload;
        },
        setProductColor: (state, action)=>{
            state.cart.selectedProduct.colorid=action.payload;
        },
        openLoginModal:(state,action)=>{
            state.login.loginModalOpen=action.payload;
        },
        setShowProfileMgmtModal: (state,action)=>{
            state.showProfileMgmtModal = action.payload;
        },
        refreshUserProfile: (state, action)=>{
            state.refreshUserProfile=!state.refreshUserProfile;
        }
    }
});
export const {refreshUserProfile,setShowProfileMgmtModal, openLoginModal, refreshOrder, refreshCart, setAnonymousId, setCartId, setCartCount,setProduct,setProductColor,setMessage, setLoggedIn, setAppuserInfo, setAppId, setProfile, setuserprofileInfo,logout, addInfo, deleteInfo,getInfo, setApiInProgress,setInitialAPIload} = HeaderSlice.actions;
export default HeaderSlice.reducer;