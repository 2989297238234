import React, { useState, useEffect } from 'react'

import Grid from '@mui/material/Grid';

import MlmcreditFilterCriteria from './MlmcreditFilterCriteria'
import MlmcreditListImpl from './MlmcreditListImpl'
import MyPageContainer from '../../core/template/MyPageContainer';
import {getListAPI} from './MlmcreditAPI';
import {transformToCardFormat} from '../../core/js/FormUtils'
import {getPathVariable} from '../../core/js/UrlUtils';

import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {setMlmcreditDashboardMlmcreditList } from '../../store/slice/DmpSlice';

export default function MlmcreditListView({setShowEdit,setMlmcreditId}){
    const {filterCriteria} = useSelector((state)=>state.dmpslice.mlmcreditDashboard);   

    const [filteredMlmcreditList, setFilteredMlmcreditList] = React.useState([]);
    const [cardList, setCardList] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const { showfilter } = useSelector((state) => state.confslice);
    const { mlmcreditDashboard } = useSelector((state) => state.dmpslice);
    
    function dataFetchCallBack(){
    }

    useEffect(()=>{
      if (mlmcreditDashboard.mlmcreditList.length>-1){
        setFilteredMlmcreditList(mlmcreditDashboard.mlmcreditList);
        transformToCardFormat(mlmcreditDashboard.mlmcreditList, setCardList);
      }
    },[mlmcreditDashboard.mlmcreditList])

    useEffect(()=>{
          transformToCardFormat(filteredMlmcreditList, setCardList);
      },[filteredMlmcreditList])

    useEffect(()=>{
        if (filterCriteria.title==='' && filterCriteria.orderid==='' && filterCriteria.creditlevel==='' && filterCriteria.creditvalue==='' && filterCriteria.srpartnerid==='' && filterCriteria.srpartnername==='' && filterCriteria.mlmuserid==='' && filterCriteria.creditviaid==='' ){
            setFilteredMlmcreditList(mlmcreditDashboard.mlmcreditList);
            transformToCardFormat(mlmcreditDashboard.mlmcreditList, setCardList);

        } else {
            setFilteredMlmcreditList(
                mlmcreditDashboard.mlmcreditList
                    .filter(mlmcredit=> mlmcredit.title.toString().toUpperCase().includes(filterCriteria.title.toString().toUpperCase()))

                
            )
            

            //transformToCardFormat(mlmcreditDashboard.filteredMlmcreditList, setCardList);
        }
      },[filterCriteria])
  
     return(
        <Grid container direction="column" >
            <Grid item lg={12}>
                <MlmcreditListImpl
                    setShowEdit={setShowEdit}
                    setMlmcreditId = {setMlmcreditId}
                    tableList={filteredMlmcreditList}
                    cardList={cardList}
                    />
          </Grid>
      </Grid>
    )
}