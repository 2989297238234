import { Grid, Tooltip} from "@mui/material";

export function getMlmorderStruct(){
    return {
        id:{
            name:'id',
            label:'ID',
            value:'',
            error:false,
            errorMessage:'',
            required:false
        },
        
        businessid:{
            name:'businessid',
            label:'Business Id',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'ID'
        },
        title:{
            name:'title',
            label:'Title',
            value:'',
            error:false,
            errorMessage:'You must enter Title',
            required:true,
            fieldtype:'string'
        },
        orderid:{
            name:'orderid',
            label:'Order Id',
            value:'',
            error:false,
            errorMessage:'You must enter Order Id',
            required:true,
            fieldtype:'ID'
        },
        ordervalue:{
            name:'ordervalue',
            label:'Order Value',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'double'
        },
        partnerid:{
            name:'partnerid',
            label:'Partner Id',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'ID'
        },
        memberid:{
            name:'memberid',
            label:'Member Id',
            value:'',
            error:false,
            errorMessage:'You must enter Member Id',
            required:true,
            fieldtype:'ID'
        },
        orderdate:{
            name:'orderdate',
            label:'Order Date',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'date'
        },
        creditvalue:{
            name:'creditvalue',
            label:'Credit Value',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'double'
        },
      }
}

export function getColumns(payload){
  let actionList = [
        
        { field: 'title', headerName: 'Title', width:100 },
        { field: 'orderid', headerName: 'Order Id', width:150 },
        { field: 'ordervalue', headerName: 'Order Value', width:150 },
        { field: 'partnerid', headerName: 'Partner Id', width:150 },
        { field: 'memberid', headerName: 'Member Id', width:150 },
        { field: 'orderdate', headerName: 'Order Date', width:150 },
        { field: 'creditvalue', headerName: 'Credit Value', width:150 },
  ];
  if (payload!==undefined){
    payload.actionList && payload.actionList.map((actionObj)=>{
    actionList.push(
      {
        field: actionObj.title,
        headerName: actionObj.title,
        sortable: false,
        width:60,
        disableClickEventBubbling: false,
        renderCell: (params) => {
          return (
              <Grid item onClick={()=>{actionObj.callBackMethod(params.row.id)}}>
                <Tooltip title={actionObj.title} sx={{cursor: "pointer"}}>
                  {actionObj.icon}
                </Tooltip>
              </Grid>
          );
        }
      })
    })
  }
  return actionList;
}

export function setData(data, formValues, setFormValues){
   
    setFormValues({
      ['id']:{
        ...formValues['id'],
        value:data.id,
      },
       ['businessid']:{
          ...formValues['businessid'],
          value:data.businessid,
        },['title']:{
          ...formValues['title'],
          value:data.title,
        },['orderid']:{
          ...formValues['orderid'],
          value:data.orderid,
        },['ordervalue']:{
          ...formValues['ordervalue'],
          value:data.ordervalue,
        },['partnerid']:{
          ...formValues['partnerid'],
          value:data.partnerid,
        },['memberid']:{
          ...formValues['memberid'],
          value:data.memberid,
        },['orderdate']:{
          ...formValues['orderdate'],
          value:data.orderdate,
        },['creditvalue']:{
          ...formValues['creditvalue'],
          value:data.creditvalue,
        },
      })
}