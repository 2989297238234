import React, {useState, useEffect} from 'react';
import {Grid, Radio, RadioGroup, FormControlLabel, FormLabel, Typography,TextField,
  FormControl, InputLabel, Select,MenuItem, Button, Divider, CircularProgress} from '@mui/material';
import { useSelector, useDispatch, useStore } from 'react-redux';

import {sendOTPAPI, sendCustomerOTPAPI} from './AuthAPI';


export default function UserId({mode, setMode,setUserId, setOtpReqId}) {
  
  const [showUserId, setShowUserId] = useState(true);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('Please enter a valid Email Id');
  const [showResend, setShowResend] = useState(false);
  const [value, setValue] = useState('');
  const {appId} = useSelector((state)=>state.headerslice)
  const [apiInProgress, setApiInProgress] = useState(false);
  //const [otpSent, setOtpSent] = useState(false);

  const sendOTP =() => {
        if (value.length===0)   {
            setError(true);
        } else {

            if (validEmailEntry(value)){
                setApiInProgress(true);
                sendOTPAPI(value,appId,successCallBack, failuerCallback);
                // if (localStorage.getItem("JWT") === null)
                //     sendOTPAPI(value,appId,successCallBack, failuerCallback);
                // else
                //     sendCustomerOTPAPI(value,successCallBack, failuerCallback)
            } else {
                setError(true);
            }
        }
  }

  const successCallBack = (data) =>{
    setApiInProgress(false);
    setOtpReqId(data.otpResponse.id);
    setMode('otp');
  }
  const failuerCallback = (data) =>{
    setApiInProgress(false);
    console.log("failuerCallback=>"+JSON.stringify(data));
  }

  const validEmailEntry=(value)=>{
    
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (value.match(validRegex)) return true;
    
     return false;
}
  
  return (
        <Grid container direction="row" padding={.5} sx={{backgroundColor:'white'}}>
            <Grid item lg={12} xs={12} >
                <TextField      
                    required={true}
                    label="Email Id"
                    name="userId"
                    value={value}
                    onChange={(e)=>{setError(false);setValue(e.target.value);setUserId(e.target.value)}}
                    error={error}
                    helperText={error && errorMsg}
                    disabled={ mode==='userid'?false:true}
                    sx={{width:'100%'}}
                />  
            
            </Grid>

            {   mode==='userid'?

                <Grid container direction="row" justifyContent="left" >
                    
                    <Grid item lg={12} xs={12}  paddingTop={1}>
                        <i>
                        <Typography color="textSecondary" gutterBottom variant="h6" sx={{fontSize:'12px'}}>
                            OTP will be sent to this Email id
                        </Typography>
                        </i>
                    </Grid>
                    <Grid item lg={12} xs={12} align="right">
                    {apiInProgress ?
                        <CircularProgress /> :
                    <Button variant="contained" onClick={sendOTP}>Send OTP</Button>
                    }
                    </Grid>
                </Grid>

            :<>
                <Grid container direction="row" justifyContent="flex-start" sx={{backgroundColor:'white', paddingTop:.5}}>
                    <Grid item lg={12} xs={12}  >
                        <i>
                        <Typography color="textSecondary" gutterBottom variant="h6" sx={{fontSize:'12px'}}>
                            OTP is sent to {value}
                        </Typography>
                        </i>
                    </Grid>
                </Grid>
                <Grid container
                    direction="row"
                    
                    sx={{
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                       
                    }} >
                    <Grid item lg={12} xs={12} align="right">
                    {apiInProgress ?
                            <CircularProgress /> :
                            <>
                            <Button variant="contained" onClick={()=>{setMode('userid')}} >Edit </Button> &nbsp;&nbsp;
                            <Button variant="contained" onClick={()=>{sendOTP()}} >Resend </Button>
                            </>
                        }
                        
                    </Grid>
                    <Grid item lg={6} xs={12} >
                        
                    </Grid>
                </Grid>
                
            </>}

        </Grid>
  );

  
}