import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Button, Grid, Typography, CircularProgress, Divider, TextField, Box } from "@mui/material";
import ImageGalleryHeader from './ImageGalaryHeader';
import ImageGalleryMainArea from './ImageGalaryMainArea';
import ImageGalleryUploadNewImage from './ImageGalaryUploadNewImage';
import ImageGallery from 'core/components/image-gallery/ImageGalary';

const ImageGalleryHolder = ({clickToAction}) => {
    const {showImageGallery} = useSelector((state)=>state.imagegalleryslice);
    useEffect(()=>{
        
    },[showImageGallery])

    
    return (
        <>
        {showImageGallery?<ImageGallery clickToAction={clickToAction}/>:<ImageGalleryUploadNewImage/>}
        </>
    )
}
export default ImageGalleryHolder;