import { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';


export default function ErrorMessage({ message }) {
    useEffect(() => {

    }, [message])
    return (
        <>
            {message !== ''
                ?
                <Box display="flex" alignItems="center" sx={{ backgroundColor: 'white' }} padding={1}>
                    <ErrorOutlineIcon color="error" sx={{ marginRight: 1 }} />
                    <Typography color="error" variant="body1">
                    &nbsp;{message}
                    </Typography>
                </Box>
                : <></>
            }
        </>
    )
}