import React, { useState, useEffect } from 'react'

import {Grid} from '@mui/material'

import ComponentContainer from '../../core/template/ComponentContainer';
import {CoreContext} from "../../core/context/CoreContext";
import MlmcreditEditForm from './MlmcreditEditForm';
import MlmcreditList from './MlmcreditList';
import { useSelector, useDispatch } from 'react-redux';

import Notification from '../../core/components/Notification';

import MlmcreditListView from './MlmcreditListView'


export default function MlmcreditDashboard(){
  const { setKeyValue, getKeyValues, keyvalues } = React.useContext(CoreContext);
  const {showEdit} = useSelector((state)=>state.dmpslice.mlmcreditDashboard);
  const [ mlmcreditId, setMlmcreditId] = React.useState('');
  const [reloadMlmcreditList, setReloadMlmcreditList] = useState(true);

  return (
      <>
      <Notification/>
      {showEdit?
        <ComponentContainer containedComponent = {
          <MlmcreditEditForm 
            setReloadMlmcreditList={setReloadMlmcreditList}
            mlmcreditId={mlmcreditId}
            />}/>:
            <>
            <Grid container direction="column" >
              <Grid item>
                    {
                      <MlmcreditListView 
                      setMlmcreditId = {setMlmcreditId}/>
                    }
              </Grid>
            </Grid>
            </>
      }
    </>


  )

}
