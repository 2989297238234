import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { Grid, Box, Typography, Button, Tooltip, CircularProgress, Checkbox, FormControlLabel } from '@mui/material';
import { getMLMuserAPI } from 'api/CoreMlmmgmtWebServices';
import MlmBenefits from './MlmBenfits';
import MlmProcess from './MlmProcess';
import MlmTandC from './MlmTandC';

export default function MlmPartnershipProcess() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mlmUserObj, partnerAPISuccess } = useSelector((state) => state.ecomslice.mlm);
  const [apiInProgress, setApiInProgress] = useState(false);

  return (
    <>
    <Box lg={12} sx={{ flexGrow: 1 }}>
      <Grid item sx={{ overflowY: "scroll", backgroundColor: 'white', maxHeight: '55vh' }}>
        <Grid container direction="column" justifyContent="center" sx={{ width: '100%', }} alignItems="center">
          <Grid container>
            <Grid item>
              <MlmBenefits />
            </Grid>
            <Grid item sx={{ paddingTop: 1 }}>
              <MlmProcess />
            </Grid>
            
          </Grid>
        </Grid>
      </Grid>
    </Box>
    <Grid item sx={{ paddingTop: 1 }}>
    <MlmTandC />
  </Grid>
  </>
  )
}