import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Button, Typography, Box, Divider } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';


export default function Welcome() {
    const { inviteeMobileNumber,partnerObj,inviteeObj } = useSelector((state) => state.ecomslice.mlm);
    return (
        <Grid container alignItems="center" justifyContent="center" >
            <Grid item xs={12}>
                <Typography variant="h5">
                    Hello <Box component="span" sx={{ color: "primary.main", fontWeight: "bold", }}>{inviteeObj.title}</Box> @ {inviteeMobileNumber}
                </Typography>
                <Typography variant="h6" sx={{ paddingTop: 1 }}>
                    Our esteemed partner, <Box component="span" sx={{ color: "primary.main", fontWeight: "bold", }}>{partnerObj.firstname}</Box>, has personally invited you to join the <Box component="span" sx={{ color: "primary.main", fontWeight: "bold", }}>G&R Multi-Level Marketing (MLM)</Box> network—a platform designed to help individuals achieve financial success and business growth.
                </Typography>
            </Grid>
            <Grid xs={12} sx={{ padding: 2, }}><Divider sx={{ bgcolor: 'primary.dark', height: 1 }} /></Grid>
            <Grid container justifyContent="flex-start">
                <Typography variant="h5" >
                    Why become G&R MLM Partner?
                </Typography>
            </Grid>

            <Grid container alignItems="center" sx={{ paddingTop: 1 }}>
                <Grid item xs={1}>
                    <TaskAltIcon />
                </Grid>
                <Grid item xs={10} sx={{ paddingLeft: 1 }}>
                    <Typography variant="h6">
                        High-quality products with flat 20% discount, applicabble for Partner & Member, both.
                    </Typography>
                </Grid>
            </Grid>
            <Grid container alignItems="center" sx={{ paddingTop: 1 }}>
                <Grid item xs={1}>
                    <TaskAltIcon />
                </Grid>
                <Grid item xs={10} sx={{ paddingLeft: 1 }}>
                    <Typography variant="h6">
                        Earn upto 10% on every transaction under your network, applicabble for Partner only.
                    </Typography>
                </Grid>
            </Grid>
            <Grid container alignItems="center" sx={{ paddingTop: 1 }}>
                <Grid item xs={1}>
                    <TaskAltIcon />
                </Grid>
                <Grid item xs={10} sx={{ paddingLeft: 1 }}>
                    <Typography variant="h6">
                        Proven and profitable MLM business model.
                    </Typography>
                </Grid>
            </Grid>

            <Grid xs={12} sx={{ padding: 2, }}><Divider sx={{ bgcolor: 'primary.dark' }} /></Grid>
        </Grid>
    )
}