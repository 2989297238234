import { Grid, Tooltip} from "@mui/material";

export function getCustomerStruct(){
    return {
        id:{
            name:'id',
            label:'ID',
            value:'',
            error:false,
            errorMessage:'',
            required:false
        },
        
        businessid:{
            name:'businessid',
            label:'Business Id',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'ID'
        },
        title:{
            name:'title',
            label:'Title',
            value:'',
            error:false,
            errorMessage:'You must enter Title',
            required:true,
            fieldtype:'string'
        },
        email:{
            name:'email',
            label:'Email',
            value:'',
            error:false,
            errorMessage:'You must enter Email',
            required:true,
            fieldtype:'emailid'
        },
        phone:{
            name:'phone',
            label:'Phone',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'mobile'
        },
        inviteeid:{
            name:'inviteeid',
            label:'Invitee Id',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'ID'
        },
        status:{
            name:'status',
            label:'Status',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'string'
        },
        customertype:{
            name:'customertype',
            label:'Customer Type',
            value:'',
            error:false,
            errorMessage:'You must enter Customer Type',
            required:true,
            fieldtype:'string'
        },
        membercount:{
            name:'membercount',
            label:'Member Count',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'number'
        },
      }
}

export function getColumns(payload){
  let actionList = [
        
        { field: 'title', headerName: 'Title', width:150 },
        { field: 'email', headerName: 'Email', width:150 },
        { field: 'phone', headerName: 'Phone', width:150 },
        { field: 'inviteeid', headerName: 'Invitee Id', width:150 },
        { field: 'status', headerName: 'Status', width:150 },
        { field: 'customertype', headerName: 'Customer Type', width:150 },
        { field: 'membercount', headerName: 'Member Count', width:150 },
  ];
  if (payload!==undefined){
    payload.actionList && payload.actionList.map((actionObj)=>{
    actionList.push(
      {
        field: actionObj.title,
        headerName: actionObj.title,
        sortable: false,
        width:60,
        disableClickEventBubbling: false,
        renderCell: (params) => {
          return (
              <Grid item onClick={()=>{actionObj.callBackMethod(params.row.id)}}>
                <Tooltip title={actionObj.title} sx={{cursor: "pointer"}}>
                  {actionObj.icon}
                </Tooltip>
              </Grid>
          );
        }
      })
    })
  }
  return actionList;
}

export function setData(data, formValues, setFormValues){
   
    setFormValues({
      ['id']:{
        ...formValues['id'],
        value:data.id,
      },
       ['businessid']:{
          ...formValues['businessid'],
          value:data.businessid,
        },['title']:{
          ...formValues['title'],
          value:data.title,
        },['email']:{
          ...formValues['email'],
          value:data.email,
        },['phone']:{
          ...formValues['phone'],
          value:data.phone,
        },['inviteeid']:{
          ...formValues['inviteeid'],
          value:data.inviteeid,
        },['status']:{
          ...formValues['status'],
          value:data.status,
        },['customertype']:{
          ...formValues['customertype'],
          value:data.customertype,
        },['membercount']:{
          ...formValues['membercount'],
          value:data.membercount,
        },
      })
}