import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Button, Typography, Box, Divider } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import SuccessMessage from 'core/components/SuccessMessage';
import { addMlmuserObj, setMlmuserOnboardModal, setMlmuserTandcModal } from 'store/slice/DmpSlice';
import { setCartObj, setPartnerObj, setInviteeObj,setInviteeProcessStatus } from 'store/slice/EcomSlice';

export default function InitiatePartnership() {
    const dispatch = useDispatch();
    const { inviteeMobileNumber, partnerObj, inviteeObj } = useSelector((state) => state.ecomslice.mlm);
    const initiatePartnershipProcess = () =>{
            dispatch(setInviteeProcessStatus("PARTNERSHIP_PROCESS_START"));
        }
    return (
        <>
            <Grid container justifyContent="center" alignItems="center" sx={{backgroundColor:'white', padding:1, borderRadius:2}}>
                        <Grid item >
                            <Typography variant="h6">
                                Thank you <Box component="span" sx={{ color: "primary.main", fontWeight: "bold", fontSize:18}}>{inviteeObj.title}</Box> for your interest in being Partner of G&N MLM Program. 
                            </Typography>
                        </Grid>
                        
                    </Grid>
                    <Grid container justifyContent="center" alignItems="center" sx={{paddingTop:5}}>
                    <Grid item>
                            <Button variant="contained" onClick={()=>initiatePartnershipProcess()}>Inititiate Pertnership Process</Button>
                        </Grid>
                    </Grid>

        </>
    )
}