import {useState, useEffect} from 'react';
import { Grid, Box, Typography } from '@mui/material';


export default function TandcActionImpl(){

    return (
        <Grid container>
            <Grid item>
                <Typography variant="h5" color="primary.darker" sx={{paddingBottom:1}}>
                    Terms and Conditions for our Multi-Level Marketing Program
                </Typography>
            </Grid>
            <Grid item>
                <Box lg={12} sx={{ flexGrow: 1 }}>
                    <Grid item sx={{ overflowY: "scroll", backgroundColor: 'white', maxHeight: '60vh' }}>
                        <Grid container direction="column" justifyContent="flex-start" sx={{ width: '100%', padding:1 }} >
                            <Grid item>
                                <Typography variant="h5">
                                    1. Program Overview
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="h6" sx={{paddingTop:.5}}>
                                1.1 This Multi-Level Marketing (MLM) program is designed to reward participants for referrals and sales made within their network.
                                </Typography>
                                <Typography variant="h6" sx={{paddingTop:.5}}>
                                1.2 Participants are eligible to earn credit-back incentives based on their referral levels as described below.
                                </Typography>
                                
                            </Grid>
                        </Grid>
                        <Grid container direction="column" justifyContent="flex-start" sx={{ width: '100%', padding:1 }} >
                            <Grid item>
                                <Typography variant="h5">
                                    2. Credit-Back Incentive Structure
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ backgroundColor: 'white', }}>
                                <Grid container justifyContent="center" sx={{ padding: .25 }} >
                                    <Grid item xs="8">
                                        <Typography variant="h6">Customer Discount</Typography>
                                    </Grid>
                                    <Grid item xs="4">
                                        <Typography variant="h5">&nbsp;&nbsp;&nbsp;20%</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent="center" >
                                    <Grid item xs="8">
                                        <Typography variant="h6">Level 1 Credit</Typography>
                                    </Grid>
                                    <Grid item xs="4">
                                        <Typography variant="h5">&nbsp;&nbsp;&nbsp;10%</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent="center" >
                                    <Grid item xs="8">
                                        <Typography variant="h6">Level 2 Credit</Typography>
                                    </Grid>
                                    <Grid item xs="4">
                                        <Typography variant="h5">&nbsp;&nbsp;&nbsp;10%</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent="center"  >
                                    <Grid item xs="8">
                                        <Typography variant="h6">Level 3 Credit</Typography>
                                    </Grid>
                                    <Grid item xs="4">
                                        <Typography variant="h5">&nbsp;&nbsp;&nbsp;5%</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent="center"  >
                                    <Grid item xs="8">
                                        <Typography variant="h6">Level 4 Credit</Typography>
                                    </Grid>
                                    <Grid item xs="4">
                                        <Typography variant="h5">&nbsp;&nbsp;&nbsp;2%</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container direction="column" justifyContent="flex-start" sx={{ width: '100%', padding:1 }} >
                            <Grid item>
                                <Typography variant="h5">
                                    3. Credit-Back Terms
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="h6" sx={{paddingTop:.5}}>
                                3.1 Credit-back incentives are calculated based on the net purchase value after applying any discounts, promotions, or applicable taxes.
                                </Typography>
                                <Typography variant="h6" sx={{paddingTop:.5}}>
                                3.2 Credits earned will be transferred to partner's bank account within a week.
                                </Typography>
                                
                            </Grid>
                        </Grid>
                        <Grid container direction="column" justifyContent="flex-start" sx={{ width: '100%', padding:1 }} >
                            <Grid item>
                                <Typography variant="h5">
                                    4. Changes to Credit-Back Structure
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="h6" sx={{paddingTop:.5}}>
                                4.1 The company reserves the right to modify, suspend, or discontinue the credit-back structure at its sole discretion and without prior notice.
                                </Typography>
                                <Typography variant="h6" sx={{paddingTop:.5}}>
                                4.2 Any changes will be communicated to participants via the company’s official communication channels, such as email or the company website.
                                </Typography>
                                
                            </Grid>
                        </Grid>
                        <Grid container direction="column" justifyContent="flex-start" sx={{ width: '100%', padding:1 }} >
                            <Grid item>
                                <Typography variant="h5">
                                5. Eligibility and Participation
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="h6" sx={{paddingTop:.5}}>
                                5.1 Participants must be registered with the company and agree to these terms and conditions.
                                </Typography>
                                <Typography variant="h6" sx={{paddingTop:.5}}>
                                5.2 Misuse of the program, including but not limited to fraudulent referrals, may result in the forfeiture of credits and suspension or termination of participation in the program.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container direction="column" justifyContent="flex-start" sx={{ width: '100%', padding:1 }} >
                            <Grid item>
                                <Typography variant="h5">
                                6. General Terms
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="h6" sx={{paddingTop:.5}}>
                                6.1 The company retains full ownership of the program and all related data.
                                </Typography>
                                <Typography variant="h6" sx={{paddingTop:.5}}>
                                6.2 The company’s decision on any matter related to the program shall be final and binding.
                                </Typography>
                                <Typography variant="h6" sx={{paddingTop:.5}}>
                                6.3 These terms and conditions are governed by the laws of Haryana, India
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>

        </Grid>
    )
}