import { createSlice } from '@reduxjs/toolkit';

export const ChoiceSelectionSlice = createSlice({
    name: "choiceSelection",
    initialState: {
        params:{}
    },
    reducers: {
        
        setViewParams: (state, action) =>{
            
            state.params=action.payload;
        }
    }
});
export const { setViewParams } = ChoiceSelectionSlice.actions;
export default ChoiceSelectionSlice.reducer;