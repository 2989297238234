
export const validMobileNumberEntry=(e)=>{
    if (isNaN(e.target.value) || e.target.value.length>10)    return false;
    return true
}

export const validNameEntry=(e)=>{
    
    return /^[a-zA-Z0-9. ]*$/.test(e.target.value);
    
}

export const validEmailEntry=(e)=>{
    return /^[a-zA-Z0-9.@_-]*$/.test(e.target.value);
}
export const isEmailValid=(value)=>{
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
}