import {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ProductDetails from './ProductDetails';

export default function ManageProductActionImpl(){
    const {productObj} = useSelector((state)=>state.dmpslice.productDashboard);
    return (
        <>
        <ProductDetails obj={productObj}/>
        </>
    )
}