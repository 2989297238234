import {getAPIprefix} from '../../../api/ApiUtils';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

// Create an Axios instance
const assetAPI = axios.create({
    //baseURL: getAPIprefix()+'/dam-core-service', // Replace with your actual API URL
    baseURL: 'http://localhost:8082/dam-core-service', // Replace with your actual API URL
  });
  
  // Set up an Axios interceptor for handling 208 status
  assetAPI.interceptors.response.use(
    (response) => {
      if (response.status === 208) {
        console.warn("Duplicate file upload attempt - already processed.");
        return Promise.reject({ message: "Already Reported", status: 208 });
      }
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 208) {
        console.warn("Duplicate request - file already processed.");
        return Promise.reject({ message: "Already Reported", status: 208 });
      }
      return Promise.reject(error);
    }
  );

  export default assetAPI;
