import React, { useState, useEffect } from 'react'

import {Grid} from '@mui/material'

import ComponentContainer from 'core/template/ComponentContainer';
  import AddressEditFormImpl from './AddressEditFormImpl';
import AddressList from './AddressList';
import { useSelector, useDispatch } from 'react-redux';

import Notification from '../Notification';

import AddressListView from './AddressListView';
import { CoreContext } from '../../context/CoreContext';


export default function AddressDashboard(){
  const { setKeyValue, getKeyValues, keyvalues } = React.useContext(CoreContext);
  const {showEdit} = useSelector((state)=>state.ecomslice.addressDashboard);
  const [ addressId, setAddressId] = React.useState('');
  const [reloadAddressList, setReloadAddressList] = useState(true);

  return (
      <>
      {/* <Notification/> */}
      {showEdit?
        <ComponentContainer containedComponent = {
          <AddressEditFormImpl 
            setReloadAddressList={setReloadAddressList}
            addressId={addressId}
            />}/>:
            <>
            <Grid container direction="column" >
              <Grid item>
                    {
                      <AddressListView 
                      setAddressId = {setAddressId}/>
                    }
              </Grid>
            </Grid>
            </>
      }
    </>


  )

}
