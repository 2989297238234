import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import CategoryDashboard from '../CategoryDashboard';

import { openDrawer } from './../../../store/slice/MenuSlice';

export default function CategoryPage() {
    const dispatch = useDispatch();
    const { CategoryList} = useSelector((state)=>state.dmpaslice)
    useEffect(() => {
        dispatch(openDrawer(false));
    },[]);

    return (
        < CategoryDashboard />
    )

}