import React, { useState, useEffect } from 'react'

import { Grid, Typography, Box } from '@mui/material';

import IconEnabled from '../../../../core/components/layout/IconEnabled';
import { useDispatch, useSelector } from 'react-redux';
import AddressChip from './AddressChip';
import { CoreContext } from '../../../context/CoreContext';
import AddressInfo from './AddressInfo';
import AddressListTableRow from './AddressListTableRow';

export default function AddressListTable({ payload }) {

  const { showloading } = React.useContext(CoreContext);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const {userprofileInfo} = useSelector((state)=>state.headerslice)
  
  useEffect(() => {
    setRows(payload.tableList);
  }, [payload])

  const handleRowClick = (rowId) => {
    setRows(prevData =>
      prevData.map(item =>
        item.id === rowId ? { ...item, 'expand': !item.expand } : item
      )
    );
  }

  const getActionComponent = (rowObj) => {
    return <>
      { payload.actionList.map(actionObj=>{
         /*if (
            (
              actionObj.status===undefined || (
                  actionObj.status!==undefined && 
                  (actionObj.status==='' || (actionObj.status!=='' && actionObj.status.toLowerCase().includes(rowObj.orderstatus.toLowerCase())))
              )
            )
          && 
          (actionObj.role===undefined || actionObj.role!=undefined && (actionObj.role==='' || actionObj.role.includes(userprofileInfo.rolename)))
        ) */
        return <IconEnabled actionObj={actionObj}  id={rowObj.id}/>})}
    </>
  }

  return (
    <>
      <Grid container direction="column" sx={{ justifyContent: "flex-start", alignItems: "flex-start"}}>
        <Grid item sx={{ width: '100%', }}>
          <Box  sx={{ flexGrow: 1 }}>
              <Grid container direction="column" sx={{ justifyContent: "flex-start", alignItems: "flex-start", width: '100%',}}>
              
              <Grid lg={12} xs={12} item sx={{ overflowY: "scroll", backgroundColor: 'white', maxHeight: '50vh', width:'100%',}}>
                {rows.map(obj => {
                    return (
                       < AddressListTableRow obj={obj} getActionComponent={getActionComponent} handleRowClick={handleRowClick}/>
                    ) 
                })
                }
                {
                    rows.length === 0 ?
                      <Grid container direction="row"  justifyContent="center"  alignItems="center" padding={1}
                        sx={{ width: '100%', backgroundColor: '#fafafa' }}
                      >
                        <Typography sx={{ fontWeight: 'light', fontSize: 14 }}><i>Records(s) are not added, yet.</i></Typography>
                      </Grid>
                      : <></>
                  }
                </Grid>
                
               </Grid>
               </Box>
            </Grid>
       
      </Grid>
    </>
  );


}