import React, { useState, useEffect } from 'react'

import {Grid} from '@mui/material'

import ComponentContainer from '../../core/template/ComponentContainer';
import {CoreContext} from "../../core/context/CoreContext";
import MlmusertypeEditForm from './MlmusertypeEditForm';
import MlmusertypeList from './MlmusertypeList';
import { useSelector, useDispatch } from 'react-redux';

import Notification from '../../core/components/Notification';

import MlmusertypeListView from './MlmusertypeListView'


export default function MlmusertypeDashboard(){
  const { setKeyValue, getKeyValues, keyvalues } = React.useContext(CoreContext);
  const {showEdit} = useSelector((state)=>state.dmpslice.mlmusertypeDashboard);
  const [ mlmusertypeId, setMlmusertypeId] = React.useState('');
  const [reloadMlmusertypeList, setReloadMlmusertypeList] = useState(true);

  return (
      <>
      <Notification/>
      {showEdit?
        <ComponentContainer containedComponent = {
          <MlmusertypeEditForm 
            setReloadMlmusertypeList={setReloadMlmusertypeList}
            mlmusertypeId={mlmusertypeId}
            />}/>:
            <>
            <Grid container direction="column" >
              <Grid item>
                    {
                      <MlmusertypeListView 
                      setMlmusertypeId = {setMlmusertypeId}/>
                    }
              </Grid>
            </Grid>
            </>
      }
    </>


  )

}
