import { Grid, Tooltip} from "@mui/material";

export function getMlmcreditStruct(){
    return {
        id:{
            name:'id',
            label:'ID',
            value:'',
            error:false,
            errorMessage:'',
            required:false
        },
        
        businessid:{
            name:'businessid',
            label:'Business Id',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'ID'
        },
        title:{
            name:'title',
            label:'Title',
            value:'',
            error:false,
            errorMessage:'You must enter Title',
            required:true,
            fieldtype:'string'
        },
        orderid:{
            name:'orderid',
            label:'Order Id',
            value:'',
            error:false,
            errorMessage:'You must enter Order Id',
            required:true,
            fieldtype:'ID'
        },
        creditlevel:{
            name:'creditlevel',
            label:'Credit Level',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'number'
        },
        creditvalue:{
            name:'creditvalue',
            label:'Credit Value',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'double'
        },
        srpartnerid:{
            name:'srpartnerid',
            label:'Sr Partner Id',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'ID'
        },
        srpartnername:{
            name:'srpartnername',
            label:'Sr Partner Name',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'string'
        },
        ordervalue:{
            name:'ordervalue',
            label:'Order Value',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'double'
        },
        mlmuserid:{
            name:'mlmuserid',
            label:'MLM User Id',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'bodynamiclist'
        },
        mlmusername:{
            name:'mlmusername',
            label:'MLM User Name',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'string'
        },
        creditviaid:{
            name:'creditviaid',
            label:'Credit Via Id',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'bodynamiclist'
        },
        creditvianame:{
            name:'creditvianame',
            label:'Credit Via Name',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'string'
        },
      }
}

export function getColumns(payload){
  let actionList = [
        
        { field: 'title', headerName: 'Title', width:100 },
        { field: 'orderid', headerName: 'Order Id', width:150 },
        { field: 'creditlevel', headerName: 'Credit Level', width:150 },
        { field: 'creditvalue', headerName: 'Credit Value', width:150 },
        { field: 'srpartnerid', headerName: 'Sr Partner Id', width:150 },
        { field: 'srpartnername', headerName: 'Sr Partner Name', width:150 },
  ];
  if (payload!==undefined){
    payload.actionList && payload.actionList.map((actionObj)=>{
    actionList.push(
      {
        field: actionObj.title,
        headerName: actionObj.title,
        sortable: false,
        width:60,
        disableClickEventBubbling: false,
        renderCell: (params) => {
          return (
              <Grid item onClick={()=>{actionObj.callBackMethod(params.row.id)}}>
                <Tooltip title={actionObj.title} sx={{cursor: "pointer"}}>
                  {actionObj.icon}
                </Tooltip>
              </Grid>
          );
        }
      })
    })
  }
  return actionList;
}

export function setData(data, formValues, setFormValues){
   
    setFormValues({
      ['id']:{
        ...formValues['id'],
        value:data.id,
      },
       ['businessid']:{
          ...formValues['businessid'],
          value:data.businessid,
        },['title']:{
          ...formValues['title'],
          value:data.title,
        },['orderid']:{
          ...formValues['orderid'],
          value:data.orderid,
        },['creditlevel']:{
          ...formValues['creditlevel'],
          value:data.creditlevel,
        },['creditvalue']:{
          ...formValues['creditvalue'],
          value:data.creditvalue,
        },['srpartnerid']:{
          ...formValues['srpartnerid'],
          value:data.srpartnerid,
        },['srpartnername']:{
          ...formValues['srpartnername'],
          value:data.srpartnername,
        },['ordervalue']:{
          ...formValues['ordervalue'],
          value:data.ordervalue,
        },['mlmuserid']:{
          ...formValues['mlmuserid'],
          value:data.mlmuserid,
        },['mlmusername']:{
          ...formValues['mlmusername'],
          value:data.mlmusername,
        },['creditviaid']:{
          ...formValues['creditviaid'],
          value:data.creditviaid,
        },['creditvianame']:{
          ...formValues['creditvianame'],
          value:data.creditvianame,
        },
      })
}