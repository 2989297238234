import {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {Grid, Box,Typography,Divider} from '@mui/material';
import FieldView from 'core/components/layout/FieldView';
import MlmorderInfo from '../MlmorderInfo';
import { convertToRS, transformDateValue,convertToRSWithTwoDec } from 'core/js/FormUtils';
import MlmcreditDetails from './MlmcreditDetails';



export default function MlmorderListTableRow({obj, getActionComponent,handleRowClick}){
  const {mobileView} = useSelector((state)=>state.menu);
  useEffect(()=>{
  },[])
  const getHoverColor = () =>{
    if (mobileView) return ''
    return 'primary.main';
  }
  const getHoverBackgroundColor = () =>{
    if (mobileView) return ''
    return 'secondary.200';
  }
  const getBackgroundColor = (obj) =>{
    if (mobileView) {
      if (obj.expand) return 'secondary.500'
      return 'white'
    }
    return 'white'
  }
    return (
      
      <Grid container sx={{  backgroundColor:'white',border:obj.expand?'1px dashed grey':''}}>
        <Grid item lg={12} sx={{ width: '100%',}}>
          {/* <ROW1 - CONTAINER/> */}
          <Grid container direction="row" onClick={() => handleRowClick(obj.id)}
            sx={{cursor: 'pointer',
              justifyContent: "flex-start", alignItems: "center",  padding:2,  
              backgroundColor: getBackgroundColor(obj),
              color: mobileView && obj.expand?'primary.main':'primary.darker',
              '&:hover': {
                backgroundColor: getHoverBackgroundColor, 
                color: getHoverColor()
              },
            }}>
            {/* <ROW1 - CELLS/> */}
            
              <Grid item lg={2} xs={3} ><Typography color="" sx={{ fontWeight: 'light', fontSize: 15 }}>{obj.title}</Typography></Grid>
              <Grid item lg={1} xs={2} ><Typography color="" sx={{ fontWeight: 'light', fontSize: 15 }}>{obj.orderid}</Typography></Grid>
              <Grid item lg={2} xs={2} ><Typography color="" sx={{ fontWeight: 'light', fontSize: 15 }}>{transformDateValue(obj.creationTimestamp)}</Typography></Grid>
              <Grid item lg={2} xs={3} ><Typography color="" sx={{ fontWeight: 'light', fontSize: 15 }}>{convertToRSWithTwoDec(obj.ordervalue)}</Typography></Grid>
              {/* <Grid item lg={2} xs={2} ><Typography color="" sx={{ fontWeight: 'light', fontSize: 15 }}>{obj.partnerid}</Typography></Grid>
              <Grid item lg={1} xs={2} ><Typography color="" sx={{ fontWeight: 'light', fontSize: 15 }}>{obj.memberid}</Typography></Grid> */}
              
              <Grid item lg={2} xs={2} ><Typography color="" sx={{ fontWeight: 'light', fontSize: 15 }}>{convertToRSWithTwoDec(obj.creditvalue)} [{((obj.creditvalue/obj.ordervalue)*100).toFixed(2)}%]</Typography></Grid>

              {/* <Grid item lg={2} xs={2} >
                <Grid container direction="row" sx={{ justifyContent: "flex-end", width: '100%', }}>
                  {getActionComponent(obj)}
                </Grid>
              </Grid> */}
            
          </Grid>
        </Grid>
        {obj.expand ?
        <Grid container direction="row">
          <Grid item sx={{ width: '100%' }}>
            < MlmcreditDetails  orderId={obj.orderid} orderValue={obj.ordervalue}/>
          </Grid>
        </Grid>
        : <Grid container direction="row">
          {/* <Grid item sx={{ width: '100%' }}>
            <Divider />
          </Grid> */}
        </Grid>}
      </Grid>
      
    )
}