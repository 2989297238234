import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";

import Alert from '@mui/material/Alert';
import { Button, Divider,TextField, Grid, Typography, Box,CircularProgress } from "@mui/material"

import {getMlmuserStruct, setData} from '../../MlmuserStruct'
import { updateTextValue, handleSubmit,updateValue,updateSelectedValue} from '../../../../core/js/FormUtils'
import {createAPI, updateAPI, getAPI, updateEditableAPI} from '../../MlmuserAPI';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {validMobileNumberEntry, validNameEntry, validEmailEntry} from '../../../../core/js/FormValidation';

import { useSearchParams } from "react-router-dom";
import { useDispatch,useSelector } from 'react-redux';
import { updateMlmuserObj,addMlmuserObj, setShowEditForMlmuser} from 'store/slice/DmpSlice';
import { setApiInProgress } from 'store/slice/HeaderSlice';
import { addMemberAPI } from 'api/CoreMlmmgmtWebServices';
import { CoreContext } from 'core/context/CoreContext';



export default function MlmuserEditFormImpl(){
    const dispatch = useDispatch();
    const { setNotification, lastURL } = React.useContext(CoreContext);
    const [formValues, setFormValues] = useState(getMlmuserStruct);
    const [showError, setShowError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('Something went wrong');
    const [dataLoaded, setDataLoaded] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const {mlmUserObj} = useSelector((state)=>state.ecomslice.mlm);
    
    const {mlmuserDashboard} = useSelector((state)=>state.dmpslice);
    const [mlmuserId, setMlmuserId] = useState(mlmuserDashboard.selectedId);
    const { businessid } = useSelector((state) => state.headerslice.userprofileInfo)
        
    const {mlmusertypeList} = useSelector((state)=>state.dmpslice);
    const handleDynamicSelectedValueFormlmusertypeid = (e) =>{
        updateSelectedValue(formValues, setFormValues,e, 
            'mlmusertypename', e.target.value==0?'Select Item':mlmusertypeList.find(obj => obj.id === e.target.value).title);
    }

    const {apiInProgress} = useSelector((state)=>state.headerslice)
    useEffect(()=>{
    },[apiInProgress])

    useEffect(() => {
        if (mlmuserId!==-1) {
            if (dataLoaded) return;
            getAPI(mlmuserId, loadForm);
        }
        else {
           // formValues.<<CONTEXT>>.value=searchParams.get('<<CONTEXT>>');
            setDataLoaded(true);
        }
        setFormValues({
            ...formValues,
            ['businessid']: {
                ...formValues['businessid'],
                value: businessid
            },
            ['seniorpartnerid']: {
                ...formValues['seniorpartnerid'],
                value: mlmUserObj.userid
            },
            ['usertype']: {
                ...formValues['usertype'],
                value: "MEMBER"
            },
            
        })
    },[]);

    const loadForm = (data)=>{
        setData(data, formValues, setFormValues);
        setDataLoaded(true);
    }
    const handleRadioChange = (name, value) => {
        setShowError(false);
        console.log(value+" == "+getRadioAPIvalue(value))
        updateValue(formValues, setFormValues, name, getRadioAPIvalue(value));
    }
    const getRadioValue = (value) => {
        if (value ) 
            return "yes"
        else 
            return "no"
    }
    const getRadioAPIvalue = (value) => {
        if (value ==="yes") 
            return true
        else 
            return false
    }
    const handleTextChange = (e) => {
        setShowError(false);
        updateTextValue(formValues, setFormValues,e);
    }
    const handleDoubleChange = (e) => {
        setShowError(false);
        const parsed = parseFloat(e.target.value);
        if (!isNaN(parsed) && e.target.value == parsed)
         updateTextValue(formValues, setFormValues,e);
    }
    const handleNameChange = (e) =>{
        setShowError(false);
        
       if (validNameEntry(e))
        updateTextValue(formValues, setFormValues,e);
    }
    const handleMobileChange = (e) => {
        setShowError(false);
        
       if (validMobileNumberEntry(e))
            updateTextValue(formValues, setFormValues,e);
    }
    const handleEmailidChange = (e) => {
        setShowError(false);
        
       if (validEmailEntry(e))
            updateTextValue(formValues, setFormValues,e);
    }

    const createSuccessCallback = (obj) =>{
         dispatch(setApiInProgress(false));
        dispatch(addMlmuserObj(obj.mlmuserInfo));
        setNotification('Record has been cretaed successfully !!!');
        dispatch(setShowEditForMlmuser(false));
    }
    const updateSuccessCallback = (obj) =>{
         dispatch(setApiInProgress(false));
        dispatch(updateMlmuserObj(obj));
        setNotification('Record with id['+mlmuserId+'] has been updated successfully !!!');
        dispatch(setShowEditForMlmuser(false));
        
    }
    const failuerCallback = (errorMsg) => {
         dispatch(setApiInProgress(false));
        setErrorMsg(errorMsg);
        setShowError(true);
    }
    const callCreateAPI = (formValues, successCallBack, failuerCallback) =>{
        dispatch(setApiInProgress(true));
        createAPI(formValues, successCallBack, failuerCallback);
    }
    const callUpdateAPI = (id,formValues, updateSuccessCallback, failuerCallback) =>{
        dispatch(setApiInProgress(true));
        updateAPI(id,formValues, updateSuccessCallback, failuerCallback);
    }
    const onSubmit=(e)=>{
        handleSubmit(e,mlmuserId, formValues, setFormValues, createSuccessCallback, updateSuccessCallback, failuerCallback, callAddMemberAPI, callUpdateAPI);
    }
    const setDate = (fieldName, value) => {
        var date = new Date(value);
        
        const day = date.getDate();
        const dayStr = day < 10 ? '0' + day : day.toString();

        const month = date.getMonth() + 1;
        const monthStr = month < 10 ? '0' + month : month.toString();
        var dateStr =date.getFullYear()+ "-" +  monthStr + "-" + dayStr + "T00:00:00.000Z";
        updateValue(formValues, setFormValues, fieldName, dateStr);
    }
    const callAddMemberAPI = () =>{
        addMemberAPI({
            "mobileNumber":formValues.mobilenumber.value,
            "name":formValues.title.value,
            "pan":formValues.pan.value
        },createSuccessCallback,failuerCallback)
    }
    return (
        <form  noValidate onSubmit={onSubmit} >
            <Grid container direction="column" padding={.25}>
                {showError? <Alert severity="error">{errorMsg}</Alert>: ''}
                <Box sx={{ flexGrow: 1 }}>
                    <Grid item sx={{overflowY: "scroll", backgroundColor:'white',maxHeight:440}}>
                        <Grid container direction="column" padding={1}>
                            {/* <Grid item>
                                <Typography variant='h4'>{mlmuserId===-1?'Add':'Edit'} User Info</Typography>
                                <Divider/>
                            </Grid> */}
                            <Grid height='10px' ></Grid>
                            {dataLoaded?
                                <Grid container display="flex" spacing={2} direction="row" justifyContent="space-between" alignItems="center"> 
                            <Grid item lg={4} xs={12} sx={{display:'none'}}>
                                <TextField size="Normal"
                                    placeholder={formValues.mobilenumber.placeholder}
                                    required={formValues.mobilenumber.required}
                                    label={formValues.mobilenumber.label}
                                    name={formValues.mobilenumber.name}
                                    value={formValues.mobilenumber.value}
                                    onChange={handleMobileChange}
                                    error={formValues.mobilenumber.error}
                                    helperText={formValues.mobilenumber.error && formValues.mobilenumber.errorMessage}
                                    sx={{width:'100%'}}
                                />
                            </Grid>                           
                            <Grid item lg={4} xs={12} sx={{display:'none'}}>
                                <TextField size="Normal"
                                    placeholder={formValues.title.placeholder}
                                    required={formValues.title.required}
                                    label="Name"
                                    name={formValues.title.name}
                                    value={formValues.title.value}
                                    onChange={handleTextChange}
                                    error={formValues.title.error}
                                    helperText={formValues.title.error && formValues.title.errorMessage}
                                    sx={{width:'100%'}}
                                />
                            </Grid>
                            <Grid item lg={4} xs={12}>
                                <TextField size="Normal"
                                    placeholder={formValues.pan.placeholder}
                                    required={formValues.pan.required}
                                    label={formValues.pan.label}
                                    name={formValues.pan.name}
                                    value={formValues.pan.value}
                                    onChange={handleTextChange}
                                    error={formValues.pan.error}
                                    helperText={formValues.pan.error && formValues.pan.errorMessage}
                                    sx={{width:'100%'}}
                                />
                            </Grid>
                            <Grid item lg={4} xs={12} sx={{display:'none'}}>
                                <TextField size="Normal"
                                    placeholder={formValues.usertype.placeholder}
                                    required={formValues.usertype.required}
                                    label={formValues.usertype.label}
                                    name={formValues.usertype.name}
                                    value={formValues.usertype.value}
                                    onChange={handleTextChange}
                                    error={formValues.usertype.error}
                                    helperText={formValues.usertype.error && formValues.usertype.errorMessage}
                                    sx={{width:'100%'}}
                                />
                            </Grid>
                                   
                            <Grid item lg={4} xs={12} sx={{display:'none'}}>
                                <FormControl sx={{  width:'100%'}} size="Normal">
                                    <InputLabel id="demo-simple-select-autowidth-label">{formValues.mlmusertypeid.label}</InputLabel>
                                    <Select
                                            labelId="demo-simple-select-autowidth-label"
                                            id="demo-simple-select-autowidth"
                                            label={formValues.mlmusertypeid.label}
                                            name={formValues.mlmusertypeid.name}
                                            value={formValues.mlmusertypeid.value}
                                            onChange={handleDynamicSelectedValueFormlmusertypeid}
                                            
                                            error={formValues.mlmusertypeid.error}
                                            helpertext={formValues.mlmusertypeid.error && formValues.mlmusertypeid.errorMessage}
                                            >
                                            <MenuItem key={0} value="0">Select Item</MenuItem>
                                            {
                                                mlmusertypeList && mlmusertypeList.map((item, i) => <MenuItem key={i} value={item.id}>{item.title}</MenuItem>) 
                                            }
                                        </Select>
                                </FormControl> 
                            </Grid>                        
                            <Grid item lg={4} xs={12} sx={{display:'none'}}>
                                <TextField size="Normal"
                                    placeholder={formValues.mlmusertypename.placeholder}
                                    required={formValues.mlmusertypename.required}
                                    label={formValues.mlmusertypename.label}
                                    name={formValues.mlmusertypename.name}
                                    value={formValues.mlmusertypename.value}
                                    onChange={handleTextChange}
                                    error={formValues.mlmusertypename.error}
                                    helperText={formValues.mlmusertypename.error && formValues.mlmusertypename.errorMessage}
                                    sx={{width:'100%'}}
                                />
                            </Grid>
                                            
                            <Grid item lg={4} xs={12} sx={{display:'none'}}>
                                <TextField size="Normal"
                                    placeholder={formValues.seniorpartnerid.placeholder}
                                    required={formValues.seniorpartnerid.required}
                                    label={formValues.seniorpartnerid.label}
                                    name={formValues.seniorpartnerid.name}
                                    value={formValues.seniorpartnerid.value}
                                    onChange={handleTextChange}
                                    error={formValues.seniorpartnerid.error}
                                    helperText={formValues.seniorpartnerid.error && formValues.seniorpartnerid.errorMessage}
                                    sx={{width:'100%'}}
                                />
                            </Grid>
                        
                                    <Grid item lg={4} xs={12} sx={{display:'none'}}>
                                        <TextField size="Normal"
                                            placeholder={formValues.businessid.placeholder}
                                            required={formValues.businessid.required}
                                            label={formValues.businessid.label}
                                            name={formValues.businessid.name}
                                            value={formValues.businessid.value}
                                            onChange={handleTextChange}
                                            error={formValues.businessid.error}
                                            helpertext={formValues.businessid.error && formValues.businessid.errorMessage}
                                            sx={{ width: '100%',  }}
                                        />
                                    </Grid> 
                                </Grid>
                            :<></>}
                        </Grid>
                    </Grid>
                </Box>
                {/* <Grid height='10px' ></Grid> */}
                <Grid padding={1}>
                    <Divider/>
                </Grid>
                <Grid container direction="row" justifyContent="flex-end"  >
                {   apiInProgress?<CircularProgress/>:
                    <>
                        <Button variant="outlined" type="submit" disabled={formValues.mobilenumber.value.length!==10}>{mlmuserId===-1?'Add':'Save'}</Button>&nbsp;&nbsp;&nbsp;
                        <Button variant="outlined" onClick={()=>{dispatch(setShowEditForMlmuser(false));}}>Close</Button>
                    </>
                }
                </Grid>
            </Grid>
        </form>
    );
};