import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Button, Grid, Typography, CircularProgress, Divider, TextField, Box } from "@mui/material";
import { getListAPI as getAssetListAPI } from './AssetAPI';
import { setSelectedImage } from 'store/slice/ImageGallerySlice';
import { setApiInProgress } from 'store/slice/HeaderSlice';
import { setAssetList } from 'store/slice/ImageGallerySlice';


const ImageGalleryMainArea = () => {
    const dispatch = useDispatch();
    //const [assetList, setAssetList] = useState([]);
    const {assetList} = useSelector((state)=>state.imagegalleryslice);
    const { imageNameUnderSearch, selectedImage,selectedImagePath } = useSelector((state) => state.imagegalleryslice);
    const [filteredAssetList, setFilteredAssetList] = useState([]);

    const { apiInProgress } = useSelector((state) => state.headerslice);

    const fetchAssetListCallBack = (data) => {
        dispatch(setAssetList(data));
        setFilteredAssetList(data);
        dispatch(setApiInProgress(false));
    }
    useEffect(() => {
        console.log('ONLOAD called.>'+assetList.length);
        //if (assetList.length===0){
            dispatch(setApiInProgress(true));
            getAssetListAPI(fetchAssetListCallBack)
        //}
        
        
    }, []);
    
    useEffect(() => {
        setFilteredAssetList(
            assetList.filter(assetObj => assetObj.name.toLowerCase().includes(imageNameUnderSearch))
        )
    }, [imageNameUnderSearch])

    const handleImageClick = (imageObj) => {
        if (selectedImage !== imageObj.name)
            dispatch(setSelectedImage(imageObj))
        else
            dispatch(setSelectedImage({ 'name': '---', 'path': '' }))
    }
    return (
        <Box lg={12} md={12} sx={{ flexGrow: 1 }}>
            <Grid item sx={{ overflowY: "scroll", backgroundColor: 'white', maxHeight: '48vh' }}>
                <Grid container direction="row" sx={{ width: '100%', backgroundColor: 'white', justifyContent:apiInProgress?'center':'flex-start', alignItems:'center' }} lg={12}>
                    <>
                        {
                            apiInProgress ?  <Grid item sx={{padding:10}}><CircularProgress/></Grid>
                                : <>
                                    {
                                        filteredAssetList.map(assetObj => {
                                            return (
                                                <Grid container direction="row" lg={3} md={3} sx={{
                                                    justifyContent: "center", alignItems: "center", width: '100%', padding:1
                                                    //border:'1px solid black'
                                                }}>
                                                    <Grid sx={{
                                                        cursor: 'pointer',
                                                        //borderRight: '1px solid #c4c4c4',
                                                        //backgroundColor: selectedImage === assetObj.name ? '#40a9ff' : '',
                                                        '&:hover': { 
                                                            backgroundColor: selectedImage !== assetObj.name ? 'secondary.200' : '', color: 'primary.main' 
                                                            //border: '1px solid #0050b3',
                                                        },
                                                    }} onClick={() => handleImageClick(assetObj)}
                                                    >
                                                        <img src={assetObj.path} alt={assetObj.name} style={{
                                                            border: '1 dotted black',
                                                            padding: 10, placeItems: 'center', width: '150px', height: '150px', borderRadius: '5px',
                                                            border:(selectedImage===assetObj.name || selectedImagePath===assetObj.path)?'2px solid #0050b3':'.5px solid #0050b3',
                                                        }} />

                                                    </Grid>
                                                    <Grid >{assetObj.name}</Grid>

                                                </Grid>
                                            )
                                        })
                                    }
                                    {/* {
                                        !apiInPorgress && filteredAssetList.length === 0 ? <>
                                            <Grid container sx={{ justifyContent: "center", alignItems: "center", paddingTop: 5 }}>
                                                <Grid sx={{ width: '500px', backgroundColor: '#e6f7ff', borderRadius: 5 }}>
                                                    <Typography sx={{ padding: 2 }}><i>Click on 'Select Image' button for Image selection and then click on 'Upload' Button.</i></Typography>
                                                </Grid>

                                            </Grid>
                                        </> : <></>
                                    } */}

                                </>}
                    </>

                </Grid>
            </Grid>
        </Box>
    )
}
export default ImageGalleryMainArea;