import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Button, Grid, Typography, CircularProgress, Divider, TextField, Box } from "@mui/material";
import { setShowImageGallery,setImageNameUnderSearch } from 'store/slice/ImageGallerySlice';

const ImageGalleryHeader = () => {
    const dispatch = useDispatch();
    const {imageNameUnderSearch} = useSelector((state)=>state.imagegalleryslice);
    const { apiInProgress } = useSelector((state) => state.headerslice);
   
    const showUploadImage = () =>{
        dispatch(setShowImageGallery(false));
    }
    const handleNameChange = (e)=>{
        dispatch(setImageNameUnderSearch(e.target.value))
    }
    return (
        <>
        {apiInProgress?<></>:
        
        <Grid
            container
            direction="row"
            sx={{
                justifyContent: "space-between",
                alignItems: "center",
                width: '100%',
                padding: 1,
                backgroundColor:'secondary.200'
            }}
            lg={12}
        >
            <Grid item lg={12} xs={12} sx={{ padding: 1, width: '100%' }}>
                <TextField size="Normal"
                    placeholder="Image name"
                    label="Type image name to search"
                    name="imageName"
                    value={imageNameUnderSearch}
                    onChange={handleNameChange}
                    sx={{ width: '100%' }}
                />
            </Grid>
            {/* <Grid item lg={2} xs={12}>
                <Button variant="outlined" onClick={()=>showUploadImage()}>Upload New Image</Button>
            </Grid> */}
        </Grid>

        }
        </>
    )
}
export default ImageGalleryHeader;