import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Divider, Grid } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  //border: '2px solid #000',
  boxShadow: 24,
  borderRadius:2,
  p: 2,
  width:{lg:'50%',xs:'80%'}
};

export default function DeleteNotification({id, width,setShowDeleteAlert, deleteRecord}) {
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} width={width}>
          <Grid container  minHeight={160} direction="column">
            <Grid container display="flex" direction="column" justifyContent="center" alignItems="center">
              <Grid item sx={{backgroundColor:'primary.darker', width:'100%', textAlign:'center', padding:1}}>
              <Typography id="modal-modal-title" variant="h6" component="h2"color="white" >
                CONFIRMATION
              </Typography>
              </Grid>
              
              <Divider/>
              <Grid item sx={{width:'100%', textAlign:'center', paddingTop:2}}>
              <Typography variant="h6">
                Are you sure to delete record with ID '{id}'?
              </Typography>
              </Grid>
            </Grid>
            <Box height="50px"></Box>
            <Divider/>
            <Grid container display="flex" direction="row" justifyContent="space-between" sx={{paddingTop:2}}>
              <Button variant="contained" onClick={()=> {setOpen(false); deleteRecord()}}> Yes</Button>
              <Button variant="contained" onClick={()=> {setOpen(false); setShowDeleteAlert(false)}}> No</Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
  );
}