import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Grid,Typography } from '@mui/material';

import MlmuserDashboard from '../MlmuserDashboard';
import { getListAPI as getMlmuserList,getMlmuserInfoByPartneridAPI } from 'components/mlmuser/MlmuserAPI';
import { getMlmcreditInfoByPartneridAPI } from 'components/mlmcredit/MlmcreditAPI';
import { setMlmuserList, setMlmuserDashboardMlmuserList } from 'store/slice/DmpSlice';
import { setSelectedTab } from 'store/slice/EcomSlice';

import { openDrawer } from './../../../store/slice/MenuSlice';
import MlmcreditPageImpl from 'components/mlmcredit/page/MlmcreditPageImpl';

export default function MlmuserPageImpl() {
    const dispatch = useDispatch();
    const { mlmuserList} = useSelector((state)=>state.dmpslice);
    const { mlmUserObj } = useSelector((state) => state.ecomslice.mlm);
    
    const getMlmuserListSuccess = (list) =>{dispatch(setMlmuserList(list));}

    const {selectedTab} = useSelector((state)=>state.ecomslice.checkout);
    useEffect(()=>{
        dispatch(setSelectedTab("MY-TEAM"));
    },[])
    useEffect(()=>{

    },[selectedTab])
    useEffect(() => {
        dispatch(openDrawer(false));
        getMlmuserInfoByPartneridAPI(mlmUserObj.userid, ()=>{}, getMlmuserListSuccess);
    },[mlmUserObj]);
    useEffect(()=>{
        setMlmuserDashboardMlmuserList( mlmuserList);
    },[ mlmuserList])
const handleTabClick = (clickedTab) =>{
        if (selectedTab===clickedTab)   return;

        dispatch(setSelectedTab(clickedTab));

    }
    return (
        <>
        <Grid container lg={12} xs={12} justifyContent="space-between" alignItems="center" direction="row" 
            sx={{}}>
            <Grid container lg={4} xs={6} justifyContent="center" 
                sx={{ borderBottom:selectedTab==='MY-TEAM'?'1px solid #003a8c':'',paddingBottom:1,
                        cursor:selectedTab!=='MY-TEAM'?'pointer':'',
                }}>
                <Typography variant={selectedTab==='MY-TEAM'?'h4':'h5'} color={selectedTab==='MY-TEAM'?'primary.dark':'primary.darker'} onClick={()=>handleTabClick('MY-TEAM')}>My Team</Typography>
            </Grid>
            <Grid container lg={4} xs={6} justifyContent="center"  
                sx={{ borderBottom:selectedTab==='CREDIT'?'1px solid #003a8c':'',paddingBottom:1,
                        cursor:selectedTab!=='CREDIT'?'pointer':''}}>
                <Typography variant={selectedTab==='CREDIT'?'h4':'h5'} color={selectedTab==='CREDIT'?'primary.dark':'primary.darker'} onClick={()=>handleTabClick('CREDIT')}>Credit Summary</Typography>
            </Grid>
        </Grid>
        {selectedTab=='MY-TEAM'?< MlmuserDashboard />:<MlmcreditPageImpl/>}
        
        </>
    )

}