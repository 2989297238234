import {useState, useEffect} from 'react';
import { Grid, Button, Typography,TextField, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import { updateUserNameAPI } from 'api/CoreMlmmgmtWebServices';
import { setuserprofileInfo, setApiInProgress,refreshUserProfile } from 'store/slice/HeaderSlice';
import AddressDashboard from '../address/AddressDashboard';
import { getAddressInfoByPartneredAPI } from '../address/AddressAPI';
import { setAddressList, setMlmUserObj } from 'store/slice/EcomSlice';
import { getMlmuserInfoByUseridAPI } from 'components/mlmuser/MlmuserAPI';
import { setMlmuserEdituserModal } from 'store/slice/DmpSlice';
import EdituserActionModal from 'components/mlmuser/action/edituser/EdituserActionModal';
import { setMlmuserDashboardMlmuserList, setSelectedMlmuserId } from 'store/slice/DmpSlice';


export default function ProfileMgmt(){
    const dispatch = useDispatch();
    const {userprofileInfo} = useSelector((state)=>state.headerslice);
    const [editMode, setEditMode] = useState(false);
    const [name, setName] = useState(userprofileInfo.username);
    const [apiInProgress, setApiInProgress] = useState(false);
    const {mlmUserObj} = useSelector((state)=>state.ecomslice.mlm);
    const {edituserModalOpen} = useSelector((state)=>state.dmpslice.mlmuserDashboard);

    useEffect(()=>{
        getAddressInfoByPartneredAPI(userprofileInfo.id,()=>{}, getAddressListSuccess)
        //getMlmuserInfoByUseridAPI(userprofileInfo.userid, ()=>{}, getMlmUserSuccess);
    },[])

    useEffect(()=>{
        if (!edituserModalOpen) getMlmuserInfoByUseridAPI(userprofileInfo.userid, ()=>{}, getMlmUserSuccess);
    },[edituserModalOpen])

    const getMlmUserSuccess = (apiResponse)=>{
        dispatch(setMlmuserDashboardMlmuserList(apiResponse))
        dispatch(setMlmUserObj(apiResponse[0]))
        dispatch(setSelectedMlmuserId(apiResponse[0].id))
    }
    const getAddressListSuccess = (apiResponse) =>{
        dispatch(setAddressList(apiResponse));
    }
    const getAddressListFailure = () =>{
        
    }
    const initiEdit = () =>{
        setEditMode(true);
    }
    
    const setNameValue = (e) => {
        const {name, value} = e.target;
        setName(value);
    }
    const saveNameSuccess = (apiResponse) =>{
        setEditMode(false);
        setApiInProgress(false);
        setName(apiResponse.mlmuserInfo.title);
        dispatch(refreshUserProfile());
    }
    const saveNameFailure = (errMsg) =>{
        setApiInProgress(false);
        setEditMode(false);
        console.log('!! ERROR !! =>'+errMsg);
    }
    const saveName = () =>{
        setApiInProgress(true);
        updateUserNameAPI({
            "userName":name     
            },saveNameSuccess, saveNameFailure
        )
    }
    const editMlmUser = () =>{
        dispatch(setMlmuserEdituserModal(true));
    }
    return (
        <>
        <Grid container direction="column" >
            <Grid item lg={12} xs={12} sx={{padding:.5}}>
                <Typography variant="h4" color="primary.darker">Your Name</Typography>
            </Grid>
            <Grid item lg={12} xs={12} sx={{width:'100%', backgroundColor:'white', padding:2, borderRadius:2}} >
                <Grid container direction="row" justifyContent="space-between" >
            {editMode?<>
                <Grid item lg={11} sx={{width:'80%'}} >
                    <TextField size="large"
                        placeholder="Enter your name"
                        required={true}
                        label="Your Name"
                        name="name"
                        value={name}
                        onChange={setNameValue}
                        sx={{width:'100%'}}
                    />
                </Grid>
                <Grid item lg={1} sx={{width:'20%', paddingLeft:2}} >
                    {apiInProgress?<CircularProgress/>:<Button onClick={()=>{saveName()}}>Save</Button>}
                </Grid>
            </>
            :<>
                <Grid item sx={{width:'95%'}}>
                    <Typography variant="h5" >{userprofileInfo.username}</Typography>
                </Grid>
                <Grid item sx={{width:'5%', cursor:'pointer'}}>
                    <EditIcon onClick ={()=>initiEdit()}/>
                </Grid>
                
            </>}
            </Grid>
            </Grid>
            <Grid item lg={12} xs={12} sx={{padding:.5}}>
                <Typography variant="h4" color="primary.darker">PAN</Typography>
            </Grid>
            {mlmUserObj?
            <Grid item lg={12} xs={12} sx={{width:'100%', backgroundColor:'white', padding:2, borderRadius:2}} >
                <Grid container direction="row" justifyContent="space-between" >
                    <Grid item><Typography variant="h5" >{mlmUserObj.pan}</Typography></Grid>
                    <Grid item sx={{width:'5%', cursor:'pointer'}}>
                        <EditIcon onClick ={()=>editMlmUser()}/>
                    </Grid>
                </Grid>
            </Grid>
            :<></>}
            <Grid item lg={12} xs={12} sx={{height:'300px'}}>
                &nbsp;
            </Grid>
            <Grid item lg={12} xs={12} sx={{padding:.5}}>
                <AddressDashboard/>
            </Grid>
        </Grid>
        <EdituserActionModal/>
        </>
    )
}