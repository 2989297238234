import React from 'react'

import { TextField ,Grid, Typography} from "@mui/material"
import { useSelector, useDispatch } from 'react-redux';
import { setAddressFilterCriteria } from 'store/slice/EcomSlice';

export default function AddressFilterCriteria() {
    const dispatch = useDispatch();
    const {filterCriteria} = useSelector((state)=>state.ecomslice.addressDashboard);
        
    const {stateList} = useSelector((state)=>state.opsslice);    
    const {districtList} = useSelector((state)=>state.opsslice);    
    const {cityList} = useSelector((state)=>state.opsslice);    
    const {addresstypeList} = useSelector((state)=>state.opsslice);
    
const updatePincodeCriteria = (pincode) =>{
        dispatch(setAddressFilterCriteria(
            {
                ...filterCriteria,
                pincode:pincode
        }))
    }

    return (

        <Grid container direction="row" lg={12} sx={{justifyContent:'center'}}>
                    <Grid item lg={4} padding={1}>
                        <TextField type="search" variant="standard"
                            label="Pincode"
                            name="pincode"
                            value={filterCriteria.pincode}
                            onChange={(e)=>updatePincodeCriteria(e.target.value)}
                            sx={{minWidth:'100px'}}
                        />
                    </Grid>


        </Grid>
    )

}