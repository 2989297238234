import {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {Grid, Box,Typography,Divider} from '@mui/material';
import FieldView from 'core/components/layout/FieldView';
import AddressInfo from './AddressInfo';


export default function AddressListTableRow({obj, getActionComponent,handleRowClick}){
  useEffect(()=>{
  },[])
    return (
      
      <>
        <Grid item lg={12} xs={12} sx={{ width: '100%', padding:.1, }}>
          <Grid container direction="row" onClick={() => handleRowClick(obj.id)}
            sx={{
              justifyContent: "flex-start", alignItems: "center",   backgroundColor: obj.expand?'primary.200':'white',
            }}>
            <Grid item lg={11} xs={11} ><AddressInfo obj={obj}/></Grid>
              <Grid item lg={1} xs={1} >
                <Grid container direction="row" sx={{ justifyContent: "flex-end", width: '100%', }}>
                  {getActionComponent(obj)}
                </Grid>
              </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12} sx={{ width: '100%' }}><Divider/></Grid>
      </>
      
    )
}