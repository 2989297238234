import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import { Paper, Typography, Grid } from '@mui/material';
import Notification from '../../core/components/Notification';
import PageWaiting from '../components/PageWaiting';

import {CoreContext} from '../context/CoreContext';


export default function ComponentContainer( {containedComponent}) {

  const { showloading } = React.useContext(CoreContext);
  useEffect(()=>{
  },[showloading])

  return (
    <Paper elevation={3}
    sx={{
      display: 'flex',
  //    m: 1,
      p: .5,
      bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
      color: (theme) =>
        theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
      border: '1px solid',
      borderColor: (theme) =>
        theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
      borderRadius: 2,
      fontSize: '0.875rem',
      fontWeight: '700',
      width:'100%',
      alignContent:'center',
      justifyContent:'center',
      //marginLeft:'3%'
    }}
  >
      <Grid container direction="column">
      {containedComponent}
      </Grid>
    </Paper>
  );
}
