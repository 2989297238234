import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";

import Alert from '@mui/material/Alert';
import { Button, Divider,TextField, Grid, Typography, Box,CircularProgress } from "@mui/material"

import {getMlmcreditStruct, setData} from './MlmcreditStruct'
import { updateTextValue, handleSubmit,updateValue,updateSelectedValue} from '../../core/js/FormUtils'
import {createAPI, updateAPI, getAPI, updateEditableAPI} from './MlmcreditAPI';
import {CoreContext} from "../../core/context/CoreContext";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {validMobileNumberEntry, validNameEntry, validEmailEntry} from '../../core/js/FormValidation';

import {getPathVariable} from '../../core/js/UrlUtils';
import { useSearchParams } from "react-router-dom";
import RadioForBoolean from '../../core/form/RadioForBoolean';
import MyDate from '../../core/components/MyDate';
import { useDispatch,useSelector } from 'react-redux';
import { updateMlmcreditObj,addMlmcreditObj, setShowEditForMlmcredit} from 'store/slice/DmpSlice';
import { setApiInProgress } from 'store/slice/HeaderSlice';



export default function MlmcreditEditForm(){
    const dispatch = useDispatch();
    const { setNotification, lastURL } = React.useContext(CoreContext);
    const [formValues, setFormValues] = useState(getMlmcreditStruct);
    const [showError, setShowError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('Something went wrong');
    const [dataLoaded, setDataLoaded] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    
    const {mlmcreditDashboard} = useSelector((state)=>state.dmpslice);
    const [mlmcreditId, setMlmcreditId] = useState(mlmcreditDashboard.selectedId);
    const { businessid } = useSelector((state) => state.headerslice.userprofileInfo)
        
    const {mlmuserList} = useSelector((state)=>state.dmpslice);
    const handleDynamicSelectedValueFormlmuserid = (e) =>{
        updateSelectedValue(formValues, setFormValues,e, 
            'mlmusername', e.target.value==0?'Select Item':mlmuserList.find(obj => obj.id === e.target.value).title);
    }    
    const handleDynamicSelectedValueForcreditviaid = (e) =>{
        updateSelectedValue(formValues, setFormValues,e, 
            'creditvianame', e.target.value==0?'Select Item':mlmuserList.find(obj => obj.id === e.target.value).title);
    }

    const {apiInProgress} = useSelector((state)=>state.headerslice)
    useEffect(()=>{
    },[apiInProgress])

    useEffect(() => {
        if (mlmcreditId!==-1) {
            if (dataLoaded) return;
            getAPI(mlmcreditId, loadForm);
        }
        else {
           // formValues.<<CONTEXT>>.value=searchParams.get('<<CONTEXT>>');
            setDataLoaded(true);
        }

        setFormValues({
            ...formValues,
            ['businessid']: {
                ...formValues['businessid'],
                value: businessid
            }
        })
    },[]);

    const loadForm = (data)=>{
        setData(data, formValues, setFormValues);
        setDataLoaded(true);
    }
    const handleRadioChange = (name, value) => {
        setShowError(false);
        console.log(value+" == "+getRadioAPIvalue(value))
        updateValue(formValues, setFormValues, name, getRadioAPIvalue(value));
    }
    const getRadioValue = (value) => {
        if (value ) 
            return "yes"
        else 
            return "no"
    }
    const getRadioAPIvalue = (value) => {
        if (value ==="yes") 
            return true
        else 
            return false
    }
    const handleTextChange = (e) => {
        setShowError(false);
        updateTextValue(formValues, setFormValues,e);
    }
    const handleDoubleChange = (e) => {
        setShowError(false);
        const parsed = parseFloat(e.target.value);
        if (!isNaN(parsed) && e.target.value == parsed)
         updateTextValue(formValues, setFormValues,e);
    }
    const handleNameChange = (e) =>{
        setShowError(false);
        
       if (validNameEntry(e))
        updateTextValue(formValues, setFormValues,e);
    }
    const handleMobileChange = (e) => {
        setShowError(false);
        
       if (validMobileNumberEntry(e))
            updateTextValue(formValues, setFormValues,e);
    }
    const handleEmailidChange = (e) => {
        setShowError(false);
        
       if (validEmailEntry(e))
            updateTextValue(formValues, setFormValues,e);
    }

    const createSuccessCallback = (obj) =>{
         dispatch(setApiInProgress(false));
        dispatch(addMlmcreditObj(obj));
        setNotification('Record has been cretaed successfully !!!');
        dispatch(setShowEditForMlmcredit(false));
    }
    const updateSuccessCallback = (obj) =>{
         dispatch(setApiInProgress(false));
        dispatch(updateMlmcreditObj(obj));
        setNotification('Record with id['+mlmcreditId+'] has been updated successfully !!!');
        dispatch(setShowEditForMlmcredit(false));
        
    }
    const failuerCallback = (errorMsg) => {
         dispatch(setApiInProgress(false));
        setErrorMsg(errorMsg);
        setShowError(true);
    }
    const callCreateAPI = (formValues, successCallBack, failuerCallback) =>{
        dispatch(setApiInProgress(true));
        createAPI(formValues, successCallBack, failuerCallback);
    }
    const callUpdateAPI = (id,formValues, updateSuccessCallback, failuerCallback) =>{
        dispatch(setApiInProgress(true));
        updateAPI(id,formValues, updateSuccessCallback, failuerCallback);
    }
    const onSubmit=(e)=>{
        handleSubmit(e,mlmcreditId, formValues, setFormValues, createSuccessCallback, updateSuccessCallback, failuerCallback, callCreateAPI, callUpdateAPI);
    }
    const setDate = (fieldName, value) => {
        var date = new Date(value);
        
        const day = date.getDate();
        const dayStr = day < 10 ? '0' + day : day.toString();

        const month = date.getMonth() + 1;
        const monthStr = month < 10 ? '0' + month : month.toString();
        var dateStr =date.getFullYear()+ "-" +  monthStr + "-" + dayStr + "T00:00:00.000Z";
        updateValue(formValues, setFormValues, fieldName, dateStr);
    }
    return (
        <form  noValidate onSubmit={onSubmit} >
            <Grid container direction="column" padding={.25}>
                {showError? <Alert severity="error">{errorMsg}</Alert>: ''}
                <Box sx={{ flexGrow: 1 }}>
                    <Grid item sx={{overflowY: "scroll", backgroundColor:'white',maxHeight:'58vh'}}>
                        <Grid container direction="column" padding={.25}>
                            <Grid item>
                                <Typography variant='h4'>{mlmcreditId===-1?'Add':'Edit'} Credit Transaction </Typography>
                                <Divider/>
                            </Grid>
                            <Grid height='25px' ></Grid>
                            {dataLoaded?
                                <Grid container display="flex" spacing={2} direction="row" justifyContent="space-between" alignItems="center"> 
                                                            
                            <Grid item lg={4} xs={12}>
                                <TextField size="Normal"
                                    placeholder={formValues.title.placeholder}
                                    required={formValues.title.required}
                                    label={formValues.title.label}
                                    name={formValues.title.name}
                                    value={formValues.title.value}
                                    onChange={handleTextChange}
                                    error={formValues.title.error}
                                    helperText={formValues.title.error && formValues.title.errorMessage}
                                    sx={{width:'100%'}}
                                />
                            </Grid>
                                            
                            <Grid item lg={4} xs={12}>
                                <TextField size="Normal"
                                    placeholder={formValues.orderid.placeholder}
                                    required={formValues.orderid.required}
                                    label={formValues.orderid.label}
                                    name={formValues.orderid.name}
                                    value={formValues.orderid.value}
                                    onChange={handleTextChange}
                                    error={formValues.orderid.error}
                                    helperText={formValues.orderid.error && formValues.orderid.errorMessage}
                                    sx={{width:'100%'}}
                                />
                            </Grid>
                                            
                            <Grid item lg={4} xs={12}>
                                <TextField size="Normal"
                                    placeholder={formValues.creditlevel.placeholder}
                                    required={formValues.creditlevel.required}
                                    label={formValues.creditlevel.label}
                                    name={formValues.creditlevel.name}
                                    value={formValues.creditlevel.value}
                                    onChange={handleTextChange}
                                    error={formValues.creditlevel.error}
                                    helperText={formValues.creditlevel.error && formValues.creditlevel.errorMessage}
                                    sx={{width:'100%'}}
                                />
                            </Grid>
                                            
                            <Grid item lg={4} xs={12}>
                                <TextField size="Normal"
                                    placeholder={formValues.creditvalue.placeholder}
                                    required={formValues.creditvalue.required}
                                    label={formValues.creditvalue.label}
                                    name={formValues.creditvalue.name}
                                    value={formValues.creditvalue.value}
                                    onChange={handleDoubleChange}
                                    error={formValues.creditvalue.error}
                                    helperText={formValues.creditvalue.error && formValues.creditvalue.errorMessage}
                                    sx={{width:'100%'}}
                                />
                            </Grid>
                                            
                            <Grid item lg={4} xs={12}>
                                <TextField size="Normal"
                                    placeholder={formValues.srpartnerid.placeholder}
                                    required={formValues.srpartnerid.required}
                                    label={formValues.srpartnerid.label}
                                    name={formValues.srpartnerid.name}
                                    value={formValues.srpartnerid.value}
                                    onChange={handleTextChange}
                                    error={formValues.srpartnerid.error}
                                    helperText={formValues.srpartnerid.error && formValues.srpartnerid.errorMessage}
                                    sx={{width:'100%'}}
                                />
                            </Grid>
                                            
                            <Grid item lg={4} xs={12}>
                                <TextField size="Normal"
                                    placeholder={formValues.srpartnername.placeholder}
                                    required={formValues.srpartnername.required}
                                    label={formValues.srpartnername.label}
                                    name={formValues.srpartnername.name}
                                    value={formValues.srpartnername.value}
                                    onChange={handleTextChange}
                                    error={formValues.srpartnername.error}
                                    helperText={formValues.srpartnername.error && formValues.srpartnername.errorMessage}
                                    sx={{width:'100%'}}
                                />
                            </Grid>
                                            
                            <Grid item lg={4} xs={12}>
                                <TextField size="Normal"
                                    placeholder={formValues.ordervalue.placeholder}
                                    required={formValues.ordervalue.required}
                                    label={formValues.ordervalue.label}
                                    name={formValues.ordervalue.name}
                                    value={formValues.ordervalue.value}
                                    onChange={handleDoubleChange}
                                    error={formValues.ordervalue.error}
                                    helperText={formValues.ordervalue.error && formValues.ordervalue.errorMessage}
                                    sx={{width:'100%'}}
                                />
                            </Grid>
                                   
                            <Grid item lg={4} xs={12}>
                                <FormControl sx={{  width:'100%'}} size="Normal">
                                    <InputLabel id="demo-simple-select-autowidth-label">{formValues.mlmuserid.label}</InputLabel>
                                    <Select
                                            labelId="demo-simple-select-autowidth-label"
                                            id="demo-simple-select-autowidth"
                                            label={formValues.mlmuserid.label}
                                            name={formValues.mlmuserid.name}
                                            value={formValues.mlmuserid.value}
                                            onChange={handleDynamicSelectedValueFormlmuserid}
                                            
                                            error={formValues.mlmuserid.error}
                                            helpertext={formValues.mlmuserid.error && formValues.mlmuserid.errorMessage}
                                            >
                                            <MenuItem key={0} value="0">Select Item</MenuItem>
                                            {
                                                mlmuserList && mlmuserList.map((item, i) => <MenuItem key={i} value={item.id}>{item.title}</MenuItem>) 
                                            }
                                        </Select>
                                </FormControl> 
                            </Grid>                        
                            <Grid item lg={4} xs={12}>
                                <TextField size="Normal"
                                    placeholder={formValues.mlmusername.placeholder}
                                    required={formValues.mlmusername.required}
                                    label={formValues.mlmusername.label}
                                    name={formValues.mlmusername.name}
                                    value={formValues.mlmusername.value}
                                    onChange={handleTextChange}
                                    error={formValues.mlmusername.error}
                                    helperText={formValues.mlmusername.error && formValues.mlmusername.errorMessage}
                                    sx={{width:'100%'}}
                                />
                            </Grid>
                                   
                            <Grid item lg={4} xs={12}>
                                <FormControl sx={{  width:'100%'}} size="Normal">
                                    <InputLabel id="demo-simple-select-autowidth-label">{formValues.creditviaid.label}</InputLabel>
                                    <Select
                                            labelId="demo-simple-select-autowidth-label"
                                            id="demo-simple-select-autowidth"
                                            label={formValues.creditviaid.label}
                                            name={formValues.creditviaid.name}
                                            value={formValues.creditviaid.value}
                                            onChange={handleDynamicSelectedValueForcreditviaid}
                                            
                                            error={formValues.creditviaid.error}
                                            helpertext={formValues.creditviaid.error && formValues.creditviaid.errorMessage}
                                            >
                                            <MenuItem key={0} value="0">Select Item</MenuItem>
                                            {
                                                mlmuserList && mlmuserList.map((item, i) => <MenuItem key={i} value={item.id}>{item.title}</MenuItem>) 
                                            }
                                        </Select>
                                </FormControl> 
                            </Grid>                        
                            <Grid item lg={4} xs={12}>
                                <TextField size="Normal"
                                    placeholder={formValues.creditvianame.placeholder}
                                    required={formValues.creditvianame.required}
                                    label={formValues.creditvianame.label}
                                    name={formValues.creditvianame.name}
                                    value={formValues.creditvianame.value}
                                    onChange={handleTextChange}
                                    error={formValues.creditvianame.error}
                                    helperText={formValues.creditvianame.error && formValues.creditvianame.errorMessage}
                                    sx={{width:'100%'}}
                                />
                            </Grid>
                        
                                    <Grid item lg={4} xs={12} sx={{display:'none'}}>
                                        <TextField size="Normal"
                                            placeholder={formValues.businessid.placeholder}
                                            required={formValues.businessid.required}
                                            label={formValues.businessid.label}
                                            name={formValues.businessid.name}
                                            value={formValues.businessid.value}
                                            onChange={handleTextChange}
                                            error={formValues.businessid.error}
                                            helpertext={formValues.businessid.error && formValues.businessid.errorMessage}
                                            sx={{ width: '100%',  }}
                                        />
                                    </Grid> 
                                </Grid>
                            :<></>}
                        </Grid>
                    </Grid>
                </Box>
                <Grid height='25px' ></Grid>
                <Grid padding={1}>
                    <Divider/>
                </Grid>
                <Grid container direction="row" justifyContent="flex-end"  >
                {   apiInProgress?<CircularProgress/>:
                    <>
                        <Button variant="outlined" type="submit" >{mlmcreditId===-1?'Add':'Save'}</Button>&nbsp;&nbsp;&nbsp;
                        <Button variant="outlined" onClick={()=>{dispatch(setShowEditForMlmcredit(false));}}>Close</Button>
                    </>
                }
                </Grid>
            </Grid>
        </form>
    );
};