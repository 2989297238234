import { createSlice } from '@reduxjs/toolkit';

export const OrderMgmtSlice = createSlice({
    name: "ordermgmtslice",
    initialState: {
        partyObj:{},
        mode:'ADD-ORDER',
        refreshPartyList:false,
        refreshProductList:false,
		mfpartyDashboard:{
            mfpartyObj:{},
			SelectPartyModalOpen:false,
        },
        orderObj:{},
        orderExpanded:true
    },
    reducers: {
        setMode : (state, action) =>{
            state.mode=action.payload;
        },
        refreshPartyList: (state, action) =>{
            state.refreshPartyList = !state.refreshPartyList;
        },
        refreshProductList: (state, action) =>{
            state.refreshProductList = !state.refreshPartyList;
        },
        setOrderObj: (state, action) =>{
            state.orderObj = action.payload;
        },
        setPartyObj: (state, action) =>{
            state.partyObj = action.payload;
        },
        setOrderExpanded: (state, action) =>{
            state.orderExpanded = action.payload;
        },

        /* Mfparty Mgmt - STARTS */
        setMfpartyObj: (state, action)=>{
            state.mfpartyDashboard.mfpartyObj=action.payload;
        },
        addMfpartyObj: (state, action)=>{
            state.mfpartyList.unshift(action.payload);
            state.mfpartyDashboard.mfpartyList.unshift(action.payload);

            state.mfpartyDashboard.mfpartyObj=action.payload;
            state.mfpartyDashboard.selectedId=action.payload.id;
        },
        updateMfpartyObj: (state, action)=>{
            let index = state.mfpartyList.map(mfpartyObj=>mfpartyObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.mfpartyList[index]=action.payload;
            }

            index = state.mfpartyDashboard.mfpartyList.map(mfpartyObj=>mfpartyObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.mfpartyDashboard.mfpartyList[index]=action.payload;
            }
            if (state.mfpartyDashboard.mfpartyObj!==null){
                state.mfpartyDashboard.mfpartyObj = action.payload;
            }
        },
        deleteMfpartyObj: (state, action)=>{
            state.mfpartyList.splice(state.mfpartyList.findIndex(a => a.id === action.payload) , 1);
            state.mfpartyDashboard.mfpartyList.splice(state.mfpartyDashboard.mfpartyList.findIndex(a => a.id === action.payload) , 1);
            state.mfpartyDashboard.productObj={}
            state.mfpartyDashboard.selectedId=-1
        },
        setSelectedMfpartyId: (state, action)=>{
            if (action.payload!==-1){
                state.mfpartyDashboard.selectedId=action.payload;
                state.mfpartyDashboard.mfpartyObj = state.mfpartyList[
                    state.mfpartyList.findIndex(mfpartyObj=>mfpartyObj.id===parseInt(action.payload))
            ];
            } else {
                state.mfpartyDashboard.selectedId=action.payload;
                state.mfpartyDashboard.mfpartyObj ={}
            }
        },
        setMfpartyList: (state, action) =>{
            state.mfpartyList=action.payload;
            state.mfpartyDashboard.mfpartyList=action.payload;
        },
        setMfpartyDashboardMfpartyList: (state, action) =>{
            state.mfpartyDashboard.mfpartyList=action.payload;
        },
      
        setMfpartySelectPartyModal:(state,action)=>{
          state.mfpartyDashboard.SelectPartyModalOpen=action.payload;
        },

        /* Mfparty Mgmt - END */

    }
});
export const { refreshProductList, refreshPartyList,setMode, setOrderObj,setPartyObj,setOrderExpanded,

    /* Mfparty Mgmt - STARTS */
    setMfpartyObj,
    setMfpartyList,getMfpartyList,updateMfpartyObj,addMfpartyObj,deleteMfpartyObj,
    setSelectedMfpartyId,
    setMfpartyDashboardMfpartyList,
    setShowEditForMfparty,
    setMfpartyFilterCriteria,
    setMfpartySelectPartyModal,
    /* Mfparty Mgmt - END */


} = OrderMgmtSlice.actions;
export default OrderMgmtSlice.reducer;