import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import ProductDashboard from '../ProductDashboard';
import { getListAPI as getProductList } from 'components/product/ProductAPI';
import { setProductList, setProductDashboardProductList } from 'store/slice/DmpSlice';

import { openDrawer } from './../../../store/slice/MenuSlice';

export default function ProductPageImpl() {
    const dispatch = useDispatch();
    const { productList} = useSelector((state)=>state.dmpslice);
    
    const getProductListSuccess = (list) =>{dispatch(setProductList(list));}
    useEffect(() => {
        //dispatch(openDrawer(false));
        getProductList(()=>{}, getProductListSuccess);
    },[]);
    useEffect(()=>{
        setProductDashboardProductList( productList);
    },[ productList])

    return (
        < ProductDashboard />
    )

}