import {useState, useEffect} from 'react';
import { Grid, Button, Typography } from '@mui/material';
import LoginContainer from 'pages/authentication/components/LoginContainer';
import MobileLogin from './MobileLogin';

export default function LoginActionImpl(){
    const [selectedTab, setSelectedTab] = useState('MOBILE');
    return (
        <Grid container direction="row" sx={{width:'100%'}}>
            <Grid item lg={12} xs={12} >
                <Grid container sx={{padding:1}} justifyContent="space-between">
                    <Grid container justifyContent="center" xs={6} sx={{
                        cursor:selectedTab==="MOBILE"?'':'pointer',
                        borderBottom:selectedTab==="MOBILE"?'1px solid blue':'',
                        color:selectedTab==="MOBILE"?'primary.dark':'primary.darker'}} 
                        onClick={()=>setSelectedTab('MOBILE')}>
                        <Typography variant="h5" color="primary.darker">Mobile Login</Typography>
                    </Grid>
                    <Grid xs={6} container justifyContent="center"  sx={{
                        cursor:selectedTab==="EMAIL"?'':'pointer',
                        borderBottom:selectedTab==="EMAIL"?'1px solid blue':'',
                        color:selectedTab==="EMAIL"?'primary.dark':'primary.darker',
                        }} onClick={()=>setSelectedTab('EMAIL')}>
                        <Typography variant="h5" >Email Login</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item lg={12} xs={12} >
                {selectedTab==="MOBILE"?<MobileLogin/>:<LoginContainer/>}
            </Grid>
        </Grid>
    )
}