import React, { useState, useEffect } from 'react'

import Grid from '@mui/material/Grid';

import ProductcategoryFilterCriteria from './ProductcategoryFilterCriteria'
import ProductcategoryList from './ProductcategoryList'
import MyPageContainer from '../../core/template/MyPageContainer';
import {getListAPI} from './ProductcategoryAPI';
import {transformToCardFormat} from '../../core/js/FormUtils'
import {getPathVariable} from '../../core/js/UrlUtils';

import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {setProductcategoryDashboardProductcategoryList } from '../../store/slice/DmpSlice';

export default function ProductcategoryListView({setShowEdit,setProductcategoryId}){
    const {filterCriteria} = useSelector((state)=>state.dmpslice.productcategoryDashboard);   

    const [filteredProductcategoryList, setFilteredProductcategoryList] = React.useState([]);
    const [cardList, setCardList] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const { showfilter } = useSelector((state) => state.confslice);
    const { productcategoryDashboard } = useSelector((state) => state.dmpslice);
    
    function dataFetchCallBack(){
    }

    useEffect(()=>{
      if (productcategoryDashboard.productcategoryList.length>-1){
        setFilteredProductcategoryList(productcategoryDashboard.productcategoryList);
        transformToCardFormat(productcategoryDashboard.productcategoryList, setCardList);
      }
    },[productcategoryDashboard.productcategoryList])

    useEffect(()=>{
          transformToCardFormat(filteredProductcategoryList, setCardList);
      },[filteredProductcategoryList])

    useEffect(()=>{
        if (filterCriteria.agency==='' && filterCriteria.title==='' && filterCriteria.productid==='' && filterCriteria.categoryid==='' ){
            setFilteredProductcategoryList(productcategoryDashboard.productcategoryList);
            transformToCardFormat(productcategoryDashboard.productcategoryList, setCardList);

        } else {
            setFilteredProductcategoryList(
                productcategoryDashboard.productcategoryList
                    .filter(productcategory=> productcategory.title.toString().toUpperCase().includes(filterCriteria.title.toString().toUpperCase()))

                
            )
            

            //transformToCardFormat(productcategoryDashboard.filteredProductcategoryList, setCardList);
        }
      },[filterCriteria])
  
     return(
        <Grid container direction="column" >
            <Grid item lg={12}>
                <ProductcategoryList
                    setShowEdit={setShowEdit}
                    setProductcategoryId = {setProductcategoryId}
                    tableList={filteredProductcategoryList}
                    cardList={cardList}
                    />
          </Grid>
      </Grid>
    )
}