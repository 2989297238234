import { v4 as uuidv4 } from 'uuid';
import { callAPI,callAPIwithoutJWT, callGetAPI,callGetWithoutJWTAPI } from 'api/ApiUtils';
import {getOPScoreAPIPrefix, getOPSserviceAPIPrefix, getOPSAnonymousServiceAPIPrefix} from 'api/ApiUtils';

 var ProductcartitemResourcePrefix = getOPScoreAPIPrefix()+"/productcartitem";
 var opsCoreWebServicesPrefix = getOPSserviceAPIPrefix();
 var ProductsResourcePrefix = getOPSAnonymousServiceAPIPrefix()+"/products";
 var OpsCorePrefix = getOPScoreAPIPrefix();

 export function getAnonymousCartAPI(payload, successCallBack, failuerCallback){
    callAPIwithoutJWT(opsCoreWebServicesPrefix+"/anonymous/anonymouscart","POST", payload, successCallBack, failuerCallback); 
    //callAPI("http://localhost:8082/ops-core-service/anonymouscart","POST", payload, successCallBack, failuerCallback); 
 }
 export function deleteCartItemAPI(id, deleteCallBack){
    const token = localStorage.getItem("JWT");
    const requestId = uuidv4().split("-")[4];
    const requestOptions = {
      method: 'DELETE',
      headers: {'X-Request-ID': requestId,'Authorization': `Bearer ${token}`}
    };
    fetch(ProductcartitemResourcePrefix+"/"+id, requestOptions)
        .then((data) => data.json())
        .then((data) => {
            deleteCallBack(data);
        })
  }
  export function updateQuantityAPI(payload, successCallBack, failuerCallback){
    callAPI(opsCoreWebServicesPrefix+"/cartitem","PUT", payload, successCallBack, failuerCallback); 
}
export function getProductInfoByBusinessidAPI(businessid, setProductList, dataFetchCallBack){
    callGetWithoutJWTAPI(ProductsResourcePrefix+"/businessid/"+businessid+"/businessid", setProductList, dataFetchCallBack, ()=>{});
}
export function getOrderListByCustomerIdAPI(customerId, setOrderList, dataFetchCallBack){

    callGetAPI(OpsCorePrefix+"/ordrs/customerid/"+customerId+"/customerid", setOrderList, dataFetchCallBack, ()=>{});
    
}

export function updateCartAddressAPI(payload, successCallBack, failuerCallback){
    callAPI(opsCoreWebServicesPrefix+"/cartaddress","PUT", payload, successCallBack, failuerCallback); 
}
export function addOrderAPI(payload, successCallBack, failuerCallback){
    callAPI(opsCoreWebServicesPrefix+"/order","POST", payload, successCallBack, failuerCallback); 
}
export function updateCartCustomerAPI(payload, successCallBack, failuerCallback){
   callAPI(opsCoreWebServicesPrefix+"/cartcustomer","PUT", payload, successCallBack, failuerCallback); 
}
export function getProductsByCartIdAPI(cartid, successCallBack, failuerCallback){
    callAPI(OpsCorePrefix+"/productcartitems/cartid/"+cartid+"/cartid","GET",{},successCallBack, failuerCallback); 
 }