import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Button, Typography, Box, Divider } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import SuccessMessage from 'core/components/SuccessMessage';
import { addMlmuserObj, setMlmuserOnboardModal, setMlmuserTandcModal } from 'store/slice/DmpSlice';


export default function MemberRegistrationDone() {
    const dispatch = useDispatch();
    const { inviteeMobileNumber, partnerObj, inviteeObj } = useSelector((state) => state.ecomslice.mlm);
    const close = () =>{
        dispatch(setMlmuserOnboardModal(false));
    }
    return (
        <>
            <Grid container sx={{ paddingTop: 5, paddingBottom: 5 }} justifyContent="center" alignItems="center">
                <SuccessMessage message="Membership Registration is complete" />
            </Grid>
            <Grid container justifyContent="center" alignItems="center" sx={{ backgroundColor: 'white', padding: 1, borderRadius: 2 }}>
                <Grid item >
                    <Typography variant="h6">
                        Thank you <Box component="span" sx={{ color: "primary.main", fontWeight: "bold", fontSize: 18 }}>{inviteeObj.title}</Box> for being Member of G&N MLM Program.
                    </Typography>
                    <Typography sx={{ paddingTop: 1 }}>
                        Enjoy the 20% flat discount on your purchase at GnR Platform.
                    </Typography>
                </Grid>

            </Grid>
            <Grid container justifyContent="center" alignItems="center" sx={{ paddingTop: 5 }}>
                <Grid item>
                    <Button variant="contained" onClick={() => close()}>Product List</Button>
                </Grid>
            </Grid>

        </>
    )
}