import { v4 as uuidv4 } from 'uuid';
var dmpCoreWebServicesPrefix = "https://appmachine.in/dmp-core-service";

export function bePartnerAPI(payload, successCallBack, failuerCallback){
    const token = localStorage.getItem("JWT");
    const requestId = uuidv4().split("-")[4];
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json','X-Request-ID': requestId,'Authorization': `Bearer ${token}` },
      body: JSON.stringify(payload)
    };
    fetch(dmpCoreWebServicesPrefix+"/mlmpartner", requestOptions)
        .then(function(response) {
            if(response.ok)
            {
                return response.json();         
            }
            throw new Error('Something went wrong.');
        })
        .then((response) => {
            let errorMessage = "Something Went Wrong"
                if (response.status===208){
                    errorMessage = "Record already exist";
                    failuerCallback(errorMessage);
                } else if (500 === response.status) {
                    errorMessage='Internal Server Error';
                    failuerCallback(errorMessage);
                } else {
                    successCallBack(response);
                }
        })
        .catch(function(error) { 
            console.log('Request failed', error);
            failuerCallback('Internal Server Error');
        });
}export function addMemberAPI(payload, successCallBack, failuerCallback){
    const token = localStorage.getItem("JWT");
    const requestId = uuidv4().split("-")[4];
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json','X-Request-ID': requestId,'Authorization': `Bearer ${token}` },
      body: JSON.stringify(payload)
    };
    fetch(dmpCoreWebServicesPrefix+"/mlmmember", requestOptions)
        .then(function(response) {
            if(response.ok)
            {
                return response.json();         
            }
            throw new Error('Something went wrong.');
        })
        .then((response) => {
            let errorMessage = "Something Went Wrong"
                if (response.status===208){
                    errorMessage = "Record already exist";
                    failuerCallback(errorMessage);
                } else if (500 === response.status) {
                    errorMessage='Internal Server Error';
                    failuerCallback(errorMessage);
                } else {
                    successCallBack(response);
                }
        })
        .catch(function(error) { 
            console.log('Request failed', error);
            failuerCallback('Internal Server Error');
        });
}export function placeOrderAPI(payload, successCallBack, failuerCallback){
    const token = localStorage.getItem("JWT");
    const requestId = uuidv4().split("-")[4];
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json','X-Request-ID': requestId,'Authorization': `Bearer ${token}` },
      body: JSON.stringify(payload)
    };
    fetch(dmpCoreWebServicesPrefix+"/mlmorder", requestOptions)
        .then(function(response) {
            if(response.ok)
            {
                return response.json();         
            }
            throw new Error('Something went wrong.');
        })
        .then((response) => {
            let errorMessage = "Something Went Wrong"
                if (response.status===208){
                    errorMessage = "Record already exist";
                    failuerCallback(errorMessage);
                } else if (500 === response.status) {
                    errorMessage='Internal Server Error';
                    failuerCallback(errorMessage);
                } else {
                    successCallBack(response);
                }
        })
        .catch(function(error) { 
            console.log('Request failed', error);
            failuerCallback('Internal Server Error');
        });
}export function getMLMuserAPI(payload, successCallBack, failuerCallback){
    const token = localStorage.getItem("JWT");
    const requestId = uuidv4().split("-")[4];
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json','X-Request-ID': requestId,'Authorization': `Bearer ${token}` },
      body: JSON.stringify(payload)
    };
    fetch(dmpCoreWebServicesPrefix+"/mlmuser", requestOptions)
        .then(function(response) {
            if(response.ok)
            {
                return response.json();         
            }
            throw new Error('Something went wrong.');
        })
        .then((response) => {
            let errorMessage = "Something Went Wrong"
                if (response.status===208){
                    errorMessage = "Record already exist";
                    failuerCallback(errorMessage);
                } else if (500 === response.status) {
                    errorMessage='Internal Server Error';
                    failuerCallback(errorMessage);
                } else {
                    successCallBack(response);
                }
        })
        .catch(function(error) { 
            console.log('Request failed', error);
            failuerCallback('Internal Server Error');
        });
}export function inviteeLoginSuccessAPI(payload, successCallBack, failuerCallback){
    const token = localStorage.getItem("JWT");
    const requestId = uuidv4().split("-")[4];
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json','X-Request-ID': requestId,'Authorization': `Bearer ${token}` },
      body: JSON.stringify(payload)
    };
    fetch(dmpCoreWebServicesPrefix+"/inviteeloginsuccess", requestOptions)
        .then(function(response) {
            if(response.ok)
            {
                return response.json();         
            }
            throw new Error('Something went wrong.');
        })
        .then((response) => {
            let errorMessage = "Something Went Wrong"
                if (response.status===208){
                    errorMessage = "Record already exist";
                    failuerCallback(errorMessage);
                } else if (500 === response.status) {
                    errorMessage='Internal Server Error';
                    failuerCallback(errorMessage);
                } else {
                    successCallBack(response);
                }
        })
        .catch(function(error) { 
            console.log('Request failed', error);
            failuerCallback('Internal Server Error');
        });
}export function updateUserNameAPI(payload, successCallBack, failuerCallback){
    const token = localStorage.getItem("JWT");
    const requestId = uuidv4().split("-")[4];
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json','X-Request-ID': requestId,'Authorization': `Bearer ${token}` },
      body: JSON.stringify(payload)
    };
    fetch(dmpCoreWebServicesPrefix+"/username", requestOptions)
        .then(function(response) {
            if(response.ok)
            {
                return response.json();         
            }
            throw new Error('Something went wrong.');
        })
        .then((response) => {
            let errorMessage = "Something Went Wrong"
                if (response.status===208){
                    errorMessage = "Record already exist";
                    failuerCallback(errorMessage);
                } else if (500 === response.status) {
                    errorMessage='Internal Server Error';
                    failuerCallback(errorMessage);
                } else {
                    successCallBack(response);
                }
        })
        .catch(function(error) { 
            console.log('Request failed', error);
            failuerCallback('Internal Server Error');
        });
}