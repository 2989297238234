import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Grid, Box, Typography, Divider } from '@mui/material';
import { convertToRS, convertToRSWithTwoDec } from 'core/js/FormUtils';


export default function MlmcreditListTableRow({ obj }) {
  const { mobileView } = useSelector((state) => state.menu);
  useEffect(() => {
  }, [])
  const getHoverColor = () => {
    if (mobileView) return ''
    return 'primary.main';
  }
  const getHoverBackgroundColor = () => {
    if (mobileView) return ''
    return 'secondary.200';
  }
  const getBackgroundColor = (obj) => {
    if (mobileView) {
      if (obj.expand) return 'secondary.100'
      return 'secondary.100'
    }
    return 'secondary.100'
  }
  return (

    <>
      <Grid item lg={12} sx={{ width: '100%' }}>
        <Grid container direction="row"
          sx={{
            justifyContent: "flex-start", alignItems: "center", padding: 1,
            backgroundColor: getBackgroundColor(obj),
            color: mobileView && obj.expand ? 'primary.main' : 'primary.darker',
            '&:hover': {
              backgroundColor: getHoverBackgroundColor,
              color: getHoverColor()
            },
          }}>
          <Grid item lg={2} xs={2} ><Typography color="" sx={{ fontWeight: 'light', fontSize: 15 }}>{obj.orderid}</Typography></Grid>

          <Grid item lg={4} xs={4} ><Typography color="" sx={{ fontWeight: 'light', fontSize: 15 }}>{obj.mlmusername}</Typography></Grid>
          <Grid item lg={2} xs={1} ><Typography color="" sx={{ fontWeight: 'light', fontSize: 15 }}>{obj.creditlevel}</Typography></Grid>
          <Grid item lg={2} xs={3} ><Typography color="" sx={{ fontWeight: 'light', fontSize: 15 }}>{((obj.creditvalue / obj.ordervalue) * 100).toFixed(2)}%</Typography></Grid>
          <Grid item lg={2} xs={2} ><Typography color="" sx={{ fontWeight: 'light', fontSize: 15 }}>{convertToRSWithTwoDec(obj.creditvalue)}</Typography></Grid>
          <Grid item sx={{ width: '100%' }}>
            <Divider sx={{ backgroundColor: "#C4C4C4" }} />
          </Grid>

        </Grid>
      </Grid>
    </>

  )
}