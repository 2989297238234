import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import { openDrawer } from 'store/slice/MenuSlice';
import MlmorderPageImpl from './MlmorderPageImpl';

export default function MlmorderPage() {
    const dispatch = useDispatch();
    useEffect(() => {
        //dispatch(openDrawer(false));
    },[]);

    return (
        < MlmorderPageImpl />
    )

}