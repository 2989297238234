import {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {Grid, Box,Typography,Divider} from '@mui/material';
import FieldView from 'core/components/layout/FieldView';
import MlmcreditInfo from '../MlmcreditInfo';
import { convertToRS, convertToRSWithTwoDec } from 'core/js/FormUtils';
import MlmOrderDetails from './MlmOrderDetails';


export default function MlmcreditListTableRow({obj, getActionComponent,handleRowClick}){
  const {mobileView} = useSelector((state)=>state.menu);
  useEffect(()=>{
  },[])
  const getHoverColor = () =>{
    if (mobileView) return ''
    return 'primary.main';
  }
  const getHoverBackgroundColor = () =>{
    if (mobileView) return ''
    return 'secondary.200';
  }
  const getBackgroundColor = (obj) =>{
    //return 'white'
    //if (mobileView) {
      if (obj.expand) return 'secondary.100'
      return 'white'
    //}
    //return 'white'
  }
    return (
      
      <>
        <Grid item lg={12} sx={{ width: '100%', }}>
          {/* <ROW1 - CONTAINER/> */}
          <Grid container direction="row" alignItems="center"
          onClick={() => handleRowClick(obj.id)}
            sx={{
              cursor: 'pointer',
              //borderBottom:'1px solid #C4C4C4',
              justifyContent: "flex-start", alignItems: "center",  
              backgroundColor: getBackgroundColor(obj),
              color: mobileView && obj.expand?'primary.main':'primary.darker',
              // '&:hover': {
              //   backgroundColor: getHoverBackgroundColor, 
              //   color: getHoverColor()
              // },
              padding:2
            }}>
            {/* <ROW1 - CELLS/> */}
            
              {/* <Grid item lg={2} xs={2} ><Typography color="" sx={{ fontWeight: 'light', fontSize: 15 }}>{obj.title}</Typography></Grid> */}
              <Grid item lg={2} xs={2} ><Typography color="" sx={{ fontWeight: 'light', fontSize: 15 }}>{obj.orderid}</Typography></Grid>
              
              {/* <Grid item lg={2} xs={2} ><Typography color="" sx={{ fontWeight: 'light', fontSize: 15 }}>{obj.srpartnerid}</Typography></Grid> */}
              <Grid item lg={4} xs={3} ><Typography color="" sx={{ fontWeight: 'light', fontSize: 15 }}>{obj.creditvianame}</Typography></Grid>
              <Grid item lg={2} xs={2} ><Typography color="" sx={{ fontWeight: 'light', fontSize: 15 }}>{obj.creditlevel}</Typography></Grid>
              <Grid item lg={4} xs={5} ><Typography color="" sx={{ fontWeight: 'light', fontSize: 15 }}>{convertToRSWithTwoDec(obj.creditvalue)} [{((obj.creditvalue/obj.ordervalue)*100).toFixed(2)}%]</Typography></Grid>
              
              {/* <Grid item lg={2} xs={2} >
                <Grid container direction="row" sx={{ justifyContent: "flex-end", width: '100%', }}>
                  {getActionComponent(obj)}
                </Grid>
              </Grid> */}
            {/* <Grid item sx={{ width: '100%' }}>
              <Divider sx={{ backgroundColor: "#C4C4C4" }} />
            </Grid> */}

          </Grid>
        </Grid>
        <Grid item lg={12} sx={{ width: '100%'}}>
        {obj.expand ?
        <Grid container direction="row">
          <Grid item sx={{ width: '100%', backgroundColor:'secondary.100' }}>
            < MlmOrderDetails  orderId={obj.orderid} />
          </Grid>
          <Grid item sx={{ width: '100%' }}>
            <Divider />
          </Grid>
        </Grid>
        : <Grid container direction="row">
          <Grid item sx={{ width: '100%' }}>
            <Divider />
          </Grid>
        </Grid>}
        </Grid>
      </>
      
    )
}