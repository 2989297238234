import { configureStore } from "@reduxjs/toolkit";
import MenuSlice from "./slice/MenuSlice";
import RestoLiveSlice from "./slice/RestoLiveSlice";
import ChoiceSelectionSlice from "./slice/ChoiceSelectionSlice";
import ConfSlice from "./slice/ConfSlice";
import DmpSlice from "./slice/DmpSlice";
import BreadCrumSlice from './slice/BreadCrumSlice';
import HeaderSlice from "./slice/HeaderSlice";
import ImageGallerySlice from "./slice/ImageGallerySlice";
import EcomSlice from "./slice/EcomSlice";
import OrderMgmtSlice from "./slice/OrderMgmtSlice";
import AppSlice from "./slice/AppSlice";


export default configureStore({
    reducer: {
        headerslice:HeaderSlice,
        breadcrumslice:BreadCrumSlice,
        menu: MenuSlice,
        restolive: RestoLiveSlice,
        confslice: ConfSlice,
        dmpslice: DmpSlice,
        ChoiceSelectionSlice,
        imagegalleryslice:ImageGallerySlice,
        ecomslice:EcomSlice,
        ordermgmtslice:OrderMgmtSlice,
        appslice:AppSlice
    }
})