import { getAPIprefix } from 'api/ApiUtils';
import { v4 as uuidv4 } from 'uuid';

var IdentityServiceAPIPrefix = "https://appmachine.in/identity-core-service";
//var IdentityServiceAPIPrefix = "http://localhost:8082/identity-core-service";


export const sendOTPAPI = ( userId, appId, successCallBack, failuerCallback) => {

    const payload = {
        "userid":userId,
        "appid":appId
    }
    callAPI(IdentityServiceAPIPrefix+"/identity/sendotp", "POST", payload, successCallBack, failuerCallback);
    
}
export const sendCustomerOTPAPI = ( userId,successCallBack, failuerCallback) => {
    const token = localStorage.getItem("JWT");
    const payload = {
        "userId":userId,
    }
    callAPI(IdentityServiceAPIPrefix+"/customerotp", "POST", payload, successCallBack, failuerCallback, token);
    
}

export const AuthenticateAPI = ( otpreqid, authcode, successCallBack, failuerCallback) => {

    const payload = {
        "otpreqid":otpreqid,
        "otp":authcode
    }
    callAPI(IdentityServiceAPIPrefix+"/identity/validateotp", "POST", payload, successCallBack, failuerCallback);
    
}
export const validateCustomerOtpAPI = ( otpreqid, authcode, successCallBack, failuerCallback) => {
    const token = localStorage.getItem("JWT");
    const payload = {
        "otpreqid":otpreqid,
        "otp":authcode
    }
    callAPI(IdentityServiceAPIPrefix+"/validatecustomerotp", "POST", payload, successCallBack, failuerCallback,token);
    
}

export const updateNameAPI = (name, otptoken, successCallBack, failuerCallback) => {

    const payload = {
        "name":name,
        "otptoken":otptoken
    }
    callAPI(IdentityServiceAPIPrefix+"/updatename", "POST", payload, successCallBack, failuerCallback);
}

export const getAuthInfoAPI = (otptoken, successCallBack, failuerCallback) => {

    const payload = {
        "otptoken":otptoken
    }
    callAPI(IdentityServiceAPIPrefix+"/getauthdetails", "POST", payload, successCallBack, failuerCallback);
}
export const callAPI = (api, method, payload, successCallBack, failuerCallBack, token) => {

    let requestOptions = {
    }

    if (method==='GET'){
        requestOptions = {
            method: method,
            headers: { 'Content-Type': 'application/json' }
        }
    } else {

    
    requestOptions = {
        method: method,
        headers: token===undefined?{ 'Content-Type': 'application/json' }:{ 'Content-Type': 'application/json','Authorization': `Bearer ${token}` },
        body: JSON.stringify(payload)
    };
}
    try{
        fetch(api, requestOptions)
            .then(response => {
                if (response.status===200) {
                    response.json().then(json => {
                        //console.log("Response=>"+JSON.stringify(json));
                        if (json.success===undefined){
                            successCallBack(json);
                        } else {
                            if (!json.success){
                                failuerCallBack(json);
                            } else successCallBack(json)
                        }
                      });
                } else {
                    
                    let errorMessage = 'Something Went Wrong';
                    if (208 === response.status) errorMessage='Record already exist';
                    failuerCallBack(errorMessage);
                    if (500 === response.status) errorMessage='Internal Server Error';
                    failuerCallBack(errorMessage);
                }              
        })
    } catch (error) {
        // setAlert(true);
        console.log(error);
        failuerCallBack(error.data);
        }
}