import { createSlice } from '@reduxjs/toolkit';

export const DmpSlice = createSlice({
    name: "dmpslice",
    initialState: {
      appId:26,
      actionList:[],
      actionDashboard:{
          selectedId:-1,
          actionList:[],
          actionObj:{},
          
      },
      menuList:[],
  
        productList:[],
		productDashboard:{
            selectedId:-1,
            showEdit:false,
            productList:[],
            productObj:{},
            filterCriteria:{
                
			title:''
            },
            
			editProductModalOpen:false,
			manageProductModalOpen:false,
            
        },
        categoryList:[],
		categoryDashboard:{
            selectedId:-1,
            showEdit:false,
            categoryList:[],
            categoryObj:{},
            filterCriteria:{
                
			title:''
            },
            
            
        },
        productcategoryList:[],
		productcategoryDashboard:{
            selectedId:-1,
            showEdit:false,
            productcategoryList:[],
            productcategoryObj:{},
            filterCriteria:{
                
			title:''
            },
            
            
        },
        customerList:[],
		customerDashboard:{
            selectedId:-1,
            showEdit:false,
            customerList:[],
            customerObj:{},
            filterCriteria:{
                
			title:''
            },
            
            
        },
        mlmuserList:[],
		mlmuserDashboard:{
            selectedId:-1,
            showEdit:false,
            mlmuserList:[],
            mlmuserObj:{},
            filterCriteria:{
                
			title:'',
			mlmusertypeid:'',
			mobilenumber:''
            },
            
			tandcModalOpen:false,
			onboardModalOpen:false,
			edituserModalOpen:false,
            
        },
        mlmusertypeList:[],
		mlmusertypeDashboard:{
            selectedId:-1,
            showEdit:false,
            mlmusertypeList:[],
            mlmusertypeObj:{},
            filterCriteria:{
                
			title:''
            },
            
            
        },
        mlmcreditList:[],
		mlmcreditDashboard:{
            selectedId:-1,
            showEdit:false,
            mlmcreditList:[],
            mlmcreditObj:{},
            filterCriteria:{
                
			title:''
            },
            
            
        },
        mlmorderList:[],
		mlmorderDashboard:{
            selectedId:-1,
            showEdit:false,
            mlmorderList:[],
            mlmorderObj:{},
            filterCriteria:{
                
			title:''
            },
            
            
        },
    },
    reducers: {
        
        setActionList: (state, action) =>{
            state.actionList=action.payload;
            state.actionDashboard.actionList=action.payload;
        },
        setSelectedActionId: (state, action)=>{
            state.actionDashboard.selectedId=action.payload;

            state.actionDashboard.actionObj = state.actionList[
                state.actionList.findIndex(actionObj=>actionObj.id===parseInt(action.payload))
            ];
        },

        
        /* Product Reducer methods */
        setProductFilterCriteria : (state, action)=>{
            state.productDashboard.filterCriteria=action.payload;
        },
        setShowEditForProduct: (state,action)=>{
            state.productDashboard.showEdit=action.payload;
            
			state.productDashboard.editProductModalOpen=false;
			state.productDashboard.manageProductModalOpen=false;
        },
        addProductObj: (state, action)=>{
            state.productList.unshift(action.payload);
            state.productDashboard.productList.unshift(action.payload);

            state.productDashboard.productObj=action.payload;
            state.productDashboard.selectedId=action.payload.id;
        },
        updateProductObj: (state, action)=>{
            let index = state.productList.map(productObj=>productObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.productList[index]=action.payload;
            }

            index = state.productDashboard.productList.map(productObj=>productObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.productDashboard.productList[index]=action.payload;
            }
            if (state.productDashboard.productObj!==null){
                state.productDashboard.productObj = action.payload;
            }
        },
        deleteProductObj: (state, action)=>{
            state.productList.splice(state.productList.findIndex(a => a.id === action.payload) , 1);
            state.productDashboard.productList.splice(state.productDashboard.productList.findIndex(a => a.id === action.payload) , 1);
            state.productDashboard.productObj={}
            state.productDashboard.selectedId=-1
        },
        setSelectedProductId: (state, action)=>{
            if (action.payload!==-1){
                state.productDashboard.selectedId=action.payload;
                state.productDashboard.productObj = state.productList[
                    state.productList.findIndex(productObj=>productObj.id===parseInt(action.payload))
            ];
            } else {
                state.productDashboard.selectedId=action.payload;
                state.productDashboard.productObj ={}
            }
        },
        setProductList: (state, action) =>{
            state.productList=action.payload;
            state.productDashboard.productList=action.payload;
        },
        setProductDashboardProductList: (state, action) =>{
            state.productDashboard.productList=action.payload;
        },
      
        setProductEditProductModal:(state,action)=>{
          state.productDashboard.editProductModalOpen=action.payload;
        },
      
        setProductManageProductModal:(state,action)=>{
          state.productDashboard.manageProductModalOpen=action.payload;
        },
        /* Category Reducer methods */
        setCategoryFilterCriteria : (state, action)=>{
            state.categoryDashboard.filterCriteria=action.payload;
        },
        setShowEditForCategory: (state,action)=>{
            state.categoryDashboard.showEdit=action.payload;
            
        },
        addCategoryObj: (state, action)=>{
            state.categoryList.unshift(action.payload);
            state.categoryDashboard.categoryList.unshift(action.payload);

            state.categoryDashboard.categoryObj=action.payload;
            state.categoryDashboard.selectedId=action.payload.id;
        },
        updateCategoryObj: (state, action)=>{
            let index = state.categoryList.map(categoryObj=>categoryObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.categoryList[index]=action.payload;
            }

            index = state.categoryDashboard.categoryList.map(categoryObj=>categoryObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.categoryDashboard.categoryList[index]=action.payload;
            }
            if (state.categoryDashboard.categoryObj!==null){
                state.categoryDashboard.categoryObj = action.payload;
            }
        },
        deleteCategoryObj: (state, action)=>{
            state.categoryList.splice(state.categoryList.findIndex(a => a.id === action.payload) , 1);
            state.categoryDashboard.categoryList.splice(state.categoryDashboard.categoryList.findIndex(a => a.id === action.payload) , 1);
            state.categoryDashboard.productObj={}
            state.categoryDashboard.selectedId=-1
        },
        setSelectedCategoryId: (state, action)=>{
            if (action.payload!==-1){
                state.categoryDashboard.selectedId=action.payload;
                state.categoryDashboard.categoryObj = state.categoryList[
                    state.categoryList.findIndex(categoryObj=>categoryObj.id===parseInt(action.payload))
            ];
            } else {
                state.categoryDashboard.selectedId=action.payload;
                state.categoryDashboard.categoryObj ={}
            }
        },
        setCategoryList: (state, action) =>{
            state.categoryList=action.payload;
            state.categoryDashboard.categoryList=action.payload;
        },
        setCategoryDashboardCategoryList: (state, action) =>{
            state.categoryDashboard.categoryList=action.payload;
        },
        /* Productcategory Reducer methods */
        setProductcategoryFilterCriteria : (state, action)=>{
            state.productcategoryDashboard.filterCriteria=action.payload;
        },
        setShowEditForProductcategory: (state,action)=>{
            state.productcategoryDashboard.showEdit=action.payload;
            
        },
        addProductcategoryObj: (state, action)=>{
            state.productcategoryList.unshift(action.payload);
            state.productcategoryDashboard.productcategoryList.unshift(action.payload);

            state.productcategoryDashboard.productcategoryObj=action.payload;
            state.productcategoryDashboard.selectedId=action.payload.id;
        },
        updateProductcategoryObj: (state, action)=>{
            let index = state.productcategoryList.map(productcategoryObj=>productcategoryObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.productcategoryList[index]=action.payload;
            }

            index = state.productcategoryDashboard.productcategoryList.map(productcategoryObj=>productcategoryObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.productcategoryDashboard.productcategoryList[index]=action.payload;
            }
            if (state.productcategoryDashboard.productcategoryObj!==null){
                state.productcategoryDashboard.productcategoryObj = action.payload;
            }
        },
        deleteProductcategoryObj: (state, action)=>{
            state.productcategoryList.splice(state.productcategoryList.findIndex(a => a.id === action.payload) , 1);
            state.productcategoryDashboard.productcategoryList.splice(state.productcategoryDashboard.productcategoryList.findIndex(a => a.id === action.payload) , 1);
            state.productcategoryDashboard.productObj={}
            state.productcategoryDashboard.selectedId=-1
        },
        setSelectedProductcategoryId: (state, action)=>{
            if (action.payload!==-1){
                state.productcategoryDashboard.selectedId=action.payload;
                state.productcategoryDashboard.productcategoryObj = state.productcategoryList[
                    state.productcategoryList.findIndex(productcategoryObj=>productcategoryObj.id===parseInt(action.payload))
            ];
            } else {
                state.productcategoryDashboard.selectedId=action.payload;
                state.productcategoryDashboard.productcategoryObj ={}
            }
        },
        setProductcategoryList: (state, action) =>{
            state.productcategoryList=action.payload;
            state.productcategoryDashboard.productcategoryList=action.payload;
        },
        setProductcategoryDashboardProductcategoryList: (state, action) =>{
            state.productcategoryDashboard.productcategoryList=action.payload;
        },
        /* Customer Reducer methods */
        setCustomerFilterCriteria : (state, action)=>{
            state.customerDashboard.filterCriteria=action.payload;
        },
        setShowEditForCustomer: (state,action)=>{
            state.customerDashboard.showEdit=action.payload;
            
        },
        addCustomerObj: (state, action)=>{
            state.customerList.unshift(action.payload);
            state.customerDashboard.customerList.unshift(action.payload);

            state.customerDashboard.customerObj=action.payload;
            state.customerDashboard.selectedId=action.payload.id;
        },
        updateCustomerObj: (state, action)=>{
            let index = state.customerList.map(customerObj=>customerObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.customerList[index]=action.payload;
            }

            index = state.customerDashboard.customerList.map(customerObj=>customerObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.customerDashboard.customerList[index]=action.payload;
            }
            if (state.customerDashboard.customerObj!==null){
                state.customerDashboard.customerObj = action.payload;
            }
        },
        deleteCustomerObj: (state, action)=>{
            state.customerList.splice(state.customerList.findIndex(a => a.id === action.payload) , 1);
            state.customerDashboard.customerList.splice(state.customerDashboard.customerList.findIndex(a => a.id === action.payload) , 1);
            state.customerDashboard.productObj={}
            state.customerDashboard.selectedId=-1
        },
        setSelectedCustomerId: (state, action)=>{
            if (action.payload!==-1){
                state.customerDashboard.selectedId=action.payload;
                state.customerDashboard.customerObj = state.customerList[
                    state.customerList.findIndex(customerObj=>customerObj.id===parseInt(action.payload))
            ];
            } else {
                state.customerDashboard.selectedId=action.payload;
                state.customerDashboard.customerObj ={}
            }
        },
        setCustomerList: (state, action) =>{
            state.customerList=action.payload;
            state.customerDashboard.customerList=action.payload;
        },
        setCustomerDashboardCustomerList: (state, action) =>{
            state.customerDashboard.customerList=action.payload;
        },
        /* Mlmuser Reducer methods */
        setMlmuserFilterCriteria : (state, action)=>{
            state.mlmuserDashboard.filterCriteria=action.payload;
        },
        setShowEditForMlmuser: (state,action)=>{
            state.mlmuserDashboard.showEdit=action.payload;
            
			state.mlmuserDashboard.tandcModalOpen=false;
			state.mlmuserDashboard.onboardModalOpen=false;
			state.mlmuserDashboard.edituserModalOpen=false;
        },
        addMlmuserObj: (state, action)=>{
            state.mlmuserList.unshift(action.payload);
            state.mlmuserDashboard.mlmuserList.unshift(action.payload);

            state.mlmuserDashboard.mlmuserObj=action.payload;
            state.mlmuserDashboard.selectedId=action.payload.id;
        },
        updateMlmuserObj: (state, action)=>{
            let index = state.mlmuserList.map(mlmuserObj=>mlmuserObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.mlmuserList[index]=action.payload;
            }

            index = state.mlmuserDashboard.mlmuserList.map(mlmuserObj=>mlmuserObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.mlmuserDashboard.mlmuserList[index]=action.payload;
            }
            if (state.mlmuserDashboard.mlmuserObj!==null){
                state.mlmuserDashboard.mlmuserObj = action.payload;
            }
        },
        deleteMlmuserObj: (state, action)=>{
            state.mlmuserList.splice(state.mlmuserList.findIndex(a => a.id === action.payload) , 1);
            state.mlmuserDashboard.mlmuserList.splice(state.mlmuserDashboard.mlmuserList.findIndex(a => a.id === action.payload) , 1);
            state.mlmuserDashboard.productObj={}
            state.mlmuserDashboard.selectedId=-1
        },
        setSelectedMlmuserId: (state, action)=>{
            if (action.payload!==-1){
                state.mlmuserDashboard.selectedId=action.payload;
                state.mlmuserDashboard.mlmuserObj = state.mlmuserList[
                    state.mlmuserList.findIndex(mlmuserObj=>mlmuserObj.id===parseInt(action.payload))
            ];
            } else {
                state.mlmuserDashboard.selectedId=action.payload;
                state.mlmuserDashboard.mlmuserObj ={}
            }
        },
        setMlmuserList: (state, action) =>{
            state.mlmuserList=action.payload;
            state.mlmuserDashboard.mlmuserList=action.payload;
        },
        setMlmuserDashboardMlmuserList: (state, action) =>{
            state.mlmuserDashboard.mlmuserList=action.payload;
        },
      
        setMlmuserTandcModal:(state,action)=>{
          state.mlmuserDashboard.tandcModalOpen=action.payload;
        },
      
        setMlmuserOnboardModal:(state,action)=>{
          state.mlmuserDashboard.onboardModalOpen=action.payload;
        },
      
        setMlmuserEdituserModal:(state,action)=>{
          state.mlmuserDashboard.edituserModalOpen=action.payload;
        },
        /* Mlmusertype Reducer methods */
        setMlmusertypeFilterCriteria : (state, action)=>{
            state.mlmusertypeDashboard.filterCriteria=action.payload;
        },
        setShowEditForMlmusertype: (state,action)=>{
            state.mlmusertypeDashboard.showEdit=action.payload;
            
        },
        addMlmusertypeObj: (state, action)=>{
            state.mlmusertypeList.unshift(action.payload);
            state.mlmusertypeDashboard.mlmusertypeList.unshift(action.payload);

            state.mlmusertypeDashboard.mlmusertypeObj=action.payload;
            state.mlmusertypeDashboard.selectedId=action.payload.id;
        },
        updateMlmusertypeObj: (state, action)=>{
            let index = state.mlmusertypeList.map(mlmusertypeObj=>mlmusertypeObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.mlmusertypeList[index]=action.payload;
            }

            index = state.mlmusertypeDashboard.mlmusertypeList.map(mlmusertypeObj=>mlmusertypeObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.mlmusertypeDashboard.mlmusertypeList[index]=action.payload;
            }
            if (state.mlmusertypeDashboard.mlmusertypeObj!==null){
                state.mlmusertypeDashboard.mlmusertypeObj = action.payload;
            }
        },
        deleteMlmusertypeObj: (state, action)=>{
            state.mlmusertypeList.splice(state.mlmusertypeList.findIndex(a => a.id === action.payload) , 1);
            state.mlmusertypeDashboard.mlmusertypeList.splice(state.mlmusertypeDashboard.mlmusertypeList.findIndex(a => a.id === action.payload) , 1);
            state.mlmusertypeDashboard.productObj={}
            state.mlmusertypeDashboard.selectedId=-1
        },
        setSelectedMlmusertypeId: (state, action)=>{
            if (action.payload!==-1){
                state.mlmusertypeDashboard.selectedId=action.payload;
                state.mlmusertypeDashboard.mlmusertypeObj = state.mlmusertypeList[
                    state.mlmusertypeList.findIndex(mlmusertypeObj=>mlmusertypeObj.id===parseInt(action.payload))
            ];
            } else {
                state.mlmusertypeDashboard.selectedId=action.payload;
                state.mlmusertypeDashboard.mlmusertypeObj ={}
            }
        },
        setMlmusertypeList: (state, action) =>{
            state.mlmusertypeList=action.payload;
            state.mlmusertypeDashboard.mlmusertypeList=action.payload;
        },
        setMlmusertypeDashboardMlmusertypeList: (state, action) =>{
            state.mlmusertypeDashboard.mlmusertypeList=action.payload;
        },
        /* Mlmcredit Reducer methods */
        setMlmcreditFilterCriteria : (state, action)=>{
            state.mlmcreditDashboard.filterCriteria=action.payload;
        },
        setShowEditForMlmcredit: (state,action)=>{
            state.mlmcreditDashboard.showEdit=action.payload;
            
        },
        addMlmcreditObj: (state, action)=>{
            state.mlmcreditList.unshift(action.payload);
            state.mlmcreditDashboard.mlmcreditList.unshift(action.payload);

            state.mlmcreditDashboard.mlmcreditObj=action.payload;
            state.mlmcreditDashboard.selectedId=action.payload.id;
        },
        updateMlmcreditObj: (state, action)=>{
            let index = state.mlmcreditList.map(mlmcreditObj=>mlmcreditObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.mlmcreditList[index]=action.payload;
            }

            index = state.mlmcreditDashboard.mlmcreditList.map(mlmcreditObj=>mlmcreditObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.mlmcreditDashboard.mlmcreditList[index]=action.payload;
            }
            if (state.mlmcreditDashboard.mlmcreditObj!==null){
                state.mlmcreditDashboard.mlmcreditObj = action.payload;
            }
        },
        deleteMlmcreditObj: (state, action)=>{
            state.mlmcreditList.splice(state.mlmcreditList.findIndex(a => a.id === action.payload) , 1);
            state.mlmcreditDashboard.mlmcreditList.splice(state.mlmcreditDashboard.mlmcreditList.findIndex(a => a.id === action.payload) , 1);
            state.mlmcreditDashboard.productObj={}
            state.mlmcreditDashboard.selectedId=-1
        },
        setSelectedMlmcreditId: (state, action)=>{
            if (action.payload!==-1){
                state.mlmcreditDashboard.selectedId=action.payload;
                state.mlmcreditDashboard.mlmcreditObj = state.mlmcreditList[
                    state.mlmcreditList.findIndex(mlmcreditObj=>mlmcreditObj.id===parseInt(action.payload))
            ];
            } else {
                state.mlmcreditDashboard.selectedId=action.payload;
                state.mlmcreditDashboard.mlmcreditObj ={}
            }
        },
        setMlmcreditList: (state, action) =>{
            state.mlmcreditList=action.payload;
            state.mlmcreditDashboard.mlmcreditList=action.payload;
        },
        setMlmcreditDashboardMlmcreditList: (state, action) =>{
            state.mlmcreditDashboard.mlmcreditList=action.payload;
        },
        /* Mlmorder Reducer methods */
        setMlmorderFilterCriteria : (state, action)=>{
            state.mlmorderDashboard.filterCriteria=action.payload;
        },
        setShowEditForMlmorder: (state,action)=>{
            state.mlmorderDashboard.showEdit=action.payload;
            
        },
        addMlmorderObj: (state, action)=>{
            state.mlmorderList.unshift(action.payload);
            state.mlmorderDashboard.mlmorderList.unshift(action.payload);

            state.mlmorderDashboard.mlmorderObj=action.payload;
            state.mlmorderDashboard.selectedId=action.payload.id;
        },
        updateMlmorderObj: (state, action)=>{
            let index = state.mlmorderList.map(mlmorderObj=>mlmorderObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.mlmorderList[index]=action.payload;
            }

            index = state.mlmorderDashboard.mlmorderList.map(mlmorderObj=>mlmorderObj.id).indexOf(action.payload.id);
            if (index>-1){
                state.mlmorderDashboard.mlmorderList[index]=action.payload;
            }
            if (state.mlmorderDashboard.mlmorderObj!==null){
                state.mlmorderDashboard.mlmorderObj = action.payload;
            }
        },
        deleteMlmorderObj: (state, action)=>{
            state.mlmorderList.splice(state.mlmorderList.findIndex(a => a.id === action.payload) , 1);
            state.mlmorderDashboard.mlmorderList.splice(state.mlmorderDashboard.mlmorderList.findIndex(a => a.id === action.payload) , 1);
            state.mlmorderDashboard.productObj={}
            state.mlmorderDashboard.selectedId=-1
        },
        setSelectedMlmorderId: (state, action)=>{
            if (action.payload!==-1){
                state.mlmorderDashboard.selectedId=action.payload;
                state.mlmorderDashboard.mlmorderObj = state.mlmorderList[
                    state.mlmorderList.findIndex(mlmorderObj=>mlmorderObj.id===parseInt(action.payload))
            ];
            } else {
                state.mlmorderDashboard.selectedId=action.payload;
                state.mlmorderDashboard.mlmorderObj ={}
            }
        },
        setMlmorderList: (state, action) =>{
            state.mlmorderList=action.payload;
            state.mlmorderDashboard.mlmorderList=action.payload;
        },
        setMlmorderDashboardMlmorderList: (state, action) =>{
            state.mlmorderDashboard.mlmorderList=action.payload;
        },
    }
});
export const { setActionList, setSelectedActionId, setProductList,getProductList,updateProductObj,addProductObj,deleteProductObj,
setSelectedProductId,
setProductDashboardProductList,
setShowEditForProduct,
setProductFilterCriteria,
setProductEditProductModal,
setProductManageProductModal,setCategoryList,getCategoryList,updateCategoryObj,addCategoryObj,deleteCategoryObj,
setSelectedCategoryId,
setCategoryDashboardCategoryList,
setShowEditForCategory,
setCategoryFilterCriteria,setProductcategoryList,getProductcategoryList,updateProductcategoryObj,addProductcategoryObj,deleteProductcategoryObj,
setSelectedProductcategoryId,
setProductcategoryDashboardProductcategoryList,
setShowEditForProductcategory,
setProductcategoryFilterCriteria,setCustomerList,getCustomerList,updateCustomerObj,addCustomerObj,deleteCustomerObj,
setSelectedCustomerId,
setCustomerDashboardCustomerList,
setShowEditForCustomer,
setCustomerFilterCriteria,setMlmuserList,getMlmuserList,updateMlmuserObj,addMlmuserObj,deleteMlmuserObj,
setSelectedMlmuserId,
setMlmuserDashboardMlmuserList,
setShowEditForMlmuser,
setMlmuserFilterCriteria,
setMlmuserTandcModal,
setMlmuserOnboardModal,
setMlmuserEdituserModal,setMlmusertypeList,getMlmusertypeList,updateMlmusertypeObj,addMlmusertypeObj,deleteMlmusertypeObj,
setSelectedMlmusertypeId,
setMlmusertypeDashboardMlmusertypeList,
setShowEditForMlmusertype,
setMlmusertypeFilterCriteria,setMlmcreditList,getMlmcreditList,updateMlmcreditObj,addMlmcreditObj,deleteMlmcreditObj,
setSelectedMlmcreditId,
setMlmcreditDashboardMlmcreditList,
setShowEditForMlmcredit,
setMlmcreditFilterCriteria,setMlmorderList,getMlmorderList,updateMlmorderObj,addMlmorderObj,deleteMlmorderObj,
setSelectedMlmorderId,
setMlmorderDashboardMlmorderList,
setShowEditForMlmorder,
setMlmorderFilterCriteria, } = DmpSlice.actions;
export default DmpSlice.reducer;