import React, { useEffect, useState } from "react";
import {CoreContext} from '../context/CoreContext'

import Alert from '@mui/material/Alert';

export default function Notification() {
    const { notification, setNotification } = React.useContext(CoreContext);
    const [showNotification, setShowNotification] = useState(false);

    const hideNotification = (()=>{
        setNotification('');
        setShowNotification(false);
    })

    useEffect(()=>{
        setShowNotification(true);
        setTimeout(hideNotification, 2000);

    },[notification])
    return (
       <div>{notification && showNotification? <Alert onClose={() => {hideNotification(false)}} variant="filled" severity="info">{notification}</Alert>: ''}</div>
    )
}

