import React, { useState, useEffect } from 'react'
import { useSearchParams} from "react-router-dom";

import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import { Grid, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import DeleteNotification from '../DeleteNotification';
import PageWaiting from '../PageWaiting';
import {isMobile} from 'react-device-detect';

import {getListAPI, deleteAPI} from './AddressAPI';
import {getColumns} from './AddressStruct';

import {getAddressView} from './AddressView';
import CardListLayout from '../card/layout/CardListLayout';
import { useSelector, useDispatch } from 'react-redux';
import {setViewParams} from '../../../store/slice/ChoiceSelectionSlice';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';

import {setFilter} from '../../../store/slice/ConfSlice';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { deleteAddressObj  ,setSelectedAddressId , setShowEditForAddress,setAddressAddAddressModal} from 'store/slice/EcomSlice';

import AddressListTable from './components/AddressListTable';
import { useNavigate } from "react-router-dom";
import { setClickedBo,setActionClicked } from 'store/slice/BreadCrumSlice';
import AddressFilterCriteria from './AddressFilterCriteria';

import DomainIcon from '@mui/icons-material/Domain';
import AddAddressActionModal from './action/addAddress/AddAddressActionModal';


import { transformDateValue } from 'core/js/FormUtils';
import { CoreContext } from '../../context/CoreContext';

export default function AddressList( {tableList, cardList, setShowEdit, setAddressId}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setNotification, setShowLoading, showloading, lastURL } = React.useContext(CoreContext);
  const columns = getColumns(editCallBack, initDeleteCallBack);
  const [view, setView] = useState(1);
  const { showfilter } = useSelector((state) => state.confslice)

  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const {selectedId, addressObj} = useSelector((state)=>state.ecomslice.addressDashboard);
  const { addressList } = useSelector((state) => state.ecomslice.addressDashboard);

  useEffect(() => {
  }, [addressList, addressObj])
  
  function initDeleteCallBack(id){
    dispatch(setSelectedAddressId(id));
    setShowDeleteAlert(true);
  }
  function deleteCallBack(obj){
    dispatch(deleteAddressObj(obj.id));
    setNotification('Record deleted successfully !!!')
  }
  function deleteRecord(){
    deleteAPI(selectedId,deleteCallBack);
    setShowDeleteAlert(false);
  }

  function editCallBack(id){
    dispatch(setSelectedAddressId(id));
    dispatch(setAddressAddAddressModal(true));
  }
  
  const createAddress=()=>{
    dispatch(setSelectedAddressId(-1));
    dispatch(setAddressAddAddressModal(true));
  }



const getDataWithDateTransformation = (data) =>{
    let newData = {
      ...data,
      'expand':false,

    }
    return newData;
  }
  const addressTransformation = (tableList) =>{
    let newDataList=[]
    tableList.map((data)=>{ 
      newDataList.push(getDataWithDateTransformation(data));
     })
    return newDataList;
  }
  const addAddressCallBack = (id) =>{
    dispatch(setSelectedAddressId(id));
    dispatch(setAddressAddAddressModal(true));
   }
  const payload = {
    addressList:addressList,
    cardList:getAddressView(tableList),
        actionList: [
          /*{title:'Edit', callBackMethod:editCallBack, icon:<EditIcon/>, status:'NA'},
          {title:'Delete', callBackMethod:initDeleteCallBack, icon:<DeleteIcon/>, status:'NA'},
          
        */
        ],
        dialogueActionList: [
          
        ],
    tableView:{
      tableList:addressTransformation(tableList),
      addressList:addressList,
      actionList:[
        {title:'Edit', callBackMethod:editCallBack, icon:<EditIcon/>, status:'NA'},
        {title:'Delete', callBackMethod:initDeleteCallBack, icon:<DeleteIcon/>, status:'NA'},
        // {id:58, title:'AddAddress', callBackMethod:addAddressCallBack, icon:<DomainIcon/>, status:'Draft', role:''},
        
      ]
    }
  }
  
  

  const getPayload = ()=>{
    return payload;
  }

  useEffect(()=>{
    if (document.body.offsetWidth<500)  setView(0);
    dispatch(setViewParams({
      colsCount:{xs:12, sm:4, md:4, lg:4},
      modal:{
            
      }
      }))
  },[])

  return (
   <>
   {showloading?<PageWaiting/>:
      <Grid container direction="column">
              <Grid item>
                  <Grid container direction="row" justifyContent={'space-between'} alignContent={'center'} sx={{height:showfilter?'55px':'35px'}}>
                      <Grid item  lg={3}>
                        <Typography variant='h4' color="primary.darker">Address Information</Typography>
                      </Grid>
                      <Grid item lg={8}>
                      {showfilter ?  < AddressFilterCriteria  />: <></>}
                      </Grid>
                      <Grid item lg={1}>
                        <Grid container justifyContent={'flex-end'}>
                        {/* {showfilter?
                            <Grid item>
                              <Tooltip title="Hide filter" sx={{cursor: "pointer"}}>
                                <FilterAltOffIcon onClick={()=>{dispatch(setFilter(false))}}/>
                              </Tooltip>
                            </Grid>
                            :
                            <Grid item>
                              <Tooltip title="Show filter" sx={{cursor: "pointer"}}>
                                <FilterAltIcon onClick={()=>{dispatch(setFilter(true))}}/>
                              </Tooltip>
                            </Grid>
                            } */}
                            {/*view===1?
                            <Grid item>
                              <Tooltip title="Grid View" sx={{cursor: "pointer"}}>
                                <ViewWeekIcon onClick={()=>{setView(0)}}/>
                              </Tooltip>
                            </Grid>
                            :
                            <Grid item>
                              <Tooltip title="Table View" sx={{cursor: "pointer"}}>
                                <CalendarViewMonthIcon onClick={()=>{setView(1)}}/>
                              </Tooltip>
                            </Grid>
                            */}
                            <Grid item>
                              <Tooltip title="Add Address" sx={{cursor: "pointer"}}>
                                <AddIcon onClick={()=>{createAddress()}}/>
                              </Tooltip>
                            </Grid>
                          </Grid>
                      </Grid>
                      
                  </Grid>
              </Grid>
              <Grid item>
                  
                     < AddressListTable 
                        payload={getPayload().tableView}
                        />
              
              </Grid>     
      </Grid>
   }
    { showDeleteAlert?<DeleteNotification 
            id={selectedId}
            width="50%"
            setShowDeleteAlert={setShowDeleteAlert}
            deleteRecord = {deleteRecord}/>: ''
    }

    <AddAddressActionModal/>
    </>
);


}