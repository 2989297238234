import { transformDateValue } from 'core/js/FormUtils';
export const getProductView = (dataList) => {
  
  const transformDataValue = (value, type) =>{
    if (!type.includes('date')) return value;
    return transformDateValue(value);
  }

  let productList = [];
  dataList.map((data)=>{
  let card = {
            'id': data.id,
            'headingList': [
                            {"fieldLabel":"Title","fieldValue":transformDataValue(data.title,"string"), "fieldType":"string"},
                            {"fieldLabel":"Description","fieldValue":transformDataValue(data.description,"string"), "fieldType":"string"},
                            {"fieldLabel":"Image Path","fieldValue":transformDataValue(data.imagepath,"string"), "fieldType":"string"},
                            {"fieldLabel":"Price","fieldValue":transformDataValue(data.price,"double"), "fieldType":"double"},
              
              
            ],
            'dataList': [
                
              ]
          }

          productList.push(card);
  })
  return productList;
}