import React from "react";
import { initialState, actions, reducer } from "./CoreContextReducer";

export const CoreContext = React.createContext();

export const CoreContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const coreContextMgr = {
    notification: state.notification,
    showloading: state.showloading,
    lastURL: state.lastURL,
    homeURL: state.homeURL,
    parentURL: state.parentURL,
    keyvalues: state.keyvalues,
    setNotification : (notification) => {
        dispatch({type:actions.SET_NOTIFICATION, notification})
    },
    setShowLoading : (flag) => {
        dispatch({type: actions.SET_SHOW_LOADING, flag})
    },
    setLastURL : (url) => {
      dispatch({type: actions.SET_LAST_URL, url})
    },
    setHomeURL : (url) => {
      dispatch({type: actions.SET_HOME_URL, url})
    },
    setParentURL : (url) => {
      dispatch({type: actions.SET_PARENT_URL, url})
    },
    setKeyValue : (key,value) => {
      dispatch({type: actions.SET_KEY_VALUE, key,value})
    },
    getKeyValues: (key) =>{
      return state.keyvalues.get(key);
    }

  };
  

  return (
    <CoreContext.Provider value={coreContextMgr}>
      {children}
    </CoreContext.Provider>
  );
};