import {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {Grid, Box} from '@mui/material';
import FieldView from 'core/components/layout/FieldView';


export default function CustomerInfo(){
  const { customerObj} = useSelector((state)=>state.dmpslice.customerDashboard);
    return (
      <Box lg={12} sx={{ flexGrow: 1 }}>
      <Grid item sx={{ overflowY: "scroll", backgroundColor: 'white', maxHeight: { xs: window.screen.height - 250, lg: window.screen.height } }}>
        
        <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{backgroundColor:'white'}}
      >
            
        <Grid item lg={4} xs={6}><FieldView label="Business Id"  value={ customerObj.businessid!==null && customerObj.businessid!==undefined?customerObj.businessid:'NOT KNONWN' }/></Grid>
        <Grid item lg={4} xs={6}><FieldView label="Title"  value={ customerObj.title!==null && customerObj.title!==undefined?customerObj.title:'NOT KNONWN' }/></Grid>
        <Grid item lg={4} xs={6}><FieldView label="Email"  value={ customerObj.email!==null && customerObj.email!==undefined?customerObj.email:'NOT KNONWN' }/></Grid>
        <Grid item lg={4} xs={6}><FieldView label="Phone"  value={ customerObj.phone!==null && customerObj.phone!==undefined?customerObj.phone:'NOT KNONWN' }/></Grid>
        <Grid item lg={4} xs={6}><FieldView label="Invitee Id"  value={ customerObj.inviteeid!==null && customerObj.inviteeid!==undefined?customerObj.inviteeid:'NOT KNONWN' }/></Grid>
        <Grid item lg={4} xs={6}><FieldView label="Status"  value={ customerObj.status!==null && customerObj.status!==undefined?customerObj.status:'NOT KNONWN' }/></Grid>
        <Grid item lg={4} xs={6}><FieldView label="Customer Type"  value={ customerObj.customertype!==null && customerObj.customertype!==undefined?customerObj.customertype:'NOT KNONWN' }/></Grid>
        <Grid item lg={4} xs={6}><FieldView label="Member Count"  value={ customerObj.membercount!==null && customerObj.membercount!==undefined?customerObj.membercount:'NOT KNONWN' }/></Grid>
      </Grid>
      </Grid>
      </Box>
    )
}