import { useState, useEffect } from "react";

import { getUserAPI } from "api/IdentityServiceAPI";
import { setAppuserInfo, setuserprofileInfo, setLoggedIn, setAppId, setProfile,setInitialAPIload } from "store/slice/HeaderSlice";
import Cookies from "js-cookie";

import { getListAPI as getProductList } from 'components/product/ProductAPI';
import { getListAPI as getCategoryList } from 'components/category/CategoryAPI';
import { getListAPI as getProductcategoryList } from 'components/productcategory/ProductcategoryAPI';
import { getListAPI as getCustomerList } from 'components/customer/CustomerAPI';
import { getListAPI as getActionList } from '../api/ActionAPI';

import { useDispatch,useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setActionList,setProductList,setCategoryList,setProductcategoryList,setCustomerList,setMlmuserOnboardModal } from 'store/slice/DmpSlice';
import { setMessage,openLoginModal, refreshUserProfile} from "store/slice/HeaderSlice";
import { setInviteeMobileNumber } from "store/slice/EcomSlice";

export default function DmpStoreLoader() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const getActionListSuccess = (list) =>{dispatch(setActionList(list));}
    const {isLoggedIn, userprofileInfo,refreshUserProfile} = useSelector((state)=>state.headerslice)

    
const getProductListSuccess = (list) =>{dispatch(setProductList(list));}
const getCategoryListSuccess = (list) =>{dispatch(setCategoryList(list));}
const getProductcategoryListSuccess = (list) =>{dispatch(setProductcategoryList(list));}
const getCustomerListSuccess = (list) =>{dispatch(setCustomerList(list));}

    /***   Load User profile if JWT is found otherwise Redirect to Login  */
    const storeInviteeMobileNumber = () =>{
        let url = window.location.href;
        let indexOfKey = url.lastIndexOf('?inviteelogin/');
        //if (indexOfKey==-1 || url.length<indexOfKey+14)  return;
        if (indexOfKey==-1)  return;

        dispatch(setInviteeMobileNumber(
            url.substring(url.lastIndexOf('?inviteelogin/')+14, url.length)
        ));
        //dispatch(openLoginModal(true));
        dispatch(setMlmuserOnboardModal(true));

    }
    useEffect(() => {
        storeInviteeMobileNumber();
        if (localStorage.getItem("JWT") !== null) {
                getUserAPI(loadUserSuccessCallBack, loadUserFailureCallBack);
        } else {
            navigate("/");
        }
    }, [])
    useEffect(()=>{
        getUserAPI(loadUserSuccessCallBack, loadUserFailureCallBack);
    },[refreshUserProfile])
    const loadUserSuccessCallBack = (response) => {
        if (response.userprofileInfo==undefined || response.appuserInfo==undefined){
            navigate('/');
            return;
        } 
        dispatch(setMessage(response.userprofileInfo.businessname));
        dispatch(setAppuserInfo(response.appuserInfo));
        dispatch(setuserprofileInfo(response.userprofileInfo));

        console.log('response.userprofileInfo.rolename==>'+response.userprofileInfo.rolename);
        if (response.userprofileInfo.rolename!=='MLM_CUSTOMER')
            dispatch(setLoggedIn(true));
    }
    const loadUserFailureCallBack = (response) => {
        navigate("/");

    }
    /* -----------  */

    /***   On User Profile Load, fetch all BOs    */
    useEffect(() => {
        if (isLoggedIn && (userprofileInfo!==null && userprofileInfo.username!==undefined)){
            loadBOs();
        }
    }, [userprofileInfo]);

    const loadBOs = ()=>{
    //getActionList(()=>{}, getActionListSuccess);

        
	//getProductList(()=>{}, getProductListSuccess);
	// getCategoryList(()=>{}, getCategoryListSuccess);
	// getProductcategoryList(()=>{}, getProductcategoryListSuccess);
	// getCustomerList(()=>{}, getCustomerListSuccess);
         
    }
}