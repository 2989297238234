import * as React from 'react';
import {Grid, Tooltip} from '@mui/material';

export default function IconEnabled({actionObj, id}) {
  return (
    actionObj!==null?<Grid item onClick={()=>actionObj.callBackMethod(id)} 
    sx={{padding:.5, color:'primary.dark', alignItems:'center', justifyContent:'center',
      '&:hover': { color:'primary.darker',backgroundColor:'secondary.light', cursor: 'pointer', borderRadius:1
       
    }}}>
        <Tooltip title={actionObj.title!==undefined?actionObj.title:<></>} arrow>
        {actionObj.icon}
        </Tooltip>
    </Grid>:<></>
  );
}