import React, { useState, useEffect } from 'react'

import { Grid, Typography, Box } from '@mui/material';

import { CoreContext } from '../../../core/context/CoreContext';
import PageWaiting from '../../../core/components/PageWaiting';
import IconEnabled from '../../../core/components/layout/IconEnabled';
import IconDisabled from 'core/components/layout/IconDisabled';
import { useDispatch, useSelector } from 'react-redux';
import ProductListTableHeader from './ProductListTableHeader';
import ProductListTableRow from './ProductListTableRow';

export default function ProductListTable({ payload }) {

  const { showloading } = React.useContext(CoreContext);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const {userprofileInfo} = useSelector((state)=>state.headerslice)
  const {mobileView} = useSelector((state)=>state.menu);
  
  useEffect(() => {
    setRows(payload.tableList);
  }, [payload])

  const handleRowClick = (rowId) => {
    setRows(prevData =>
      prevData.map(item =>
        item.id === rowId ? { ...item, 'expand': !item.expand } : item
      )
    );
  }

  const getActionComponent = (rowObj) => {
    return <>
      { payload.actionList.map(actionObj=>{
         if (
          (actionObj.role===undefined || actionObj.role!=undefined && (actionObj.role==='' || actionObj.role.includes(userprofileInfo.rolename)))
        ) 
        return <IconEnabled actionObj={actionObj}  id={rowObj.id}/>})}
    </>
  }

  return (
    <>
      <Grid container direction="row" sx={{ justifyContent: "flex-start", alignItems: "flex-start", }}>
        <Grid item sx={{ width: '100%' }}>
          <Box lg={12} sx={{ flexGrow: 1 }}>
            <Grid item sx={{ overflowY: "scroll", backgroundColor: 'white', maxHeight: userprofileInfo.rolename==='DMP_PRODUCT_MGR'?'100vh':mobileView?'72vh':'100vh' }}>
              <Grid container direction="row" sx={{ justifyContent: "flex-start", alignItems: "flex-start" }}>
                {rows.map(obj => {
                  return (
                    <Grid item xl={2} lg={3} md={3} xs={6} sx={{padding:.5}}>
                    <Grid container key={obj.id} direction="row"  sx={{ padding: {lg:1}, borderRadius:2, border:'.05px solid #096dd9'}}>
                      < ProductListTableRow obj={obj} getActionComponent={getActionComponent} handleRowClick={handleRowClick} />
                    </Grid>
                    </Grid>
                    )
                    
                })}
                {
                  rows.length === 0 ?
                    <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ width: '100%', backgroundColor: '#fafafa' }} padding={1}>
                      <Typography sx={{ fontWeight: 'light', fontSize: 14 }}><i>Records(s) are not added, yet.</i></Typography>
                    </Grid>
                    : <></>
                }
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );


}