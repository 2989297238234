import React, { useState, useEffect } from 'react'

import { Grid, Typography, Box, Stack, Badge, Button } from '@mui/material';

import { CoreContext } from '../../../core/context/CoreContext';
import PageWaiting from '../../../core/components/PageWaiting';
import IconEnabled from '../../../core/components/layout/IconEnabled';
import IconDisabled from 'core/components/layout/IconDisabled';
import { useDispatch, useSelector } from 'react-redux';
import MlmuserListTableHeader from './MlmuserListTableHeader';
import MlmuserListTableRow from './MlmuserListTableRow';
import { Tree, TreeNode } from 'react-organizational-chart';
import styled from 'styled-components';
import { getMlmuserInfoByPartneridAPI } from '../MlmuserAPI';
import PersonIcon from '@mui/icons-material/Person';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { setShowGraph } from 'store/slice/EcomSlice';

const StyledNode = styled.div`
  padding: 5px;
  border-radius: 8px;
  min-width:75px;
  display: inline-block;
  border: 1px solid red;
  background-color:rgb(237, 235, 235)
`;

export default function MembershipTree({ payload }) {
  const { userprofileInfo } = useSelector((state) => state.headerslice)
  const { mlmUserObj } = useSelector((state) => state.ecomslice.mlm);
  const [memberStruct, setMemberStruct] = useState([])
  const [selectedPartnerId, setSelectedPartnerId] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    getMlmuserInfoByPartneridAPI(mlmUserObj.userid, () => { }, getMembersSuccess)
  }, [])

  useEffect(() => {
    //  console.log('memberStruct=>'+JSON.stringify(memberStruct))
  }, [memberStruct])
  const getMembersSuccess = (apiResponse) => {
    let membershipStruct = []; // Initialize as an array
    for (let ii = 0; ii < apiResponse.length; ii++) {
      let memberObj = {
        "title": apiResponse[ii].title,
        "userId": apiResponse[ii].userid,
        "mobileNumber": apiResponse[ii].mobilenumber,
        "memberCount": apiResponse[ii].membercount,
        "loginStatus": apiResponse[ii].loginstatus,
        "expanded": false,
        "members": []
      };
      membershipStruct.push(memberObj); // Now this works because membershipStruct1 is an array
    }
    setMemberStruct(membershipStruct);
  }

  // Function to update state and empty the members of a specific userId
  const handleClearMembers = (userId) => {
    setMemberStruct(prevMembers =>
      prevMembers.map(member => clearMembersRecursive(member, userId))
    );
  };

  const clearMembersRecursive = (member, userId) => {
    if (member.userId === userId) {
      return { ...member, members: [] }; // Clear members for matching node
    }

    if (Array.isArray(member.members) && member.members.length > 0) {
      return {
        ...member,
        members: member.members.map(child => clearMembersRecursive(child, userId))
      };
    }

    return member; // Return unchanged if no match
  };
  // Function to search for node by userId
  const findNodeByUserId = (nodeList, userId) => {
    for (let node of nodeList) {
      if (node.userId === userId) {
        return node;  // Node found, return it
      }
      if (node.members && node.members.length > 0) {
        // Recursively check nested members
        const foundNode = findNodeByUserId(node.members, userId);
        if (foundNode) return foundNode;
      }
    }
    return null;  // Return null if no node with the given userId is found
  };

  const updateMemberRecursively = (nodes, targetUserId, newMembers) => {
    return nodes.map(node => {
      if (node.userId === targetUserId) {
        return {
          ...node,
          members: newMembers, // Update members for the matched node
          expanded: true,
        };
      }

      // Recur through nested members
      return {
        ...node,
        members: updateMemberRecursively(node.members, targetUserId, newMembers),
      };
    });
  };

  const getMembersOfMyMemberSuccess = (apiResponse) => {
    if (apiResponse.length === 0) return;
    let localMembershipStruct = []; // Initialize as an array
    for (let ii = 0; ii < apiResponse.length; ii++) {
      let memberObj = {
        "title": apiResponse[ii].title,
        "userId": apiResponse[ii].userid,
        "memberCount": apiResponse[ii].membercount,
        "mobileNumber": apiResponse[ii].mobilenumber,
        "loginStatus": apiResponse[ii].loginstatus,
        "expanded": false,
        "members": []
      };
      localMembershipStruct.push(memberObj); // Now this works because membershipStruct1 is an array
    }

    setMemberStruct(prevStruct =>
      updateMemberRecursively(prevStruct, apiResponse[0].seniorpartnerid, localMembershipStruct)
    );
  }

  const handleMemberClick = (userId) => {
    let clickedObj = findNodeByUserId(memberStruct, userId);
    if (clickedObj.members.length > 0) {
      handleClearMembers(clickedObj.userId);
    } else {
      setSelectedPartnerId(userId);
      if (userId!==0)
        getMlmuserInfoByPartneridAPI(userId, () => { }, getMembersOfMyMemberSuccess)
    }
  }
  // Recursive function to render titles
  const renderTitles = (node) => {
    return (
      <TreeNode label={
        <StyledNode onClick={() => handleMemberClick(node.userId)} >
          <Typography variant="h6" sx={{ cursor: 'pointer' }}>{node.title}[{node.mobileNumber}]</Typography>
          <Stack direction="column" alignItems="center" spacing={1} paddingTop={1} sx={{ cursor: 'pointer', color: node.loginStatus ? 'primary.darker' : 'secondary.light' }}>
            {node.memberCount > 0 ? <Badge badgeContent={node.memberCount} color="primary" >
              <PeopleAltIcon />
            </Badge>
              : <PersonIcon />}

          </Stack>

        </StyledNode>}>
        {node.members && node.members.length > 0
          ? node.members.map(renderTitles)
          : null}
      </TreeNode>
    );
  };
  const goToListView=() =>{
    dispatch(setShowGraph(false));
  }
  return (
    <Box lg={12} sx={{ flexGrow: 1 }}>
                <Grid item sx={{ overflowY: "scroll", backgroundColor: 'white', maxHeight: '68vh' }}>
    <Grid container >
      <Grid item>
        <Tree
          lineWidth={'2px'}
          lineColor={'green'}
          lineBorderRadius={'10px'}
          label={<StyledNode>{mlmUserObj && mlmUserObj.title}</StyledNode>}
        >
          {memberStruct && memberStruct.map(renderTitles)}
        </Tree>
      </Grid>
      <Grid container justifyContent="flex-start" sx={{paddingTop:5}}>
        <Button variant="contained" onClick={()=>goToListView()}> List View</Button>
      </Grid>
    </Grid>
    </Grid>
    </Box>
  );

}