import { transformDateValue } from 'core/js/FormUtils';
export const getProductcategoryView = (dataList) => {
  
  const transformDataValue = (value, type) =>{
    if (!type.includes('date')) return value;
    return transformDateValue(value);
  }

  let productcategoryList = [];
  dataList.map((data)=>{
  let card = {
            'id': data.id,
            'headingList': [
                            {"fieldLabel":"Agency","fieldValue":transformDataValue(data.agency,"string"), "fieldType":"string"},
                            {"fieldLabel":"title","fieldValue":transformDataValue(data.title,"string"), "fieldType":"string"},
                            {"fieldLabel":"Product Id","fieldValue":transformDataValue(data.productid,"ID"), "fieldType":"ID"},
                            {"fieldLabel":"Category Id","fieldValue":transformDataValue(data.categoryid,"ID"), "fieldType":"ID"},
              
              
            ],
            'dataList': [
                
              ]
          }

          productcategoryList.push(card);
  })
  return productcategoryList;
}