import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import MlmcreditDashboard from '../MlmcreditDashboard';
import { getListAPI as getMlmcreditList } from 'components/mlmcredit/MlmcreditAPI';
import { setMlmcreditList, setMlmcreditDashboardMlmcreditList } from 'store/slice/DmpSlice';
import { getMlmcreditInfoByPartneridAPI,getMlmcreditInfoByMlmuseridAPI } from 'components/mlmcredit/MlmcreditAPI';

import { openDrawer } from './../../../store/slice/MenuSlice';

export default function MlmcreditPageImpl() {
    const dispatch = useDispatch();
    const {mlmUserObj} = useSelector((state)=>state.ecomslice.mlm);
    const {authuserid} = useSelector((state)=>state.headerslice.appuserInfo);
    
    const getMlmcreditListSuccess = (apiResponse) =>{
        dispatch(setMlmcreditDashboardMlmcreditList(apiResponse));}

    useEffect(() => {
        dispatch(openDrawer(false));
        getMlmcreditInfoByMlmuseridAPI(mlmUserObj.id, ()=>{}, getMlmcreditListSuccess);
    },[]);
    

    return (
        < MlmcreditDashboard />
    )

}