import {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {Grid, Box,Typography} from '@mui/material';
import FieldView from 'core/components/layout/FieldView';


export default function MlmcreditListTableHeader(){
  const {mobileView} = useSelector((state)=>state.menu)
    return (
      
      <Grid container direction="row" sx={{ justifyContent: "flex-start", alignItems: "center", width: '100%', padding: 1, backgroundColor: 'secondary.200' }}>

            {/* <HEADER - CELLS/> */}
        
                        {/* <Grid item lg={2} xs={1} ><Typography variant="h5"> Title </Typography></Grid> */}
                        <Grid item lg={2} xs={2} ><Typography variant="h5"> # </Typography></Grid>
                        <Grid item lg={4} xs={4} ><Typography variant="h5"> {mobileView?'Ben':'Beneficiary'} </Typography></Grid>
                        <Grid item lg={2} xs={1} ><Typography variant="h5"> {mobileView?'L':'Level'} </Typography></Grid>
                        <Grid item lg={2} xs={3} ><Typography variant="h5"> {mobileView?'Cre[%]':'Credit[%]'}</Typography></Grid>
                        <Grid item lg={2} xs={2} ><Typography variant="h5"> Credit </Typography></Grid>
                        

      </Grid>
      
    )
}