import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Container, Typography } from '@mui/material';

import { setMlmcreditDashboardMlmcreditList } from 'store/slice/DmpSlice';
import { getMlmcreditInfoByOrderidAPI } from 'components/mlmcredit/MlmcreditAPI';
import { getAPI } from 'components/ordr/OrdrAPI';
import FieldView2 from 'core/components/layout/FieldView2';
import { convertToRS, convertToRSWithTwoDec } from 'core/js/FormUtils';

import MlmcreditListTable from 'components/mlmorder/components/mlmcredit/MlmcreditListTable';

export default function MlmcreditDetails({ orderId }) {
    const dispatch = useDispatch();
    //const { mlmcreditList } = useSelector((state) => state.dmpslice);
    const [orderInfo, setOrderInfo] = useState({});
    const [ creditList, setCreditList ] = useState([]);

    const getMlmcreditListSuccess = (apiResponse) => {
        setCreditList(apiResponse);
    }
    useEffect(() => {
        getMlmcreditInfoByOrderidAPI(orderId, () => { }, getMlmcreditListSuccess);

        getAPI(orderId, setOrderInfo);
    }, []);

    const getDiscount = () => {
        return convertToRSWithTwoDec(orderInfo.discountamount) + " [" + orderInfo.discountpercentile + "%]";
    }
    const getGST = () => {
        return "GST [" + ((orderInfo.gstamount / (orderInfo.total - orderInfo.discountamount)) * 100).toFixed(0) + "%]";
    }
    return (
        <Grid container justifyContent="space-between" sx={{padding:.5}} >
            <Grid item lg={4} xs={12}>
                <Grid container>
                    <Grid item>
                        <Typography variant="h5" color="primary.dark">Order Details</Typography>
                    </Grid>
                    <Grid item  sx={{padding:1, backgroundColor:'secondary.200', borderRadius:2, }}>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center" > 
                        <Grid item lg={7} xs={7} ><FieldView2 label="Total[A]" value={convertToRS(orderInfo.total)} /></Grid>
                        <Grid item lg={5} xs={5} > <FieldView2 label="Discount[B]" value={getDiscount()} /></Grid>
            
                        <Grid item lg={5} xs={5}><FieldView2 label="Effective[A-B]" value={orderInfo.total - orderInfo.discountamount} /></Grid>
                        <Grid item lg={4} xs={4}><FieldView2 label={getGST()} value={convertToRSWithTwoDec(orderInfo.gstamount)} /></Grid>
                        <Grid item lg={3} xs={3}><FieldView2 label="Total" value={orderInfo.grandtotal} /></Grid>
                    </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item lg={7} xs={12} sx={{}}>
                <Grid container direction="column">
                    <Grid item>
                        <Typography variant="h5" color="primary.dark">Credit Details</Typography>
                    </Grid>
                    <Grid item>
                        < MlmcreditListTable creditList={creditList}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

    )

}