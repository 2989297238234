import {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {Grid, Box,Typography,Divider} from '@mui/material';
import FieldView from 'core/components/layout/FieldView';
import CustomerInfo from '../CustomerInfo';


export default function CustomerListTableRow({obj, getActionComponent,handleRowClick}){
  useEffect(()=>{
  },[])
    return (
      
      <>
        <Grid item lg={12} sx={{ width: '100%'}}>
          {/* <ROW1 - CONTAINER/> */}
          <Grid container direction="row" onClick={() => handleRowClick(obj.id)}
            sx={{cursor: 'pointer',
              justifyContent: "flex-start", alignItems: "center",  padding:1,  backgroundColor: obj.expand?'primary.200':'white',
              '&:hover': {
                backgroundColor: 'secondary.lighter', color: 'primary.main'
              },
            }}>
            {/* <ROW1 - CELLS/> */}
            
              <Grid item lg={1} xs={2} ><Typography color="" sx={{ fontWeight: 'light', fontSize: 15 }}>{obj.title}</Typography></Grid>
              <Grid item lg={2} xs={2} ><Typography color="" sx={{ fontWeight: 'light', fontSize: 15 }}>{obj.email}</Typography></Grid>
              <Grid item lg={1} xs={2} ><Typography color="" sx={{ fontWeight: 'light', fontSize: 15 }}>{obj.phone}</Typography></Grid>
              <Grid item lg={1} xs={2} ><Typography color="" sx={{ fontWeight: 'light', fontSize: 15 }}>{obj.inviteeid}</Typography></Grid>
              <Grid item lg={1} xs={2} ><Typography color="" sx={{ fontWeight: 'light', fontSize: 15 }}>{obj.status}</Typography></Grid>
              <Grid item lg={1} xs={2} ><Typography color="" sx={{ fontWeight: 'light', fontSize: 15 }}>{obj.customertype}</Typography></Grid>
              <Grid item lg={1} xs={2} ><Typography color="" sx={{ fontWeight: 'light', fontSize: 15 }}>{obj.membercount}</Typography></Grid>

              <Grid item lg={2} xs={2} >
                <Grid container direction="row" sx={{ justifyContent: "flex-end", width: '100%', }}>
                  {getActionComponent(obj)}
                </Grid>
              </Grid>
            
          </Grid>
        </Grid>
        <Grid item lg={12} sx={{ width: '100%' }}></Grid>
        {obj.expand ?
        <Grid container direction="row">
          <Grid item sx={{ width: '100%' }}>
            < CustomerInfo  customerObj={obj} />
          </Grid>
          <Grid item sx={{ width: '100%' }}>
            <Divider />
          </Grid>
        </Grid>
        : <Grid container direction="row">
          <Grid item sx={{ width: '100%' }}>
            <Divider />
          </Grid>
        </Grid>}
      </>
      
    )
}