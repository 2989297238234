import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";

import Alert from '@mui/material/Alert';
import { Button, Divider,TextField, Grid, Typography, Box,CircularProgress } from "@mui/material"

import {getAddressStruct, setData} from './AddressStruct'
import { updateTextValue, handleSubmit,updateValue,updateSelectedValue} from '../../js/FormUtils'
import {createAPI, updateAPI, getAPI, updateEditableAPI} from './AddressAPI';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {validMobileNumberEntry, validNameEntry, validEmailEntry} from '../../../core/js/FormValidation';

import { useSearchParams } from "react-router-dom";
import { useDispatch,useSelector } from 'react-redux';
import { updateAddressObj,addAddressObj, setShowEditForAddress, setAddressAddAddressModal} from 'store/slice/EcomSlice';
import { setApiInProgress } from 'store/slice/HeaderSlice';
import { CoreContext } from '../../context/CoreContext';



export default function AddressEditFormImpl(){
    const dispatch = useDispatch();
    const { setNotification, lastURL } = React.useContext(CoreContext);
    const [formValues, setFormValues] = useState(getAddressStruct);
    const [showError, setShowError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('Something went wrong');
    const [dataLoaded, setDataLoaded] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    
    const {addressDashboard} = useSelector((state)=>state.ecomslice);
    const [addressId, setAddressId] = useState(addressDashboard.selectedId);
    const { businessid,id } = useSelector((state) => state.headerslice.userprofileInfo)
        
    const {stateList} = useSelector((state)=>state.ecomslice);
    const handleDynamicSelectedValueForstateid = (e) =>{
        updateSelectedValue(formValues, setFormValues,e, 
            'statename', e.target.value==0?'Select Item':stateList.find(obj => obj.id === e.target.value).title);
    }    
    const {addresstypeList} = useSelector((state)=>state.ecomslice);
    const handleDynamicSelectedValueForaddresstypeid = (e) =>{
        updateSelectedValue(formValues, setFormValues,e, 
            'addresstypename', e.target.value==0?'Select Item':addresstypeList.find(obj => obj.id === e.target.value).title);
    }

    const {apiInProgress} = useSelector((state)=>state.headerslice)
    useEffect(()=>{
    },[apiInProgress])

    useEffect(() => {
        if (addressId!==-1) {
            if (dataLoaded) return;
            getAPI(addressId, loadForm);
        }
        else {
           // formValues.<<CONTEXT>>.value=searchParams.get('<<CONTEXT>>');
            setDataLoaded(true);
        }

        setFormValues({
            ...formValues,
            ['businessid']: {
                ...formValues['businessid'],
                value: businessid
            },
            ['partnerid']: {
                ...formValues['partnerid'],
                value: id
            }
        })
    },[]);

    const loadForm = (data)=>{
        setData(data, formValues, setFormValues);
        setDataLoaded(true);
    }
    const handleRadioChange = (name, value) => {
        setShowError(false);
        console.log(value+" == "+getRadioAPIvalue(value))
        updateValue(formValues, setFormValues, name, getRadioAPIvalue(value));
    }
    const getRadioValue = (value) => {
        if (value ) 
            return "yes"
        else 
            return "no"
    }
    const getRadioAPIvalue = (value) => {
        if (value ==="yes") 
            return true
        else 
            return false
    }
    const handleTextChange = (e) => {
        setShowError(false);
        updateTextValue(formValues, setFormValues,e);
    }
    const handleAddressChange = (e) => {
        setShowError(false);
        const sanitizedText = e.target.value.replace(/\n/g, " ");
        updateValue(formValues, setFormValues,formValues.title.name, sanitizedText);
    }
    const handleDoubleChange = (e) => {
        setShowError(false);
        const parsed = parseFloat(e.target.value);
        if (!isNaN(parsed) && e.target.value == parsed)
         updateTextValue(formValues, setFormValues,e);
    }
    const handleNameChange = (e) =>{
        setShowError(false);
        
       if (validNameEntry(e))
        updateTextValue(formValues, setFormValues,e);
    }
    const handleMobileChange = (e) => {
        setShowError(false);
        
       if (validMobileNumberEntry(e))
            updateTextValue(formValues, setFormValues,e);
    }
    const handleEmailidChange = (e) => {
        setShowError(false);
        
       if (validEmailEntry(e))
            updateTextValue(formValues, setFormValues,e);
    }

    const createSuccessCallback = (obj) =>{
         dispatch(setApiInProgress(false));
        dispatch(addAddressObj(obj));
        setNotification('Record has been cretaed successfully !!!');
        dispatch(setShowEditForAddress(false));
        dispatch(setAddressAddAddressModal(false));
    }
    const updateSuccessCallback = (obj) =>{
         dispatch(setApiInProgress(false));
        dispatch(updateAddressObj(obj));
        setNotification('Record with id['+addressId+'] has been updated successfully !!!');
        dispatch(setAddressAddAddressModal(false));
        
    }
    const failuerCallback = (errorMsg) => {
         dispatch(setApiInProgress(false));
        setErrorMsg(errorMsg);
        setShowError(true);
    }
    const callCreateAPI = (formValues, successCallBack, failuerCallback) =>{
        dispatch(setApiInProgress(true));
        createAPI(formValues, successCallBack, failuerCallback);
    }
    const callUpdateAPI = (id,formValues, updateSuccessCallback, failuerCallback) =>{
        dispatch(setApiInProgress(true));
        updateAPI(id,formValues, updateSuccessCallback, failuerCallback);
    }
    const onSubmit=(e)=>{
        handleSubmit(e,addressId, formValues, setFormValues, createSuccessCallback, updateSuccessCallback, failuerCallback, callCreateAPI, callUpdateAPI);
    }
    const setDate = (fieldName, value) => {
        var date = new Date(value);
        
        const day = date.getDate();
        const dayStr = day < 10 ? '0' + day : day.toString();

        const month = date.getMonth() + 1;
        const monthStr = month < 10 ? '0' + month : month.toString();
        var dateStr =date.getFullYear()+ "-" +  monthStr + "-" + dayStr + "T00:00:00.000Z";
        updateValue(formValues, setFormValues, fieldName, dateStr);
    }
    return (
        <form  noValidate onSubmit={onSubmit} >
            <Grid container direction="column" padding={.25}>
                {showError? <Alert severity="error">{errorMsg}</Alert>: ''}
                <Box sx={{ flexGrow: 1 }}>
                    <Grid item sx={{overflowY: "scroll", backgroundColor:'white',maxHeight:440}}>
                        <Grid container direction="column" padding={.25}>
                            <Grid item>
                                <Typography variant='h4'>{addressId===-1?'Add':'Edit'} Address Information </Typography>
                                <Divider/>
                            </Grid>
                            <Grid height='25px' ></Grid>
                            {dataLoaded?
                                <Grid container display="flex" spacing={2} direction="row" justifyContent="space-between" alignItems="center"> 
                                                            
                            <Grid item lg={4} xs={12} sx={{display:'none'}}>
                                <TextField size="Normal"
                                    placeholder={formValues.partnerid.placeholder}
                                    required={formValues.partnerid.required}
                                    label={formValues.partnerid.label}
                                    name={formValues.partnerid.name}
                                    value={formValues.partnerid.value}
                                    onChange={handleTextChange}
                                    error={formValues.partnerid.error}
                                    helperText={formValues.partnerid.error && formValues.partnerid.errorMessage}
                                    sx={{width:'100%'}}
                                />
                            </Grid>
                                   
                            <Grid item lg={2} xs={12} sx={{display:'none'}}>
                                <FormControl sx={{  width:'100%'}} size="Normal">
                                    <InputLabel id="demo-simple-select-autowidth-label">Address Type</InputLabel>
                                    <Select
                                            labelId="demo-simple-select-autowidth-label"
                                            id="demo-simple-select-autowidth"
                                            label="Address Type"
                                            name={formValues.addresstypeid.name}
                                            value={formValues.addresstypeid.value}
                                            onChange={handleDynamicSelectedValueForaddresstypeid}
                                            
                                            error={formValues.addresstypeid.error}
                                            helpertext={formValues.addresstypeid.error && formValues.addresstypeid.errorMessage}
                                            >
                                            <MenuItem key={0} value="0">Select Item</MenuItem>
                                            {
                                                addresstypeList && addresstypeList.map((item, i) => <MenuItem key={i} value={item.id}>{item.title}</MenuItem>) 
                                            }
                                        </Select>
                                </FormControl> 
                            </Grid>               
                            <Grid item lg={2} xs={12} sx={{display:'none'}}>
                                <FormControl sx={{  width:'100%'}} size="Normal">
                                    <InputLabel id="demo-simple-select-autowidth-label">State</InputLabel>
                                    <Select
                                            labelId="demo-simple-select-autowidth-label"
                                            id="demo-simple-select-autowidth"
                                            label="State"
                                            name={formValues.stateid.name}
                                            value={formValues.stateid.value}
                                            onChange={handleDynamicSelectedValueForstateid}
                                            
                                            error={formValues.stateid.error}
                                            helpertext={formValues.stateid.error && formValues.stateid.errorMessage}
                                            >
                                            <MenuItem key={0} value="0">Select Item</MenuItem>
                                            {
                                                stateList && stateList.map((item, i) => <MenuItem key={i} value={item.id}>{item.title}</MenuItem>) 
                                            }
                                        </Select>
                                </FormControl> 
                            </Grid>                        
                            <Grid item lg={3} xs={12} sx={{display:'none'}}>
                                <TextField size="Normal"
                                    placeholder={formValues.districtname.placeholder}
                                    required={formValues.districtname.required}
                                    label={formValues.districtname.label}
                                    name={formValues.districtname.name}
                                    value={formValues.districtname.value}
                                    onChange={handleTextChange}
                                    error={formValues.districtname.error}
                                    helperText={formValues.districtname.error && formValues.districtname.errorMessage}
                                    sx={{width:'100%'}}
                                />
                            </Grid>
                                            
                            <Grid item lg={3} xs={12}>
                                <TextField size="Normal"
                                    placeholder={formValues.cityname.placeholder}
                                    required={formValues.cityname.required}
                                    label={formValues.cityname.label}
                                    name={formValues.cityname.name}
                                    value={formValues.cityname.value}
                                    onChange={handleTextChange}
                                    error={formValues.cityname.error}
                                    helperText={formValues.cityname.error && formValues.cityname.errorMessage}
                                    sx={{width:'100%'}}
                                />
                            </Grid>
                                            
                            <Grid item lg={2} xs={12}>
                                <TextField size="Normal"
                                    placeholder={formValues.pincode.placeholder}
                                    required={formValues.pincode.required}
                                    label={formValues.pincode.label}
                                    name={formValues.pincode.name}
                                    value={formValues.pincode.value}
                                    onChange={handleTextChange}
                                    error={formValues.pincode.error}
                                    helperText={formValues.pincode.error && formValues.pincode.errorMessage}
                                    sx={{width:'100%'}}
                                />
                            </Grid>
                                            
                            <Grid item lg={12} xs={12}>
                                <TextField size="Normal"
                                    multiline
                                    rows={3}
                                    placeholder={formValues.title.placeholder}
                                    required={formValues.title.required}
                                    label="Address"
                                    name={formValues.title.name}
                                    value={formValues.title.value}
                                    onChange={handleAddressChange}
                                    error={formValues.title.error}
                                    helperText={formValues.title.error && formValues.title.errorMessage}
                                    sx={{width:'100%'}}
                                />
                            </Grid>
                        
                                    <Grid item lg={4} xs={12} sx={{display:'none'}}>
                                        <TextField size="Normal"
                                            placeholder={formValues.businessid.placeholder}
                                            required={formValues.businessid.required}
                                            label={formValues.businessid.label}
                                            name={formValues.businessid.name}
                                            value={formValues.businessid.value}
                                            onChange={handleTextChange}
                                            error={formValues.businessid.error}
                                            helpertext={formValues.businessid.error && formValues.businessid.errorMessage}
                                            sx={{ width: '100%',  }}
                                        />
                                    </Grid> 
                                </Grid>
                            :<></>}
                        </Grid>
                    </Grid>
                </Box>
                <Grid height='25px' ></Grid>
                <Grid padding={1}>
                    <Divider/>
                </Grid>
                <Grid container direction="row" justifyContent="flex-end"  >
                {   apiInProgress?<CircularProgress/>:
                    <>
                        <Button variant="outlined" type="submit" >{addressId===-1?'Add':'Save'}</Button>&nbsp;&nbsp;&nbsp;
                        <Button variant="outlined" onClick={()=>{dispatch(setShowEditForAddress(false));dispatch(setAddressAddAddressModal(false))}}>Close</Button>
                    </>
                }
                </Grid>
            </Grid>
        </form>
    );
};