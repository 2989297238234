import { createSlice } from '@reduxjs/toolkit';

export const AppSlice = createSlice({
    name: "app",
    initialState: {
        orderDashboard:{
          filteredTaskId:0
        }
      },
    reducers: {
      setFilteredTaskId : (state, action) =>{
        state.orderDashboard.filteredTaskId=action.payload;
    }
      }
});
export const { setFilteredTaskId } = AppSlice.actions;
export default AppSlice.reducer;