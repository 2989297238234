import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs'; 
import {Typography} from '@mui/material';
import { useState, useEffect } from 'react';

export default function MyDate({label, fieldName, defaultValue, errStr, setDate}) {
  const [showErr, setShowErr] = useState(false);

  useEffect(()=>{
    if (!defaultValue){
      setShowErr(true);
    }
  },[])

  const handleDateChange = (value) =>{
    setShowErr(false);
    setDate(fieldName, value);
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker 
          label={label} 
          onChange={(value)=>handleDateChange(value)}
          value={dayjs(defaultValue)}
          sx={{width: '100%'}}
        />
        {showErr?
        <Typography variant="h6" sx={{color:'red'}}>{errStr}</Typography>
        :<></>}
    </LocalizationProvider>
  );
}