import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Grid, Box, Typography, Divider } from '@mui/material';
import FieldView from 'core/components/layout/FieldView';
import ProductInfo from './ProductInfo';


export default function ProductListTableRow({ obj, getActionComponent, handleRowClick }) {
  const { rolename } = useSelector((state) => state.headerslice.userprofileInfo)
  useEffect(() => {
  }, [])
  return (

    <>
      <Grid container direction="row">
        <Grid item sx={{ width: '100%', }}>
          < ProductInfo obj={obj} />
        </Grid>
      </Grid>

      <Grid container direction="row" justifyContent="space-between"
          sx={{
            justifyContent: "flex-start", alignItems: "center", 
          }}>
          {/* <Grid item lg={10} xs={10} sx={{paddingLeft:1}}>
            <Typography sx={{ fontSize: 18, color: 'primary.darker' }}>{obj.title}</Typography>
          </Grid> */}
          <Grid item lg={2} xs={2}  >
            {getActionComponent(obj)}
          </Grid>

        </Grid>
      
    </>

  )
}