import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Grid, Box, Typography, Button, Tooltip } from '@mui/material';
import Cookies from "js-cookie";
import { convertToRS } from 'core/js/FormUtils';

import FieldView from 'core/components/layout/FieldView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

import { addAnonymousCartItemAPI } from 'pages/dashboard/LandingPageAPI';
import { refreshCart, setCartObj } from 'store/slice/EcomSlice';
import { setProductManageProductModal } from 'store/slice/DmpSlice';

import {
  Badge,

  IconButton,
  Popper,
  useMediaQuery
} from '@mui/material';

export default function ProductDetails({ obj }) {
  const dispatch = useDispatch();
  const [expand, setExpand] = useState(false);
  const { rolename } = useSelector((state) => state.headerslice.userprofileInfo);
  const [apiInProgress, setApiInProgress] = useState(false);
  const { cartitemList, refreshOrder } = useSelector((state) => state.ecomslice);

  useEffect(() => {
  }, [cartitemList, refreshOrder]);


  const addProductToCartSuccess = (apiResponse) => {
    Cookies.set("anonymousId", apiResponse.cartInfo.anonymousid);
    //Cookies.set("cartId",apiResponse.cartInfo.id);
    if (localStorage.getItem("JWT") === null)
      localStorage.setItem("JWT", apiResponse.jwt)
    dispatch(setCartObj(apiResponse.cartInfo));
    dispatch(refreshCart());
    setApiInProgress(false);
  }
  const addProductToCartFailure = (errMsg) => {
    setApiInProgress(false);
    console.log('errMsg=>' + errMsg);
  }
  const addProductToCart = () => {
    setApiInProgress(true);
    addAnonymousCartItemAPI({
      "anonymousId": Cookies.get("anonymousId"),
      "productCartItemInfo": { "quantity": "1", "productid": obj.id }
    }, addProductToCartSuccess, addProductToCartFailure)
  }
  const getQuantity = (productId) => {
    if (!cartitemList || cartitemList.length === undefined) return 0;
    let index = cartitemList.map(cartItemObj => cartItemObj.productid).indexOf(productId);
    if (index !== -1) return cartitemList[index].quantity

    return 0;
  }
  const openProductDetails = () => {
    dispatch(setProductManageProductModal(true));
  }
  return (

    <Grid container direction="column" >
      <Grid item lg={12} xs={12}>
        <Grid container direction="row" justifyContent="space-between" sx={{ width: '100%', }} >
          <Grid item lg={3} xs={12} sx={{ backgroundColor: 'white', }}>
            <img src={obj.imagepath} style={{ backgroundColor: 'white', width: '100%', height: '220px', borderRadius: 10, }} />
          </Grid>
          <Grid item lg={9} xs={12} sx={{ backgroundColor: 'white', padding: 1 }}>
            <Grid container direction="column">

              <Box lg={12} sx={{ flexGrow: 1 }}>
                <Grid item lg={12} xs={12} sx={{ overflowY: "scroll", backgroundColor: 'white', maxHeight: '40vh' }}>
                  <Typography variant="h6" color="textSecondary" fontSize={17} gutterBottom >{obj.description}</Typography>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid lg={12} xs={12} item sx={{ paddingTop: 1 }}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{}}>
          <Grid item xs="auto">
            <Box sx={{
              backgroundColor: 'primary.darker',
              color: 'white',  // Ensure text is readable
              padding: '8px 16px',  // Adjust padding for a proper box shape
              borderRadius: '8px', // Rounded corners
              display: 'inline-block' // Prevent full-width stretching
            }}>
              <Typography variant="h5">{convertToRS(obj.price)}</Typography>
            </Box>
          </Grid>

          {rolename === 'DMP_PRODUCT_MGR' ? <></> :
            <Grid item lg={12} xs="auto" sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => addProductToCart()}
                sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
              >
                <IconButton size="small">
                  <Badge badgeContent={getQuantity(obj.id)} color="secondary">
                    <Tooltip title="Add to Cart">
                      <AddShoppingCartIcon sx={{ cursor: "pointer", color: 'white' }} />
                    </Tooltip>
                  </Badge>
                </IconButton>
                Add to Cart
              </Button>

            </Grid>
          }
        </Grid>
      </Grid>




    </Grid>
  )
}