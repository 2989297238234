import { Typography, Box, Grid } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

function SuccessMessage({message}) {
    return (
        <>
        {message!==''
            ?
                <Box display="flex" alignItems="center" sx={{backgroundColor:'white', borderRadius:2}} padding={2}>
                    <Grid container alignItems="center" justifyContent="center" sx={{width:'100%'}}>
                        <Grid item ><CheckCircleOutlineIcon color="success" /></Grid>
                        <Grid item sx={{paddingLeft:1}}>
                            <Typography color="green" variant="body1">{message}</Typography>
                        </Grid>
                    </Grid>
                </Box>
            :<></>
        }
    </>
    );
}

export default SuccessMessage;