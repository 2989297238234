import { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
// material-ui
import { useTheme } from '@mui/material/styles';
import { getJWTAPI } from 'pages/dashboard/LandingPageAPI';
import Cookies from "js-cookie";
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import {  } from 'store/slice/EcomSlice';
import { setCartCount,setCartitemList,setCartObj } from 'store/slice/EcomSlice';
import { setuserprofileInfo,setAppuserInfo } from 'store/slice/HeaderSlice';
import { getAnonymousCartAPI } from 'core/components/ecom/EcomAPI';
import { setSelectedBottomMenu } from 'store/slice/EcomSlice';

import {
  Badge,
  Box,
  IconButton,
  Popper,
  useMediaQuery
} from '@mui/material';


// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const Cart = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { userprofileInfo, refreshOrder,appuserInfo,appId } = useSelector((state) => state.headerslice);
  const { cartObj, cartCount, refresh } = useSelector((state) => state.ecomslice.cart);
  
  // -------------------- ** ----------------
  useEffect(()=>{
    //getJWTAPI({"appId":22},getJWTsuccess, ()=>{});
    
   /* if (userprofileInfo.email!==undefined)
      getCartInfoByPartneridandstatusAPI(userprofileInfo.email, "DRAFT", () => { }, getCartSuccess);
    */
  },[userprofileInfo.email])

  useEffect(()=>{
    //f (userprofileInfo.email===undefined){
    if (localStorage.getItem("JWT") === null) {
      getJWTAPI({"appId":appId},getJWTsuccess, ()=>{});
    }
  },[])

// -------------------- ______ ----------------
  useEffect(()=>{
    if (Cookies.get('anonymousId')) {
      getAnonymousCartAPI({
          "anonymousId":Cookies.get('anonymousId')}, getAnonymousCartItemsSuccess, () => { })
    } 
  },[refresh]) // On publishinging of Refresh cart event
  const getJWTsuccess = (apiResponse)=>{
    //Cookies.set("JWT", apiResponse.jwt);
    localStorage.setItem("JWT", apiResponse.jwt);
    dispatch(setuserprofileInfo(apiResponse.userprofileInfo));
  }

  const getAnonymousCartItemsSuccess = (apiResponse) =>{
    dispatch(setCartObj(apiResponse.cartInfo))
    dispatch(setCartCount(apiResponse.productcartitemInfoList.list.length));
    dispatch(setCartitemList(
        apiResponse.productcartitemInfoList.list.sort((a, b) => a.title.localeCompare(b.title))
    ))
}
    


  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  useEffect(()=>{

  },[cartCount]);
  
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const getCartItemListSuccess = (apiResponse) => {
    dispatch(setCartCount(apiResponse.length));

    dispatch(setCartitemList(
        apiResponse.sort((a, b) => a.title.localeCompare(b.title))
    ))
}
  const iconBackColorOpen = 'grey.300';
  const iconBackColor = 'grey.100';

  const redirectToCartPage = ()=>{
    //navigate('/draftcart');
    dispatch(setSelectedBottomMenu('CART'));
  }
  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        disableRipple
        color="secondary"
        sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={()=>redirectToCartPage()}
      >
        <Badge badgeContent={cartCount} color="primary">
          <ShoppingCartCheckoutIcon />
        </Badge>
        
      </IconButton>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? -5 : 0, 9]
              }
            }
          ]
        }}
      >
      </Popper>
    </Box>
  );
};

export default Cart;
