import React, { useState, useEffect } from 'react'

import {Grid} from '@mui/material'

import ComponentContainer from '../../core/template/ComponentContainer';
import {CoreContext} from "../../core/context/CoreContext";
import ProductcategoryEditForm from './ProductcategoryEditForm';
import ProductcategoryList from './ProductcategoryList';
import { useSelector, useDispatch } from 'react-redux';

import Notification from '../../core/components/Notification';

import ProductcategoryListView from './ProductcategoryListView'


export default function ProductcategoryDashboard(){
  const { setKeyValue, getKeyValues, keyvalues } = React.useContext(CoreContext);
  const {showEdit} = useSelector((state)=>state.dmpslice.productcategoryDashboard);
  const [ productcategoryId, setProductcategoryId] = React.useState('');
  const [reloadProductcategoryList, setReloadProductcategoryList] = useState(true);

  return (
      <>
      <Notification/>
      {showEdit?
        <ComponentContainer containedComponent = {
          <ProductcategoryEditForm 
            setReloadProductcategoryList={setReloadProductcategoryList}
            productcategoryId={productcategoryId}
            />}/>:
            <>
            <Grid container direction="column" >
              <Grid item>
                    {
                      <ProductcategoryListView 
                      setProductcategoryId = {setProductcategoryId}/>
                    }
              </Grid>
            </Grid>
            </>
      }
    </>


  )

}
