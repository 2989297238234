import {useState, useEffect} from 'react';
import ImageGalleryHolder from 'core/components/image-gallery/ImageGalaryHolder';
import { Grid, Button, Typography } from '@mui/material';
import ProductEditFormImpl from 'components/product/ProductEditFormImpl';

export default function EditProductActionImpl(){

    return (
        <Grid container direction="row" sx={{width:'100%'}}>
            <Grid item lg={8} md={8} sm={12} xs={12}>
                <ImageGalleryHolder /> 
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12} sx={{paddingLeft:2}}>
                <ProductEditFormImpl/>
            </Grid>

        </Grid>
    )
}