import React, { useState, useEffect } from 'react'

import Grid from '@mui/material/Grid';

import MlmusertypeFilterCriteria from './MlmusertypeFilterCriteria'
import MlmusertypeList from './MlmusertypeList'
import MyPageContainer from '../../core/template/MyPageContainer';
import {getListAPI} from './MlmusertypeAPI';
import {transformToCardFormat} from '../../core/js/FormUtils'
import {getPathVariable} from '../../core/js/UrlUtils';

import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {setMlmusertypeDashboardMlmusertypeList } from '../../store/slice/DmpSlice';

export default function MlmusertypeListView({setShowEdit,setMlmusertypeId}){
    const {filterCriteria} = useSelector((state)=>state.dmpslice.mlmusertypeDashboard);   

    const [filteredMlmusertypeList, setFilteredMlmusertypeList] = React.useState([]);
    const [cardList, setCardList] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const { showfilter } = useSelector((state) => state.confslice);
    const { mlmusertypeDashboard } = useSelector((state) => state.dmpslice);
    
    function dataFetchCallBack(){
    }

    useEffect(()=>{
      if (mlmusertypeDashboard.mlmusertypeList.length>-1){
        setFilteredMlmusertypeList(mlmusertypeDashboard.mlmusertypeList);
        transformToCardFormat(mlmusertypeDashboard.mlmusertypeList, setCardList);
      }
    },[mlmusertypeDashboard.mlmusertypeList])

    useEffect(()=>{
          transformToCardFormat(filteredMlmusertypeList, setCardList);
      },[filteredMlmusertypeList])

    useEffect(()=>{
        if (filterCriteria.title==='' ){
            setFilteredMlmusertypeList(mlmusertypeDashboard.mlmusertypeList);
            transformToCardFormat(mlmusertypeDashboard.mlmusertypeList, setCardList);

        } else {
            setFilteredMlmusertypeList(
                mlmusertypeDashboard.mlmusertypeList
                    .filter(mlmusertype=> mlmusertype.title.toString().toUpperCase().includes(filterCriteria.title.toString().toUpperCase()))

                
            )
            

            //transformToCardFormat(mlmusertypeDashboard.filteredMlmusertypeList, setCardList);
        }
      },[filterCriteria])
  
     return(
        <Grid container direction="column" >
            <Grid item lg={12}>
                <MlmusertypeList
                    setShowEdit={setShowEdit}
                    setMlmusertypeId = {setMlmusertypeId}
                    tableList={filteredMlmusertypeList}
                    cardList={cardList}
                    />
          </Grid>
      </Grid>
    )
}