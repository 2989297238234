import React, { useState, useEffect } from 'react'

import Grid from '@mui/material/Grid';

import CategoryFilterCriteria from './CategoryFilterCriteria'
import CategoryList from './CategoryList'
import MyPageContainer from '../../core/template/MyPageContainer';
import {getListAPI} from './CategoryAPI';
import {transformToCardFormat} from '../../core/js/FormUtils'
import {getPathVariable} from '../../core/js/UrlUtils';

import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {setCategoryDashboardCategoryList } from '../../store/slice/DmpSlice';

export default function CategoryListView({setShowEdit,setCategoryId}){
    const {filterCriteria} = useSelector((state)=>state.dmpslice.categoryDashboard);   

    const [filteredCategoryList, setFilteredCategoryList] = React.useState([]);
    const [cardList, setCardList] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const { showfilter } = useSelector((state) => state.confslice);
    const { categoryDashboard } = useSelector((state) => state.dmpslice);
    
    function dataFetchCallBack(){
    }

    useEffect(()=>{
      if (categoryDashboard.categoryList.length>-1){
        setFilteredCategoryList(categoryDashboard.categoryList);
        transformToCardFormat(categoryDashboard.categoryList, setCardList);
      }
    },[categoryDashboard.categoryList])

    useEffect(()=>{
          transformToCardFormat(filteredCategoryList, setCardList);
      },[filteredCategoryList])

    useEffect(()=>{
        if (filterCriteria.title==='' ){
            setFilteredCategoryList(categoryDashboard.categoryList);
            transformToCardFormat(categoryDashboard.categoryList, setCardList);

        } else {
            setFilteredCategoryList(
                categoryDashboard.categoryList
                    .filter(category=> category.title.toString().toUpperCase().includes(filterCriteria.title.toString().toUpperCase()))

                
            )
            

            //transformToCardFormat(categoryDashboard.filteredCategoryList, setCardList);
        }
      },[filterCriteria])
  
     return(
        <Grid container direction="column" >
            <Grid item lg={12}>
                <CategoryList
                    setShowEdit={setShowEdit}
                    setCategoryId = {setCategoryId}
                    tableList={filteredCategoryList}
                    cardList={cardList}
                    />
          </Grid>
      </Grid>
    )
}