import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Grid, Box, Typography, Button, Tooltip, CircularProgress } from '@mui/material';
import Cookies from "js-cookie";
import { convertToRS } from 'core/js/FormUtils';

import FieldView from 'core/components/layout/FieldView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

import { addAnonymousCartItemAPI } from 'pages/dashboard/LandingPageAPI';
import { refreshCart, setCartObj } from 'store/slice/EcomSlice';
import { setProductManageProductModal, updateProductObj } from 'store/slice/DmpSlice';

import {
  Badge,

  IconButton,
  Popper,
  useMediaQuery
} from '@mui/material';

export default function ProductInfo({ obj }) {
  const dispatch = useDispatch();
  const [expand, setExpand] = useState(false);
  const [moreClicked, setMoreClicked] = useState(false);
  const { rolename } = useSelector((state) => state.headerslice.userprofileInfo);
  const [apiInProgress, setApiInProgress] = useState(false);
  const {cartitemList, refreshOrder} = useSelector((state)=>state.ecomslice);

  useEffect(() => {
  }, [cartitemList,refreshOrder]);

  const getDescription = (description) => {
    if (moreClicked) return description;

    if (description.length > 45) {
      return description.substr(0, 44)
    } else
      return description;
  }
  const handleMoreClicked = () => {
    setMoreClicked(!moreClicked);
  }
  const addProductToCartSuccess = (apiResponse) => {
    Cookies.set("anonymousId", apiResponse.cartInfo.anonymousid);
    //Cookies.set("cartId",apiResponse.cartInfo.id);
    if (localStorage.getItem("JWT") === null)
      localStorage.setItem("JWT", apiResponse.jwt)
    dispatch(setCartObj(apiResponse.cartInfo));
    dispatch(refreshCart());
    setApiInProgress(false);
  }
  const addProductToCartFailure = (errMsg) => {
    setApiInProgress(false);
    console.log('errMsg=>' + errMsg);
  }
  const addProductToCart = () => {
    setApiInProgress(true);
    addAnonymousCartItemAPI({
      "anonymousId": Cookies.get("anonymousId"),
      "productCartItemInfo": { "quantity": "1", "productid": obj.id }
    }, addProductToCartSuccess, addProductToCartFailure)
  }
  const getQuantity = (productId) =>{
    if (!cartitemList  || cartitemList.length===undefined) return 0;
    let index = cartitemList.map(cartItemObj=>cartItemObj.productid).indexOf(productId);
    if(index!==-1)  return cartitemList[index].quantity

    return 0;
  }
  const openProductDetails = (obj) =>{
    dispatch(updateProductObj(obj))
   dispatch(setProductManageProductModal(true));
  }
  return (
    <Grid container direction="column" justifyContent="space-between" sx={{ width: '100%',  }} >
      <Grid item xs={12} lg={4} sx={{ backgroundColor: 'white' }}>
        <Grid container direction="column">
          {!moreClicked ?
            <Grid container lg={12} xs={12} >
              <img  onClick={()=>openProductDetails(obj)} src={obj.imagepath} style={{ cursor:'pointer', backgroundColor: 'white', width: '100%', height: '120px', borderRadius: 10, padding:5 }} />
            </Grid>
            : <></>}
          <Grid item xs={12} lg={4} sx={{padding:.5}}>
            <Typography variant="h6" color="primary.darker">{obj.title}</Typography>
          </Grid>
          <Grid item lg={12} xs={12}>
            <Grid container justifyContent="space-between" sx={{ height: '100%', }}>
              {/* <Grid item >
                <Typography variant="h6" sx={{ fontWeight: 'light', fontSize: 14 }}>{getDescription(obj.description)}<Button onClick={() => handleMoreClicked()}>{moreClicked ? 'Back' : 'More'}</Button></Typography>
              </Grid> */}
              {rolename==='MLM_PARTNER' || rolename==='MLM_MEMBER' || rolename==='MLM_CUSTOMER'|| rolename==='OPS_CUSTOMER' || rolename==='OPS_ANONYMOUS'?
                <Grid container justifyContent="space-between" alignItems="center" sx={{ backgroundColor:'secondary.100', width:'100%', padding:1}}>
                {apiInProgress ? <CircularProgress /> : (
                  <>
                    {/* Price on the left */}
                    <Grid item sx={{ flexGrow: 1 }}>
                      <Typography variant="h6">{convertToRS(obj.price)}</Typography>
                    </Grid>
              
                    {/* Cart icon on the right */}
                    <Grid item>
                      <IconButton onClick={() => addProductToCart()}>
                        <Badge badgeContent={getQuantity(obj.id)} color="primary">
                          <Tooltip title="Add to Cart">
                            <AddShoppingCartIcon sx={{ cursor: "pointer", color: 'secondary.darker' }} />
                          </Tooltip>
                        </Badge>
                      </IconButton>
                    </Grid>
                  </>
                )}
              </Grid> :
                <Grid container lg={12} xs={12} justifyContent="flex-end" sx={{ width: '100%' }}>
                  <Typography sx={{ backgroundColor: 'primary.darker', color: 'white', padding: 1, borderRadius: 2 }}>{convertToRS(obj.price)}</Typography>
                </Grid>
              }
            </Grid>


          </Grid>

        </Grid>
      </Grid>
    </Grid>
  )
}