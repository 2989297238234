import { createSlice } from '@reduxjs/toolkit';

export const MenuSlice = createSlice({
    name: "menu",
    initialState: {
        openItem: ['dashboard'],
        defaultId: 'dashboard',
        openComponent: 'buttons',
        drawerOpen: false,
        componentDrawerOpen: true,
        mobileView: false,
        showProfileMenu: true,
        bottomMenu:{
          selectedMenu:"HOME"
        },
      },
    reducers: {
        activeItem(state, action) {
          state.openItem = action.payload.openItem;
        },
    
        activeComponent(state, action) {
          state.openComponent = action.payload.openComponent;
        },
    
        openDrawer(state, action) {
          state.drawerOpen = action.payload.drawerOpen;
        },
    
        openComponentDrawer(state, action) {
          state.componentDrawerOpen = action.payload.componentDrawerOpen;
        },
        setMobileView(state, action) {
          state.mobileView = action.payload.mobileView;
        },
        setShowProfileMenu: (state, action)=>{
          state.showProfileMenu = action.payload;
        },
        setSelectedBottomMenu : (state, action) =>{
            state.bottomMenu.selectedMenu=action.payload;
        }
    
      }
});
export const { setSelectedBottomMenu, setShowProfileMenu, activeItem, activeComponent, openDrawer, openComponentDrawer,setMobileView } = MenuSlice.actions;
export default MenuSlice.reducer;