
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';

var AutoproducttemplatesResourcePrefix = "http://localhost:8082/ops-core-api/anonymous/autoproducttemplates";
var AutoproducttemplatevariantsResourcePrefix = "http://localhost:8082/ops-core-api/anonymous/autoproducttemplatevariants";
var AutoproducttemplatecolorsResourcePrefix = "http://localhost:8082/ops-core-api/autoproducttemplatecolors";
var AutoproducttemplatecolorsResourcePrefixAnonymous = "http://localhost:8082/ops-core-api/anonymous/autoproducttemplatecolors";
var AutotypesResourcePrefix = "http://localhost:8082/ops-core-api/anonymous/autotypes";
var ProductcolorsResourcePrefix = "http://localhost:8082/ops-core-api/anonymous/productcolors";

//var opsCoreWebServicesPrefix = "http://localhost:8082/ops-core-service/anonymous";
var opsCoreWebServicesPrefix = "https://appmachine.in/ops-core-service/anonymous";
var CartitemsResourcePrefix = "https://appmachine.in/ops-core-api/anonymous/cartitems";

var opsCartMgmtPrefix = "https://appmachine.in/ops-core-service";

export function getAutoPorductTemplateListAPI(setAutoproducttemplateList, dataFetchCallBack){
    const token = localStorage.get("JWT");
    const requestId = uuidv4().split("-")[4];
    const requestOptions = {
      method: 'GET',
      headers: {
        'X-Request-ID': requestId,
        //'Authorization': `Bearer ${token}`
    }
    };
    fetch(AutoproducttemplatesResourcePrefix, requestOptions)
        .then((data) => data.json())
        .then((data) => {setAutoproducttemplateList(data.list);dataFetchCallBack(data.list)})
}

export function getAutoProductTemplateVariantInfoByAutoproducttemplateidAPI(autoproducttemplateid, setAutoproducttemplatevariantList, dataFetchCallBack){
    const requestId = uuidv4().split("-")[4];
    const token = Cookies.get("JWT");
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json','X-Request-ID': requestId },
    };
    fetch(AutoproducttemplatevariantsResourcePrefix+"/autoproducttemplateid/"+autoproducttemplateid+"/autoproducttemplateid", requestOptions)
        .then((data) => data.json())
        .then((data) => {
          setAutoproducttemplatevariantList(data.list);
          dataFetchCallBack(data.list)
    })
}
export function getAutoproducttemplatecolorInfoByAutoproducttemplateidAPI(autoproducttemplateid, setAutoproducttemplatecolorList, dataFetchCallBack){
    const requestId = uuidv4().split("-")[4];
    const token = Cookies.get("JWT");
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json','X-Request-ID': requestId },
    };
    fetch(AutoproducttemplatecolorsResourcePrefix+"/autoproducttemplateid/"+autoproducttemplateid+"/autoproducttemplateid", requestOptions)
        .then((data) => data.json())
        .then((data) => {
          setAutoproducttemplatecolorList(data.list);
          dataFetchCallBack(data.list)
    })
}
export function getListAPI(setAutotypeList, dataFetchCallBack){
  const token = Cookies.get("JWT");
  const requestId = uuidv4().split("-")[4];
  const requestOptions = {
    method: 'GET',
    headers: {
      'X-Request-ID': requestId,
  }
  };
  fetch(AutotypesResourcePrefix, requestOptions)
      .then((data) => data.json())
      .then((data) => {setAutotypeList(data.list);dataFetchCallBack(data.list)})
}

export function getAutoProductTemplateVariantAPI(setAutoproducttemplatevariantList, dataFetchCallBack){
  const requestId = uuidv4().split("-")[4];
  const requestOptions = {
    method: 'GET',
  };
  fetch(AutoproducttemplatevariantsResourcePrefix, requestOptions)
      .then((data) => data.json())
      .then((data) => {setAutoproducttemplatevariantList(data.list);dataFetchCallBack(data.list)})
}

export function getProductTemplateColorListAPI(setAutoproducttemplatecolorList, dataFetchCallBack){
  const requestOptions = {
    method: 'GET',
  };
  fetch(AutoproducttemplatecolorsResourcePrefixAnonymous, requestOptions)
      .then((data) => data.json())
      .then((data) => {setAutoproducttemplatecolorList(data.list);dataFetchCallBack(data.list)})
}

export function addAnonymousCartItemAPI(payload, successCallBack, failuerCallback){
  const requestId = uuidv4().split("-")[4];
  const token = localStorage.getItem("JWT");
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${token}` },
    body: JSON.stringify(payload)
  };
  fetch(opsCartMgmtPrefix+"/anonymouscartitem", requestOptions)
      .then(async function(response) {
          let errorMessage = "Something Went Wrong"
          if(response.ok)
          {
              return response.json();         
          }
          else {
              if (response.status===208){
                  errorMessage = "Record already exist";
              } else if (403 === response.status) {
                  errorMessage = "Access Denied"
              }else if (400 === response.status) {
                  return response.json().then((errorData) => {
                      console.error('400 Error:', errorData);
                      errorMessage=errorData.message;
                      throw new Error(errorMessage);
                    });
              }
              else if (500 === response.status) {
              } 
          }
          throw new Error(errorMessage);
      })
      .then((response) => {
          successCallBack(response);
      })
      .catch(function(error) { 
          console.log('Request failed', error.message);
          failuerCallback(error.message);
      });
}

export function getCartitemListByCartidAPI(cartid, setCartitemList, dataFetchCallBack){
  const requestOptions = {
    method: 'GET',
  };
  fetch(CartitemsResourcePrefix+"/"+cartid, requestOptions)
      .then((data) => data.json())
      .then((data) => {
        setCartitemList(data.list);
        dataFetchCallBack(data.list)
  })
}


export function getJWTAPI(payload, successCallBack, failuerCallback){
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload)
  };
  fetch(opsCoreWebServicesPrefix+"/jwt", requestOptions)
      .then(async function(response) {
          let errorMessage = "Something Went Wrong"
          if(response.ok)
          {
              return response.json();         
          }
          else {
              if (response.status===208){
                  errorMessage = "Record already exist";
              } else if (403 === response.status) {
                  errorMessage = "Access Denied"
              }else if (400 === response.status) {
                  return response.json().then((errorData) => {
                      console.error('400 Error:', errorData);
                      errorMessage=errorData.message;
                      throw new Error(errorMessage);
                    });
              }
              else if (500 === response.status) {
              } 
          }
          throw new Error(errorMessage);
      })
      .then((response) => {
          successCallBack(response);
      })
      .catch(function(error) { 
          console.log('Request failed', error.message);
          failuerCallback(error.message);
      });
}

