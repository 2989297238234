import React, { useState, useEffect } from 'react'

import Grid from '@mui/material/Grid';

import CustomerFilterCriteria from './CustomerFilterCriteria'
import CustomerList from './CustomerList'
import MyPageContainer from '../../core/template/MyPageContainer';
import {getListAPI} from './CustomerAPI';
import {transformToCardFormat} from '../../core/js/FormUtils'
import {getPathVariable} from '../../core/js/UrlUtils';

import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {setCustomerDashboardCustomerList } from '../../store/slice/DmpSlice';

export default function CustomerListView({setShowEdit,setCustomerId}){
    const {filterCriteria} = useSelector((state)=>state.dmpslice.customerDashboard);   

    const [filteredCustomerList, setFilteredCustomerList] = React.useState([]);
    const [cardList, setCardList] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const { showfilter } = useSelector((state) => state.confslice);
    const { customerDashboard } = useSelector((state) => state.dmpslice);
    
    function dataFetchCallBack(){
    }

    useEffect(()=>{
      if (customerDashboard.customerList.length>-1){
        setFilteredCustomerList(customerDashboard.customerList);
        transformToCardFormat(customerDashboard.customerList, setCardList);
      }
    },[customerDashboard.customerList])

    useEffect(()=>{
          transformToCardFormat(filteredCustomerList, setCardList);
      },[filteredCustomerList])

    useEffect(()=>{
        if (filterCriteria.title==='' && filterCriteria.email==='' && filterCriteria.phone==='' && filterCriteria.inviteeid==='' && filterCriteria.status==='' && filterCriteria.customertype==='' && filterCriteria.membercount==='' ){
            setFilteredCustomerList(customerDashboard.customerList);
            transformToCardFormat(customerDashboard.customerList, setCardList);

        } else {
            setFilteredCustomerList(
                customerDashboard.customerList
                    .filter(customer=> customer.title.toString().toUpperCase().includes(filterCriteria.title.toString().toUpperCase()))

                
            )
            

            //transformToCardFormat(customerDashboard.filteredCustomerList, setCardList);
        }
      },[filterCriteria])
  
     return(
        <Grid container direction="column" >
            <Grid item lg={12}>
                <CustomerList
                    setShowEdit={setShowEdit}
                    setCustomerId = {setCustomerId}
                    tableList={filteredCustomerList}
                    cardList={cardList}
                    />
          </Grid>
      </Grid>
    )
}