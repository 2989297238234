import { Grid, Tooltip} from "@mui/material";

export function getProductcategoryStruct(){
    return {
        id:{
            name:'id',
            label:'ID',
            value:'',
            error:false,
            errorMessage:'',
            required:false
        },
        
        agency:{
            name:'agency',
            label:'Agency',
            value:'',
            error:false,
            errorMessage:'You must enter Agency',
            required:true,
            fieldtype:'string'
        },
        businessid:{
            name:'businessid',
            label:'Business Id',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'ID'
        },
        title:{
            name:'title',
            label:'title',
            value:'',
            error:false,
            errorMessage:'',
            required:false,
            fieldtype:'string'
        },
        productid:{
            name:'productid',
            label:'Product Id',
            value:'',
            error:false,
            errorMessage:'You must enter Product Id',
            required:true,
            fieldtype:'ID'
        },
        categoryid:{
            name:'categoryid',
            label:'Category Id',
            value:'',
            error:false,
            errorMessage:'You must enter Category Id',
            required:true,
            fieldtype:'ID'
        },
      }
}

export function getColumns(payload){
  let actionList = [
        
        { field: 'agency', headerName: 'Agency', width:150 },
        { field: 'title', headerName: 'title', width:150 },
        { field: 'productid', headerName: 'Product Id', width:150 },
        { field: 'categoryid', headerName: 'Category Id', width:150 },
  ];
  if (payload!==undefined){
    payload.actionList && payload.actionList.map((actionObj)=>{
    actionList.push(
      {
        field: actionObj.title,
        headerName: actionObj.title,
        sortable: false,
        width:60,
        disableClickEventBubbling: false,
        renderCell: (params) => {
          return (
              <Grid item onClick={()=>{actionObj.callBackMethod(params.row.id)}}>
                <Tooltip title={actionObj.title} sx={{cursor: "pointer"}}>
                  {actionObj.icon}
                </Tooltip>
              </Grid>
          );
        }
      })
    })
  }
  return actionList;
}

export function setData(data, formValues, setFormValues){
   
    setFormValues({
      ['id']:{
        ...formValues['id'],
        value:data.id,
      },
       ['agency']:{
          ...formValues['agency'],
          value:data.agency,
        },['businessid']:{
          ...formValues['businessid'],
          value:data.businessid,
        },['title']:{
          ...formValues['title'],
          value:data.title,
        },['productid']:{
          ...formValues['productid'],
          value:data.productid,
        },['categoryid']:{
          ...formValues['categoryid'],
          value:data.categoryid,
        },
      })
}