import React, {useState, useEffect} from 'react';
import {Grid, Typography, TextField,Button} from '@mui/material';


export default function OTP({mode, setMode, setOtp, showErrorMsg, setShowErrorMsg}) {
  
const [errorMsg, setErrorMsg] = useState('Please enter Valid OTP');
const [attempsLeft, setAttempsLeft] = useState(5);
const [showResend, setShowResend] = useState(false);
const [value, setValue] = useState('');
const [time, setTime] = useState(5);

useEffect(()=>{
    //setTimeout(setTimer, 1000);
    //setValue('');
},[mode, showResend])

useEffect(()=>{
    if (time>0)
    {
        setTimeout(setTimer, 20000);
    } else {
        clearTimeout(setTimer);
        setShowResend(true);
    }
        
},[time])

const setTimer = ()=>{
    //console.log('timer is called with timme'+time);
    setTime(time-1);
   // setTimeout(timer, 2000);
}
const resendOTP = ()=>{
    setTime(5);
    setShowResend(false);
}

const handleOTPChange = (e) => {
    const {name, value} = e.target;
    setShowErrorMsg(false);
    
    if (!isNaN(value)){
        setValue(value);
        setOtp(value)
    } 
    } 

return (       
    
    <Grid container direction="row" padding={.5} sx={{width:'100%'}}>
        <Grid item sm={12}  xs={12} sx={{width:'100%'}}>
            <TextField      
                    required={true}
                    label="OTP"
                    name="otp"
                    value={value}
                    onChange={(e)=>{handleOTPChange(e)}}
                    error={showErrorMsg}
                    helperText={showErrorMsg && errorMsg}
                    disabled={ mode==='otp'?false:true}
                    sx={{width:'100%'}}
                /> 
        </Grid>
        <Grid item sm={12}  xs={12} sx={{width:'100%'}} ></Grid>
            <Grid container direction="row" justifyContent="space-between" >
                <Grid item sm={4}  xs={6}  ><i>
                    {/* <Typography color="textSecondary" gutterBottom variant="h6" sx={{fontSize:'12px'}}>
                        Attempts Left ({attempsLeft})
                    </Typography> */}
                    </i>
                </Grid>   
                {
                    mode==='otp'?
                        <div align="right" width="100%">
                            {showResend?
                                    <Grid item sm={12}  xs={12} align="right"  sx={{paddingTop:1}}>
                                        {/* <Button variant="contained" onClick={resendOTP}>Resend</Button> */}
                                    </Grid>
                                :
                                    <Grid item sm={8}  xs={12} align="right"  >
                                        {/* <Typography color="textSecondary" gutterBottom variant="h6" sx={{fontSize:'12px'}}>
                                            Resend in {time} seconds
                                        </Typography> */}
                                    </Grid>
                            }
                        </div>
                    :
                        <div>
                        </div>
                }
            </Grid>          
       </Grid>

    );
}
