import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Grid, Box, Typography, Button, Tooltip, Link, Checkbox, FormControlLabel } from '@mui/material';
import { setMlmuserTandcModal } from 'store/slice/DmpSlice';
import TandcActionModal from 'components/mlmuser/action/tandc/TandcActionModal';
import { setMlmUserObj,setPartnerAPISuccess } from 'store/slice/EcomSlice';
import { bePartnerAPI } from 'api/CoreMlmmgmtWebServices';
import { getUserAPI } from 'api/IdentityServiceAPI';
import { setuserprofileInfo } from 'store/slice/HeaderSlice';

export default function MlmTandC() {
    const dispatch = useDispatch();
    const [checked, setChecked] = useState(false);
    const [apiInProgress, setApiInProgress] = useState(false);
    const {partnerAPISuccess} = useSelector((state)=>state.ecomslice.mlm);
    
    const loadUserSuccessCallBack = (response) => {
            dispatch(setuserprofileInfo(response.userprofileInfo));
    }
    const handleSubmit = () => {
        if (checked) {
            setApiInProgress(true);
            bePartnerAPI({},bePartnerSucccess,bePartnerFailure)
        } 
    };
    const bePartnerSucccess = (apiResponse) =>{
        setApiInProgress(false);
        dispatch(setMlmUserObj(apiResponse.mlmuserInfo));
        dispatch(setPartnerAPISuccess(true));
        getUserAPI(loadUserSuccessCallBack, ()=>{});
    }
    const bePartnerFailure = (errMsg) =>{
        setApiInProgress(false);
        console.log('!!! ERROR !!! '+errMsg);
    }
    const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
    };
    const handleLinkClick = () =>{
        dispatch(setMlmuserTandcModal(true));
    }
    return (
        <>
            <Grid container xs={12} direction="row" justifyContent="flex-end" sx={{ width: '100%', paddingBottom: 1 }} >
                {/* <Grid item xs={12}>
                    <Typography variant="h4" color="primary.darker">Terms & Conditions</Typography>
                </Grid> */}
            </Grid>
            <Grid>
                <Box sx={{ }}>
                    {/* <Typography variant="h6" >
                        By checking the box below, you agree to our Terms and Conditions and Privacy Policy.
                    </Typography> */}
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checked}
                                onChange={handleCheckboxChange}
                                color="primary"
                            />
                        }
                        label={
                            <Typography variant="h6">
                                I agree to the{" "}
                                <Link href="#" onClick={handleLinkClick} sx={{ cursor: "pointer" }}>
                                Terms and Conditions
                                </Link>.
                            </Typography>
                        }
                    />
                    
                </Box>
            </Grid>
            {apiInProgress?
                <Grid container justifyContent="center" />
                :
                <Grid container justifyContent="flex-end" sx={{padding:1}}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={!checked} // Disable the button if not checked
                        sx={{  }}
                    >
                        Buy Partnership
                    </Button>
            </Grid>
            }
            <TandcActionModal/>
        </>
    )
}