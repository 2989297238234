import React, { useState, useEffect } from 'react'

import Grid from '@mui/material/Grid';

import MlmorderFilterCriteria from './MlmorderFilterCriteria'
import MlmorderList from './MlmorderList'
import MyPageContainer from '../../core/template/MyPageContainer';
import {getListAPI} from './MlmorderAPI';
import {transformToCardFormat} from '../../core/js/FormUtils'
import {getPathVariable} from '../../core/js/UrlUtils';

import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {setMlmorderDashboardMlmorderList } from '../../store/slice/DmpSlice';

export default function MlmorderListView({setShowEdit,setMlmorderId}){
    const {filterCriteria} = useSelector((state)=>state.dmpslice.mlmorderDashboard);   

    const [filteredMlmorderList, setFilteredMlmorderList] = React.useState([]);
    const [cardList, setCardList] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const { showfilter } = useSelector((state) => state.confslice);
    const { mlmorderDashboard } = useSelector((state) => state.dmpslice);
    
    function dataFetchCallBack(){
    }

    useEffect(()=>{
      if (mlmorderDashboard.mlmorderList.length>-1){
        setFilteredMlmorderList(mlmorderDashboard.mlmorderList);
        transformToCardFormat(mlmorderDashboard.mlmorderList, setCardList);
      }
    },[mlmorderDashboard.mlmorderList])

    useEffect(()=>{
          transformToCardFormat(filteredMlmorderList, setCardList);
      },[filteredMlmorderList])

    useEffect(()=>{
        if (filterCriteria.title==='' && filterCriteria.orderid==='' && filterCriteria.ordervalue==='' && filterCriteria.partnerid==='' && filterCriteria.memberid==='' && filterCriteria.orderdate==='' && filterCriteria.creditvalue==='' ){
            setFilteredMlmorderList(mlmorderDashboard.mlmorderList);
            transformToCardFormat(mlmorderDashboard.mlmorderList, setCardList);

        } else {
            setFilteredMlmorderList(
                mlmorderDashboard.mlmorderList
                    .filter(mlmorder=> mlmorder.title.toString().toUpperCase().includes(filterCriteria.title.toString().toUpperCase()))

                
            )
            

            //transformToCardFormat(mlmorderDashboard.filteredMlmorderList, setCardList);
        }
      },[filterCriteria])
  
     return(
        <Grid container direction="column" >
            <Grid item lg={12}>
                <MlmorderList
                    setShowEdit={setShowEdit}
                    setMlmorderId = {setMlmorderId}
                    tableList={filteredMlmorderList}
                    cardList={cardList}
                    />
          </Grid>
      </Grid>
    )
}