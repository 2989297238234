import { transformDateValue } from 'core/js/FormUtils';
export const getMlmuserView = (dataList) => {
  
  const transformDataValue = (value, type) =>{
    if (!type.includes('date')) return value;
    return transformDateValue(value);
  }

  let mlmuserList = [];
  dataList.map((data)=>{
  let card = {
            'id': data.id,
            'headingList': [
                            {"fieldLabel":"Title","fieldValue":transformDataValue(data.title,"string"), "fieldType":"string"},
                            {"fieldLabel":"userid","fieldValue":transformDataValue(data.userid,"ID"), "fieldType":"ID"},
                            {"fieldLabel":"User Type","fieldValue":transformDataValue(data.usertype,"string"), "fieldType":"string"},
                            {"fieldLabel":"MLM User Type Id","fieldValue":transformDataValue(data.mlmusertypeid,"bodynamiclist"), "fieldType":"bodynamiclist"},
                            {"fieldLabel":"MLM User Type Name","fieldValue":transformDataValue(data.mlmusertypename,"string"), "fieldType":"string"},
                            {"fieldLabel":"Senior Partner Id","fieldValue":transformDataValue(data.seniorpartnerid,"ID"), "fieldType":"ID"},
                            {"fieldLabel":"Mobile Number","fieldValue":transformDataValue(data.mobilenumber,"mobile"), "fieldType":"mobile"},
                            {"fieldLabel":"Login Status","fieldValue":transformDataValue(data.loginstatus,"number"), "fieldType":"number"},
                            {"fieldLabel":"Member Count","fieldValue":transformDataValue(data.membercount,"number"), "fieldType":"number"},
              
              
            ],
            'dataList': [
                
              ]
          }

          mlmuserList.push(card);
  })
  return mlmuserList;
}