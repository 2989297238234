// project import
import dashboard from './dashboard';
import utilities from './utilities';
import dmp from './dmp';


// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [,dashboard, dmp, utilities]
};

export default menuItems;