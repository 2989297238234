import PropTypes from 'prop-types';
import { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid,
  IconButton,
  Paper,
  Popper,
  Stack,
  Tab,
  Tabs,
  Typography
} from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';
import ProfileTab from './ProfileTab';
import SettingTab from './SettingTab';

// assets
import avatar1 from 'assets/images/users/avatar-1.png';
import notLoggedIn from 'assets/images/auth/not-logged-in.jpg';
import { LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { setLoggedIn, logout, openLoginModal } from 'store/slice/HeaderSlice';
import { setShowProfileMenu } from 'store/slice/MenuSlice';

// tab panel wrapper
function TabPanel({ children, value, index, ...other }) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `profile-tab-${index}`,
    'aria-controls': `profile-tabpanel-${index}`
  };
}

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { appuserInfo, isLoggedIn, userprofileInfo } = useSelector((state) => state.headerslice);
  const {mobileView,showProfileMenu} = useSelector((state)=>state.menu)

  useEffect(() => {
  }, [userprofileInfo, showProfileMenu])
  useEffect(()=>{

    if (mobileView){
      dispatch(setShowProfileMenu(true));
      return;
    }

    if (isLoggedIn) dispatch(setShowProfileMenu(true));
    else dispatch(setShowProfileMenu(false));
  },[])

  const handleLogout = async () => {
    dispatch(logout());
    dispatch(setLoggedIn(false));
    localStorage.removeItem("JWT");
    navigate('/');
    window.location.reload();
  };

  const anchorRef = useRef(null);
  //const [open, setOpen] = useState(showProfileMenu);
  const handleToggle = () => {
    if (userprofileInfo.rolename === 'OPS_ANONYMOUS' || userprofileInfo.rolename === 'MLM_CUSTOMER') {
      dispatch(openLoginModal(true));
      return;
    }

    if (!isLoggedIn) handleLogout();
    //setOpen((prevOpen) => !prevOpen);
    dispatch(setShowProfileMenu((prevOpen) => !prevOpen))
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
   // setOpen(false);
   dispatch(setShowProfileMenu(false));
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const iconBackColorOpen = 'grey.300';
  const getUserName = () => {
    if (userprofileInfo.rolename === 'OPS_ANONYMOUS' || userprofileInfo.rolename === 'MLM_CUSTOMER') return 'Login';
    if (isLoggedIn) {
      return userprofileInfo.username
    }
  }
  const getUserRole = () => {
    if (userprofileInfo) return userprofileInfo.rolename;
    return "NO PROFILE"
  }
  const getAvtarComponent = () => {
    if (isLoggedIn) {
      if (userprofileInfo.rolename === 'OPS_ANONYMOUS') return notLoggedIn;
      else return avatar1
    } else
      return notLoggedIn
  }
  const getLoginId = () =>{
    if (userprofileInfo) return userprofileInfo.email.split('@')[0];
    return "NO PROFILE"
  }
  return (
      <Box sx={{ flexShrink: 0, ml: 0.75}}>
        <ButtonBase
          sx={{
            p: 0.25,
            //bgcolor: open ? iconBackColorOpen : 'transparent',
            borderRadius: 1,
            //'&:hover': { bgcolor: 'secondary.lighter' }
          }}
          aria-label="open profile"
          ref={anchorRef}
          aria-controls={showProfileMenu ? 'profile-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
            <Avatar alt="profile user" src={getAvtarComponent()} sx={{ width: 32, height: 32 }} />
            <Typography variant="subtitle1">{getUserName()}</Typography>
          </Stack>
        </ButtonBase>
        <Popper
          placement="bottom-end"
          open={showProfileMenu}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          popperOptions={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 9]
                }
              }
            ]
          }}
        >
          {({ TransitionProps }) => (
            <Transitions type="fade" in={showProfileMenu} {...TransitionProps}>
              {showProfileMenu && isLoggedIn && (
                <Paper
                  sx={{
                    boxShadow: theme.customShadows.z1,
                    width: 290,
                    minWidth: 240,
                    maxWidth: 290,
                    [theme.breakpoints.down('md')]: {
                      maxWidth: 250
                    }
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <MainCard elevation={0} border={false} content={false}>
                      <CardContent sx={{ px: 2.5, pt: 2 }}>
                        <Grid container justifyContent="space-between" alignItems="center" direction="row">
                          <Grid item >
                            <Stack direction="row" spacing={1.25} alignItems="center">
                              {/* <Avatar alt="profile user" src={avatar1} sx={{ width: 32, height: 32 }} /> */}
                              <Stack>
                                <Typography variant="h6">{getUserName()}</Typography>
                                <Typography variant="body2" color="textSecondary">
                                  {getUserRole()}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Grid>
                          <Grid item >
                            <Stack direction="row" spacing={1.25} alignItems="center">
                              {/* <Avatar alt="profile user" src={avatar1} sx={{ width: 32, height: 32 }} /> */}
                              <Stack>
                                <Typography variant="h6">Login Id</Typography>
                                <Typography variant="body2" color="textSecondary">{getLoginId()}</Typography>
                              </Stack>
                            </Stack>
                          </Grid>
                          {/* <Grid item justifyContent="flex-end">
                            <IconButton size="large" color="secondary" onClick={handleLogout}>
                              <LogoutOutlined />
                            </IconButton>
                          </Grid> */}
                        </Grid>
                      </CardContent>
                      {showProfileMenu && (
                        <>
                          <Box sx={{ borderBottom: 1, borderColor: 'divider' }} >
                            <Tabs variant="fullWidth" value={value} onChange={handleChange} aria-label="profile tabs">
                              <Tab
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  textTransform: 'capitalize'
                                }}
                                icon={<UserOutlined style={{ marginBottom: 0, marginRight: '10px' }} />}
                                label="Profile"
                                {...a11yProps(0)}
                              />
                              <Tab
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  textTransform: 'capitalize'
                                }}
                                icon={<SettingOutlined style={{ marginBottom: 0, marginRight: '10px' }} />}
                                label="Setting"
                                {...a11yProps(1)}
                              />
                            </Tabs>
                          </Box>
                          <TabPanel value={value} index={0} dir={theme.direction}>
                            <ProfileTab handleLogout={handleLogout} />
                          </TabPanel>
                          <TabPanel value={value} index={1} dir={theme.direction}>
                            <SettingTab />
                          </TabPanel>
                        </>
                      )}
                    </MainCard>
                  </ClickAwayListener>
                </Paper>
              )}
            </Transitions>
          )}
        </Popper>
      </Box>
  );
};

export default Profile;
